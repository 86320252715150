import React from "react";

import ReactNative from "../../assets/images/logos/reactnative.webp";
import MERNLogo from "../../assets/images/logos/MERNLogo.webp";
import JavascriptLogo from "../../assets/images/logos/JavascriptLogo.webp";
import NodejsLogo from "../../assets/images/logos/NodeJSLogo.webp";
import MYSQLLogo from "../../assets/images/logos/MYSQLLogo.webp";
import MongoDBLogo from "../../assets/images/logos/mongodb.webp";
import WordpressLogo from "../../assets/images/logos/WordpressLogo.webp";
import WooCommerceLogo from "../../assets/images/logos/woocommerce.webp";
import FigmaLogo from "../../assets/images/logos/FigmaLogo.webp";
import GCPLogo from "../../assets/images/logos/gcp.webp";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Tech() {
  const { t } = useTranslation();

  return (
    <div className="max-w-auto  px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto bg-gradient-to-b from-slate-900 to-black">
      <div className="sm:w-1/2 xl:w-1/3 mx-auto text-center mb-6">
        <h2 className="text-2xl font-bold md:text-3xl md:leading-tight text-gray-200 dark:text-gray-200">
          {t("TechTitle.text")}
        </h2>
      </div>

      {/* React JS Svg */}
      <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
        <a className="flex-shrink-0 transition hover:-translate-y-1">
          <LazyLoadImage
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={ReactNative}
            effect="blur"
            alt="Transistor"
            width={165}
            height={50}
          />
        </a>

        {/* Vue JS Svg */}
        <a className="flex-shrink-0 transition hover:-translate-y-1">
          <LazyLoadImage
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={MERNLogo}
            effect="blur"
            alt="Reform"
            width={165}
            height={50}
          />
        </a>

        {/* MERN Stack Svg */}
        <a className="flex-shrink-0 transition hover:-translate-y-1">
          <LazyLoadImage
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={JavascriptLogo}
            effect="blur"
            alt="Tuple"
            width={165}
            height={50}
          />
        </a>

        {/* MERN Stack Svg */}
        <a className="flex-shrink-0 transition hover:-translate-y-1">
          <LazyLoadImage
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={NodejsLogo}
            effect="blur"
            alt="SavvyCal"
            width={165}
            height={50}
          />
        </a>

        <a className="flex-shrink-0 transition hover:-translate-y-1">
          <LazyLoadImage
            className="col-span-2 max-h-28 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={MYSQLLogo}
            effect="blur"
            alt="Statamic"
            width={200}
            height={50}
          />
        </a>

        <a className="flex-shrink-0 transition hover:-translate-y-1">
          <LazyLoadImage
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={MongoDBLogo}
            effect="blur"
            alt="Statamic"
            width={165}
            height={50}
          />
        </a>

        <a className="flex-shrink-0 transition hover:-translate-y-1">
          <LazyLoadImage
            className="col-span-2 max-h-14 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={WordpressLogo}
            effect="blur"
            alt="Statamic"
            width={165}
            height={50}
          />
        </a>

        <a className="flex-shrink-0 transition hover:-translate-y-1">
          <LazyLoadImage
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={WooCommerceLogo}
            effect="blur"
            alt="Statamic"
            width={165}
            height={50}
          />
        </a>

        <a className="flex-shrink-0 transition hover:-translate-y-1">
          <LazyLoadImage
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={FigmaLogo}
            effect="blur"
            alt="Statamic"
            width={165}
            height={50}
          />
        </a>

        <a className="flex-shrink-0 transition hover:-translate-y-1">
          <LazyLoadImage
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={GCPLogo}
            effect="blur"
            alt="Statamic"
            width={165}
            height={50}
          />
        </a>
      </div>
    </div>
  );
}

