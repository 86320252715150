import React from "react";
import Navbar from "../navbar";
import Hero from "./hero";
import Features from "./features";
import Pricing from "./pricing";
import CTA2 from "./cta2";
import FeaturesFooter from "./featuresFooter";
import CTA from "./cta";
import FAQ from "./faq";
import Footer from "../../home/footer";

export default function MainVPS () {
    return (
        <>
        <div className="bg-gray-900">
        <Navbar />
        <Hero />
        </div>
        <div className="bg-white dark:bg-gray-900 -mt-14 sm:-mt-14 lg:-mt-14">
        <Features />
        <Pricing />
        <CTA2 />
        <FeaturesFooter />
        <CTA />
        <FAQ />
        <Footer />
        </div>
        </>
    );
}