import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import Animation from "../../../assets/hosting/gaming.json";

export default function CTA() {
  return (
    <div id="gaming" className="bg-gray-900 text-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="flex flex-col gap-8 lg:flex-row items-center">
          <div className="lg:w-1/2">
            <h1 className="text-5xl font-bold leading-tight mb-4">
            Puedes usar tu VPS para crear tu propio servidor de juegos
            </h1>
            <p className="text-xl mb-8">
              Con nuestros servidores VPS, puedes alojar tu propio servidor de
              juegos, como Minecraft, CS:GO, Rust, ARK, y muchos más.
            </p>
            <a
              className="focus:outline-none text-black bg-yellow-700 hover:bg-yellow-400 focus:ring-4 focus:ring-yellow-300 font-medium rounded-full text-md px-14 py-3.5 me-2 mb-2 dark:focus:ring-yellow-900 hover:shadow-lg hover:-translate-y-0.5 transform transition duration-300"
              href="#plans"
            >
              Ver planes
            </a>
          </div>
          <div className="lg:w-1/2 lg:ml-12">
            <Player
              autoplay
              loop
              speed={1}
              src={Animation}
              style={{ height: "auto", width: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
