import { MoveRight } from 'lucide-react';

const features = [
  {
    name: 'NVMe.',
    description:
      'El almacenamiento NVMe es una tecnología de almacenamiento de alta velocidad que utiliza la interfaz PCIe para ofrecer velocidades de lectura y escritura significativamente más rápidas que las SSD y HDD tradicionales.',
    icon: MoveRight,
  },
  {
    name: 'SSD.',
    description: 'Las SSD son unidades de estado sólido que utilizan memoria flash para almacenar datos. Son más rápidas y duraderas que los discos duros tradicionales, pero más lentas que las unidades NVMe.',
    icon: MoveRight,
  },
  {
    name: 'HDD.',
    description: 'Los discos duros tradicionales utilizan discos magnéticos giratorios para almacenar datos. Son más lentos y menos duraderos que las SSD y NVMe, pero son más económicos y ofrecen una mayor capacidad de almacenamiento.',
    icon: MoveRight,
  },
]

export default function SSD() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32 border-t-[25px] border-gray-200 dark:border-blue-500">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-blue-600">Almacenamiento</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">NVMe vs SSD vs HDD</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                La tecnología de almacenamiento que elijas para tu servidor puede tener un impacto significativo en el rendimiento de tu aplicación. A continuación, se muestra una descripción general de las diferencias entre los servidores NVMe, SSD y HDD.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
              <p className="mt-6 underline text-lg font-medium leading-8 text-gray-800">
                Todos nuestros servidores de hosting utilizan almacenamiento NVMe o SSD. 
              </p>
            </div>
          </div>
          <img
            src="https://contabo.com/blog/wp-content/uploads/2021/10/nvme-feature-1.jpg.webp"
            alt="Product screenshot"
            className="w-full max-w-none rounded-2xl shadow-xl ring-1  ring-gray-400/10 object-cover sm:object-contain"
          />
        </div>
      </div>
    </div>
  )
}
