import React, { ReactNode } from 'react';
import { Nav } from "../home/navbar";
import Footer from "../home/footer";
interface ServiceLayoutProps {
  children: ReactNode;
}
const ServiceLayout: React.FC<ServiceLayoutProps> = ({
  children
}) => {
  return <div className="dark:bg-gray-900">
            <Nav />
            {children}
            <Footer />
        </div>;
};
export default ServiceLayout;