import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";
import es from "./es";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: en,
      es: es,
    },
    // otras configuraciones...
    lng: "es", // idioma inicial
    fallbackLng: "en", // un idioma de respaldo en caso que no encuentre el primero
    interpolation: {
      escapeValue: false,
      
    },


    
  });

export default i18n;

