import React, { useState, useEffect } from "react";
import data from "../data.json";

export default function Pricing() {
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [plans, setPlans] = useState([]);

  const toggleBillingCycle = () => {
    setBillingCycle(billingCycle === "monthly" ? "yearly" : "monthly");
  };

  useEffect(() => {
    setPlans(data.vds);
  }, []);


  return (
    <div className="mx-auto max-w-7xl">
      <div className="relative z-10 mx-auto max-w-md lg:max-w-7xl">
        <div className="mt-16 rounded-lg bg-gray-900 dark:bg-gray-800 px-6 py-8 shadow-2xl shadow-violet-600/10 sm:p-10 lg:flex lg:items-center">
          <div className="lg:w-0 lg:flex-1">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Los mejores precios
            </h2>
            <p className="mt-4 max-w-3xl text-lg text-gray-300">
              Para que puedas elegir el plan que mejor se adapte a tus
              necesidades.
            </p>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:ml-8 lg:flex-shrink-0">
            <div className="flex rounded-full shadow-sm">
              <button
                type="button"
                onClick={toggleBillingCycle}
                className={`relative inline-flex items-center rounded-l-full border border-transparent ${
                  billingCycle === "monthly"
                    ? "bg-gradient-to-r from-yellow-700 to-yellow-900 text-white"
                    : "bg-white text-gray-700"
                } px-6 py-3 text-sm font-medium `}
              >
                Mensual
              </button>
              <button
                type="button"
                onClick={toggleBillingCycle}
                className={`relative inline-flex items-center rounded-r-full border border-gray-300 ${
                  billingCycle === "yearly"
                    ? "bg-gradient-to-r from-yellow-700 to-yellow-900 text-white"
                    : "bg-white text-gray-700"
                } px-6 py-3 text-sm font-medium `}
              >
                Anual
              </button>
            </div>
          </div>
        </div>
        <div className="mx-auto mt-20">
          <div className="flex flex-col md:flex-row gap-4 items-center justify-center">
            {plans.map((plan) => (
              <div
                key={plan.id}
                className="bg-white dark:bg-gray-800 rounded-lg p-8 shadow-lg border hover:shadow-2xl transition-shadow duration-300 hover:transform hover:-translate-y-1 w-4/5 md:w-1/3"
              >
                <h2 className="text-lg font-bold mb-2 text-purple-700 dark:text-yellow-500">
                  {plan.title}
                </h2>
                {/* <p className="text-gray-700 mb-4">{plan.description}</p> */}
                <p className="text-4xl font-bold mb-4 text-black dark:text-white">
                  {billingCycle === "yearly"
                    ? `${(plan.price * 12 * 0.75).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}` // Precio anual con descuento del 25%
                    : `${plan.price.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}`}
                  <span className="text-gray-500 text-lg dark:text-gray-400">
                    /{billingCycle === "yearly" ? "año" : "mes"}
                  </span>
                </p>
                {billingCycle === "yearly" && (
                  <p className="text-yellow-900 font-semibold mb-4">
                    Ahorras{" "}
                    {`${(plan.price * 12 * 0.25).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}`}{" "}
                    al año
                  </p>
                )}

                <ul className="text-md text-gray-700 font-medium dark:text-gray-300">
                  {plan.details.map((detail, index) => (
                    <li key={index} className="flex items-center gap-2 mb-2">
                      {" "}
                      {/* Añade un margen inferior (mb) */}
                      <span className="text-yellow-900">•</span> {detail}
                    </li>
                  ))}
                </ul>
                <div className="text-center">
                  {" "}
                  {/* Aquí envolvemos el botón en un div centrado */}
                  <button className="mt-5 inline-flex justify-center items-center gap-1.5 py-2 px-8 bg-blue-700 hover:bg-yellow-700 text-white text-sm font-medium rounded-lg transition-colors duration-300 ">
                    Comprar ahora
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
