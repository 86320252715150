import React, { useState, useEffect } from "react";
import Navbar from "../navbar";
import data from "../data.json";
import Cpanel from "../../../assets/hosting/cpanel-logo.webp";
import Ubuntu from "../../../assets/hosting/Ubuntu-Emblem.webp";
import Windows from "../../../assets/hosting/windows-server-logo.webp";
import Centos from "../../../assets/hosting/centos-logo-light.webp";
import Wordpress from "../../../assets/hosting/Wordpress-logo.webp";
import Lamp from "../../../assets/hosting/LAMP-Stack-logo.webp";
import Plesk from "../../../assets/hosting/Plesk-logo.webp";
import Debian from "../../../assets/hosting/Debian_logo.webp";
import { Player } from "@lottiefiles/react-lottie-player";
import Animation from "../../../assets/hosting/hero.json";
import Awards from "../../../assets/hosting/contabo-awards.webp";
import Datacenters from "./datacenters";
import Details from "./DetailsCard.js";
import Brands from "./brands";
import CardsTypesHosting from "./cardsTypesHosting";
import SSD from "./ssd";
import ContaboLogo from "../../../assets/hosting/Contabo.png";
import Footer from "../../home/footer.js";

export default function Hosting() {
  const [isAnnual, setIsAnnual] = useState(false); // Estado para el switch
  const [plans, setPlans] = useState([]);

  const toggleBillingCycle = () => {
    setIsAnnual(!isAnnual);
  };

  useEffect(() => {
    setPlans(data.vps);
  }, []);

  return (
    <div>
      <>
        <div id="hosting" className="overflow-hidden dark:bg-gray-900">
          {/* Header */}
          <header className="flex flex-wrap md:justify-start md:flex-nowrap z-50 w-full text-sm py-3 md:py-0">
            <nav
              className="max-w-[85rem] w-full mx-auto px-4 md:px-6 lg:px-8"
              aria-label="Global"
            >
              <Navbar />
            </nav>
          </header>
          {/* End Header */}

          {/* Hero */}
          <div className="relative">
            {/* Gradients */}
            <div
              aria-hidden="true"
              className="flex absolute -top-48 left-0 -z-[1]"
            >
              <div className="bg-[#1e88e5] blur-3xl w-[7036px] h-[700px] dark:bg-purple-900 dark:opacity-80" />
              <div className="bg-slate-200 opacity-90 blur-3xl w-[577px] h-[300px] transform translate-y-32 dark:bg-gray-900" />
            </div>
            {/* End Gradients */}
            <div className="max-w-[85rem] px-4 pt-10 sm:px-6 lg:px-8 lg:pt-14 mx-auto">
              {/* Hero section, text on the left side and a image on the right side */}
              <div className="grid lg:grid-cols-2 gap-6 lg:gap-8 items-center mb-32">
                <div>
                  <h1 className="text-4xl md:text-4xl lg:text-6xl font-bold text-white dark:text-gray-200">
                    Servidores de alto rendimiento
                  </h1>
                  <p className="mt-4 mb-6 text-lg text-slate-200 dark:text-gray-400 pr-0 lg:pr-20">
                    Al confiarnos las necesidades de su negocio y proyectos,
                    prometemos un tiempo de actividad del 99,9 % en cualquier
                    servicio que brindemos, fuera de cualquier mantenimiento
                    estándar que podamos brindar.
                  </p>
                  <a
                    className="focus:outline-none text-black bg-yellow-700 hover:bg-yellow-400 focus:ring-4 focus:ring-yellow-300 font-medium rounded-full text-md px-6 py-3.5 me-2 mb-2 dark:focus:ring-yellow-900 hover:shadow-lg hover:-translate-y-0.5 transform transition duration-300"
                    href="#plans"
                  >
                    Ver planes
                  </a>
                  <a
                    className="focus:outline-none text-white bg-gray-800 hover:bg-gray-600 focus:ring-4 focus:ring-gray-600 font-medium rounded-full text-md px-5 py-3.5 dark:focus:ring-gray-900 hover:shadow-lg hover:-translate-y-0.5 transform transition duration-300"
                    href="#"
                  >
                    Contactar
                  </a>
                </div>
                <div className="hidden lg:block">
                  <Player
                    autoplay
                    loop
                    speed={1}
                    src={Animation}
                    style={{ height: "auto", width: "100%" }}
                  />
                </div>
              </div>

              {/* End Title */}
              <Details />
              <Brands />
              {/* Cards */}
              {/* <CardsDetails /> */}
              {/* Switch */}
              <div
                id="plans"
                className="w-2/3 sm:w-1/2 lg:w-1/3 mx-auto text-center mt-10 md:mt-14 mb-10 lg:mt-24"
              >
                <h1 className="dark:text-gray-400 text-3xl md:text-4xl lg:text-5xl font-bold">
                  Los mejores planes de hosting
                </h1>
              </div>
              <div className="flex mt-2 justify-center items-center ">
                <label
                  htmlFor="pricing-switch"
                  className="min-w-[3.5rem] text-sm text-gray-600 mr-3 dark:text-gray-400"
                >
                  Mensual
                </label>
                <input
                  type="checkbox"
                  id="pricing-switch"
                  className="relative shrink-0 w-[3.25rem] h-7 bg-gray-300 text-violet-900 checked:bg-none checked:bg-[#00acee] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 border border-transparent ring-1 ring-transparent focus:border-slate-900 focus:ring-slate-900 ring-offset-white focus:outline-none appearance-none dark:bg-gray-700 dark:checked:bg-yellow-500 dark:focus:ring-offset-gray-800 before:inline-block before:w-6 before:h-6 before:bg-white before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-gray-400"
                  checked={isAnnual}
                  onChange={toggleBillingCycle}
                />
                <label
                  htmlFor="pricing-switch"
                  className="min-w-[3.5rem] text-sm text-gray-600 ml-3 dark:text-gray-400"
                >
                  Anual
                </label>
              </div>
              {/* End Switch */}
              {/* Grid */}
              <div className="mt-6 md:mt-12 grid sm:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-6 lg:gap-3 xl:gap-6 lg:items-center">
                {/* Map over hosting data */}
                {/* Mostrar solo los primeros 4 vps */}
                {plans.slice(0, 4).map((vps) => (
                  <div
                    key={vps.id}
                    className={`flex flex-col bg-white border border-gray-200 text-center rounded-lg p-4 md:p-8 dark:bg-slate-900 dark:border-gray-700 hover:shadow-lg ${
                      vps.featured
                        ? "border-2 border-yellow-700 shadow-lg h-full hover:shadow-xl transform hover:-translate-y-1 transition-transform duration-300 dark:border-yellow-500"
                        : "h-full hover:-translate-y-1 transition-transform duration-300"
                    }`}
                  >
                    <img
                      className="h-16 w-auto mx-auto mt-4 mb-4"
                      src={vps.icon}
                      alt={vps.title}
                    />
                    <h4 className="font-medium text-lg text-gray-800 dark:text-gray-200">
                      {vps.title}
                    </h4>
                    {/* Conditional rendering for price */}
                    <span className="mt-7 font-bold text-3xl md:text-3xl xl:text-4xl text-gray-800 dark:text-gray-200">
                      {isAnnual
                        ? `$${(vps.price * 12 * 0.8).toFixed(2)}` // 20% discount for annual
                        : `$${vps.price.toFixed(2)}`}{" "}
                      /{" "}
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {isAnnual ? "año" : "mes"}
                      </span>
                      {isAnnual && (
                        <span className="block text-sm text-yellow-900 mt-1 dark:text-yellow-500">
                          Ahorras ${((vps.price * 12) * 0.2).toFixed(2)} al año
                        </span>
                      )}
                    </span>
                    <p className="mt-2 text-md text-gray-700 font-semibold dark:text-gray-300">
                      {vps.description}
                    </p>
                    {/* Mapping over details */}
                    <ul className="mt-7 space-y-2.5 text-sm">
                      {vps.details.map((detail, index) => (
                        <li key={index} className="flex space-x-2">
                          <svg
                            className="flex-shrink-0 h-5 w-5 text-blue-700"
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.5219 4.0949C11.7604 3.81436 12.181 3.78025 12.4617 4.01871C12.7422 4.25717 12.7763 4.6779 12.5378 4.95844L6.87116 11.6251C6.62896 11.91 6.1998 11.94 5.9203 11.6916L2.9203 9.02494C2.64511 8.78033 2.62032 8.35894 2.86493 8.08375C3.10955 7.80856 3.53092 7.78378 3.80611 8.02839L6.29667 10.2423L11.5219 4.0949Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span className="text-gray-800 dark:text-gray-400 font-medium">
                            {detail}
                          </span>
                        </li>
                      ))}
                    </ul>
                    <a
                      className="mt-5 inline-flex justify-center items-center gap-1.5 py-2 px-4 bg-blue-700 hover:bg-yellow-700 text-white text-sm font-medium rounded-lg transition-colors duration-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-violet-500"
                      href="#"
                    >
                      Comprar ahora
                    </a>
                  </div>
                ))}
                {/* End Map */}
              </div>
              {/* End Grid */}

              {/* Title */}
              <div className="w-2/3 sm:w-1/2 lg:w-1/3 mx-auto text-center mt-10 md:mt-14 mb-6 lg:mt-24">
                <h2 className="text-gray-600 dark:text-gray-400">
                  Usa tu servidor con los sistemas operativos más populares
                </h2>
              </div>
              {/* End Title */}
              {/* Clients */}
              <div className="flex items-center justify-center mb-10">
                <div className="grid grid-cols-4 gap-6 md:gap-8 lg:gap-12">
                  <img
                    className="h-20 w-auto object-contain grayscale transition duration-300 hover:grayscale-0"
                    src={Windows}
                    alt="Windows"
                  />
                  <img
                    className="h-20 w-auto object-contain grayscale transition duration-300 hover:grayscale-0"
                    src={Debian}
                    alt="Debian"
                  />
                  <img
                    className="h-16 w-auto object-contain grayscale transition duration-300 hover:grayscale-0"
                    src={Ubuntu}
                    alt="Ubuntu"
                  />
                  <img
                    className="h-16 w-auto object-contain grayscale transition duration-300 hover:grayscale-0"
                    src={Centos}
                    alt="Centos"
                  />
                  <img
                    className="h-24 w-auto object-contain grayscale transition duration-300 hover:grayscale-0"
                    src={Wordpress}
                    alt="Wordpress"
                  />
                  <img
                    className="h-20 w-auto object-contain grayscale transition duration-300 hover:grayscale-0"
                    src={Lamp}
                    alt="Lamp"
                  />
                  <img
                    className="h-16 w-auto object-contain grayscale transition duration-300 hover:grayscale-0"
                    src={Plesk}
                    alt="Plesk"
                  />
                  <img
                    className="h-16 w-auto object-contain grayscale transition duration-300 hover:grayscale-0"
                    src={Cpanel}
                    alt="cPanel"
                  />
                </div>
              </div>
              {/* End Clients */}
            </div>
            <CardsTypesHosting />
          </div>
          {/* End Hero */}
        </div>
        {/* Data Centers */}
        <Datacenters />
        <SSD />
        {/* Testimonials */}
        <div className="relative overflow-hidden dark:bg-gray-900 border-b">
          <div className="max-w-[85rem] px-4 py-12 sm:px-6 lg:px-8 lg:pt-16 lg:pb-28 mx-auto">
            <div className="flex flex-col items-center justify-center gap-6 lg:gap-8">
              <img className="h-12 w-auto" src={ContaboLogo} alt="Contabo" />
              <h3 className="text-lg text-center dark:text-white">
                Este servicio es brindando en colaboración entre Contabo y
                WeCode. <br />
                Contabo es uno de los principales proveedores de servidores en
                Europa.
              </h3>
              <img
                className="h-64 w-auto object-contain"
                src={Awards}
                alt="Contabo"
              />
              {/* End Col */}
            </div>
            {/* End Grid */}
          </div>
        </div>
        {/* End Testimonials */}

        {/* Footer */}
        <Footer />
        {/* End Footer */}
      </>
    </div>
  );
}
