import React from "react";
import IconWhite from "../../assets/images/Logo-v1-full-white.png";
import "../../translations/i18n";
import { useTranslation } from "react-i18next";
import GPTS from "../../assets/images/gtps.webp";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const Nav = () => {
  const { t } = useTranslation();


  const navigate = useNavigate();

  const handleClientClick = () => {
    navigate("/"); // Navega a la página de inicio
    setTimeout(() => {
      const section = document.getElementById("clients");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Ajusta el tiempo si es necesario
  };

  const handleScrollToSection = (sectionId) => {
    navigate(`/services/another-services#${sectionId}`);
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <div className="relative flex items-center justify-between">
      <div className="flex items-center justify-between w-full">
        <header className="flex flex-wrap md:justify-start md:flex-nowrap w-full text py-3 md:py-0">
          <nav
            className="max-w-[85rem] w-full mx-auto px-4 md:px-6 lg:px-8"
            aria-label="Global"
          >
            <div className="relative md:flex md:items-center md:justify-between">
              <div className="flex items-center justify-between py-4 md:py-0">
                <a
                  className="flex items-center text-[#1f2937] hover:text-gray-600 dark:text-gray-200 dark:hover:text-gray-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  href="/"
                >
                  <LazyLoadImage
                    className="w-[250px] h-10 mt-3 md:w-[300px] md:h-10 md:mt-3"
                    src={IconWhite}
                    alt="Logo"
                    effect="blur"
                  />
                </a>

                <div className="md:hidden">
                  <button
                    type="button"
                    className="hs-collapse-toggle flex justify-center items-center w-9 h-9 text-sm font-semibold rounded-lg border border-gray-200 text-[#fff] hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    data-hs-collapse="#navbar-collapse-with-animation"
                    aria-controls="navbar-collapse-with-animation"
                    aria-label="Toggle navigation"
                  >
                    <svg
                      className="hs-collapse-open:hidden flex-shrink-0 w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1="3" x2="21" y1="6" y2="6" />
                      <line x1="3" x2="21" y1="12" y2="12" />
                      <line x1="3" x2="21" y1="18" y2="18" />
                    </svg>
                    <svg
                      className="hs-collapse-open:block hidden flex-shrink-0 w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M18 6 6 18" />
                      <path d="m6 6 12 12" />
                    </svg>
                  </button>
                </div>
              </div>

              <div
                id="navbar-collapse-with-animation"
                className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow md:block"
              >
                <div className="overflow-hidden overflow-y-auto max-h-[75vh] [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500">
                  <div className="flex flex-col gap-x-0 mt-5 divide-y divide-dashed divide-gray-200 md:flex-row md:items-center md:justify-end md:gap-x-7 md:mt-0 md:ps-7 md:divide-y-0 md:divide-solid dark:divide-gray-700">
                    <a
                      className="font-medium text-white py-3 md:py-6 dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 hover:text-[#00acee] dark:hover:text-[#00acee]"
                      href="/"
                      aria-current="page"
                    >
                      {t("Navbar.home")}
                    </a>

                    <div className="hs-dropdown [--strategy:static] md:[--strategy:absolute] [--adaptive:none] md:[--trigger:hover] py-3 md:py-6">
                      <button
                        type="button"
                        className="flex items-center w-full text-white hover:text-[#00acee] font-medium dark:text-gray-200 dark:hover:text-[#00acee] dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      >
                        {t("Navbar.services")}
                        <svg
                          className="flex-shrink-0 ms-2 w-4 h-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="m6 9 6 6 6-6" />
                        </svg>
                      </button>

                      <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 md:w-[705px] lg:w-[750px] hidden z-10 top-full end-0 overflow-hidden bg-white md:shadow-2xl rounded-lg dark:bg-gray-700 dark:divide-gray-700 before:absolute before:-top-5 before:start-0 before:w-full before:h-5">
                        <div className="grid grid-cols-2 md:grid-cols-10">
                          <div className="md:col-span-3">
                            <div className="flex flex-col py-6 px-3 md:px-6">
                              <div className="space-y-4">
                                <span className="mb-2 text-xs font-semibold uppercase text-[#1f2937] dark:text-gray-200">
                                  {t("Navbar.development")}
                                </span>

                                <a
                                  className="flex gap-x-4 text-[#1f2937] hover:text-[#00acee] dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:hover:text-[#00acee]"
                                  href="/services/web-development"
                                >
                                  <svg
                                    className="flex-shrink-0 w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <rect
                                      width="18"
                                      height="18"
                                      x="3"
                                      y="3"
                                      rx="2"
                                      ry="2"
                                    />
                                    <line x1="3" x2="21" y1="9" y2="9" />
                                    <line x1="9" x2="9" y1="21" y2="9" />
                                  </svg>
                                  <div className="grow">
                                    <p>{t("Navbar.web")}</p>
                                  </div>
                                </a>

                                <a
                                  className="flex gap-x-4 text-[#1f2937] hover:text-[#00acee] dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:hover:text-[#00acee]"
                                  href="/services/app-development"
                                >
                                  <svg
                                    className="flex-shrink-0 w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <rect
                                      width="14"
                                      height="20"
                                      x="5"
                                      y="2"
                                      rx="2"
                                      ry="2"
                                    />
                                    <path d="M12.667 8 10 12h4l-2.667 4" />
                                  </svg>
                                  <div className="grow">
                                    <p>{t("Navbar.apps")}</p>
                                  </div>
                                </a>

                                <a
                                  className="flex gap-x-4 text-[#1f2937] hover:text-[#00acee] dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:hover:text-[#00acee]"
                                  href="/services/ecommerce"
                                >
                                  <svg
                                    className="flex-shrink-0 w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <circle cx="8" cy="21" r="1" />
                                    <circle cx="19" cy="21" r="1" />
                                    <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12" />
                                  </svg>{" "}
                                  <div className="grow">
                                    <p>{t("Navbar.store")}</p>
                                  </div>
                                </a>

                                <a
                                  className="flex gap-x-4 text-[#1f2937] hover:text-[#00acee] dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:hover:text-[#00acee]"
                                  href="/services/personalized-software"
                                >
                                  <svg
                                    className="flex-shrink-0 w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <circle cx="18" cy="15" r="3" />
                                    <circle cx="9" cy="7" r="4" />
                                    <path d="M10 15H6a4 4 0 0 0-4 4v2" />
                                    <path d="m21.7 16.4-.9-.3" />
                                    <path d="m15.2 13.9-.9-.3" />
                                    <path d="m16.6 18.7.3-.9" />
                                    <path d="m19.1 12.2.3-.9" />
                                    <path d="m19.6 18.7-.4-1" />
                                    <path d="m16.8 12.3-.4-1" />
                                    <path d="m14.3 16.6 1-.4" />
                                    <path d="m20.7 13.8 1-.4" />
                                  </svg>{" "}
                                  <div className="grow">
                                    <p>{t("Navbar.software")}</p>
                                  </div>
                                </a>

                                <a
                                  className="flex gap-x-4 text-[#1f2937] hover:text-[#00acee] dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:hover:text-[#00acee]"
                                  href="/services/business-software"
                                >
                                  <svg
                                    className="flex-shrink-0 w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z" />
                                    <path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2" />
                                    <path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2" />
                                    <path d="M10 6h4" />
                                    <path d="M10 10h4" />
                                    <path d="M10 14h4" />
                                    <path d="M10 18h4" />
                                  </svg>{" "}
                                  <div className="grow">
                                    <p>{t("Navbar.softwareBusiness")}</p>
                                  </div>
                                </a>

                                <a
                                  className="flex gap-x-4 text-[#1f2937] hover:text-[#00acee] dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:hover:text-[#00acee]"
                                  href="/services/api-development"
                                >
                                  <svg
                                    className="flex-shrink-0 w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="m18 16 4-4-4-4" />
                                    <path d="m6 8-4 4 4 4" />
                                    <path d="m14.5 4-5 16" />
                                  </svg>{" "}
                                  <div className="grow">
                                    <p>{t("Navbar.apis")}</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="md:col-span-3">
                            <div className="flex flex-col py-6 px-3 md:px-6">
                              <div className="space-y-4">
                                <span className="mb-2 text-xs font-semibold uppercase text-[#1f2937] dark:text-gray-200">
                                  {t("Navbar.anothers")}
                                </span>

                                <a
                                  href="/services/another-services"
                                  className="flex gap-x-4 text-[#1f2937] hover:text-[#00acee] dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:hover:text-[#00acee]"
                                >
                                  <svg
                                    className="flex-shrink-0 w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <circle cx="12" cy="12" r="10" />
                                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                                    <path d="M12 17h.01" />
                                  </svg>
                                  <div className="grow">
                                    <p>{t("Navbar.consulting")}</p>
                                  </div>
                                </a>

                                <a
                                  onClick={() => handleScrollToSection("seo")}
                                  className="hover:cursor-pointer flex gap-x-4 text-[#1f2937] hover:text-[#00acee] dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:hover:text-[#00acee]"
                                >
                                  <svg
                                    className="flex-shrink-0 w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M3 3v18h18" />
                                    <path d="M7 12v5h12V8l-5 5-4-4Z" />
                                  </svg>{" "}
                                  <div className="grow">
                                    <p>{t("Navbar.seo")}</p>
                                  </div>
                                </a>

                                <a
                                  onClick={() =>
                                    handleScrollToSection("support")
                                  }
                                  className="hover:cursor-pointer flex gap-x-4 text-[#1f2937] hover:text-[#00acee] dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:hover:text-[#00acee]"
                                >
                                  <svg
                                    className="flex-shrink-0 w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                                    <circle cx="9" cy="7" r="4" />
                                    <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                  </svg>
                                  <div className="grow">
                                    <p>{t("Navbar.support")}</p>
                                  </div>
                                </a>
                              </div>

                              <div className="mt-7 space-y-4">
                                <span className="mb-2 text-xs font-semibold uppercase text-[#1f2937] dark:text-gray-200">
                                  Automatización e IA
                                </span>

                                <a
                                  className="flex gap-x-4 text-[#1f2937] hover:text-[#00acee] dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:hover:text-[#00acee]"
                                  href="/services/ai-development"
                                >
                                  <svg
                                    className="flex-shrink-0 w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z" />
                                    <path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1" />
                                  </svg>{" "}
                                  <div className="grow">
                                    <p>{t("Navbar.chatbots")}</p>
                                  </div>
                                </a>

                                <a
                                  className="flex gap-x-4 text-[#1f2937] hover:text-[#00acee] dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:hover:text-[#00acee]"
                                  href="/services/ai-development"
                                >
                                  <svg
                                    className="flex-shrink-0 w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M12 8V4H8" />
                                    <rect
                                      width="16"
                                      height="12"
                                      x="4"
                                      y="8"
                                      rx="2"
                                    />
                                    <path d="M2 14h2" />
                                    <path d="M20 14h2" />
                                    <path d="M15 13v2" />
                                    <path d="M9 13v2" />
                                  </svg>{" "}
                                  <div className="grow">
                                    <p>{t("Navbar.gpts")}</p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="col-span-full md:col-span-4">
                            <div className="flex flex-col bg-gray-50 p-6 dark:bg-gray-700">
                              <span className="text-xs font-semibold uppercase text-[#1f2937] dark:text-gray-200">
                                {t("Navbar.ia")}
                              </span>

                              <a
                                className="mt-4 group dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                href="#"
                              >
                                <div className="aspect-w-16 aspect-h-9">
                                  <LazyLoadImage
                                    className="w-full object-cover rounded-lg"
                                    src={GPTS}
                                    effect="blur"
                                    alt="ChatGPT Customs"
                                  />
                                </div>
                                <div className="mt-2">
                                  <p className="text-[#1f2937] text-[14px] dark:text-gray-200">
                                    {t("Navbar.news")}
                                  </p>
                                  <p className="mt-3 inline-flex items-center gap-x-1 text-sm text-[#00acee] decoration-2 hover:underline font-medium dark:text-[#00acee] dark:hover:text-blue-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-slate-600">
                                    {t("ReadMore.text")}
                                    <svg
                                      className="flex-shrink-0 w-4 h-4 transition ease-in-out group-hover:translate-x-1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path d="m9 18 6-6-6-6" />
                                    </svg>
                                  </p>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <a className="font-medium text-white py-3 md:py-6 dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 hover:text-[#00acee] dark:hover:text-[#00acee]" href="/hosting" aria-current="hosting">{t('Navbar.hosting')}</a>

                    <a
                      onClick={handleClientClick}
                      href="/#clients"
                      className="font-medium text-white py-3 md:py-6 dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 hover:text-[#00acee] dark:hover:text-[#00acee]"
                      aria-current="nexus"
                    >
                      {t("Navbar.clients")}
                    </a>

                    {/* <a
                      className="font-medium text-[#1f2937] py-3 md:py-6 dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 hover:text-[#00acee] dark:hover:text-[#00acee]"
                      href="https://nexus.wecodecr.com"
                      aria-current="nexus"
                    >
                      Nexus
                    </a> */}

                    <a
                      className="font-medium text-white py-3 md:py-6 dark:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 hover:text-[#00acee] dark:hover:text-[#00acee]"
                      href="/team"
                      aria-current="nosotrs"
                    >
                      {t("Navbar.about")}
                    </a>

                    <div className="pt-3 md:pt-0">
                      <a
                        className="py-2.5 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-[#00acee] text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        href="/contact"
                      >
                        {t("Navbar.contact")}
                      </a>
                    </div>

                    
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </div>
  );
};

