import React from "react";
import Feature1 from "../../assets/3d/features-1.webp";
import Feature2 from "../../assets/3d/features-2.webp";
import Feature3 from "../../assets/3d/features-3.webp";

import "../../App.css";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Features() {
  const { t } = useTranslation();

  return (
    <section className="bg-white dark:bg-gray-900 max-w-[100rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto z-10">
      <div className="container px-6 py-10 mx-auto">
        <h1
          className="text-2xl font-semibold text-[#1f2937] font-nasalization capitalize lg:text-3xl dark:text-white"
          style={{ whiteSpace: "pre-line" }}
        >
          <p dangerouslySetInnerHTML={{ __html: t("FeaturesTitle.text") }} />
        </h1>
        <div className="mt-2">
          <span className="inline-block w-40 h-1 bg-[#00acee] rounded-full"></span>
          <span className="inline-block w-3 h-1 ml-1 bg-[#00acee] rounded-full"></span>
          <span className="inline-block w-1 h-1 ml-1 bg-[#00acee] rounded-full"></span>
        </div>

        <div className="mt-8 xl:mt-12 lg:flex lg:items-center">
          <div className="grid w-full grid-cols-1 gap-8 lg:w-1/2 xl:gap-16 md:grid-cols-2">
            <div className="space-y-3">
              <span className="inline-block p-3 text-[#00acee] bg-gray-200 rounded-xl dark:text-white dark:bg-blue-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-monitor-smartphone"
                >
                  <path d="M18 8V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h8" />
                  <path d="M10 19v-3.96 3.15" />
                  <path d="M7 19h5" />
                  <rect width="6" height="10" x="16" y="12" rx="2" />
                </svg>
              </span>

              <h1 className="text-xl font-semibold text-[#1f2937] capitalize dark:text-white">
                {t("Feature1.title")}
              </h1>

              <p className="text-gray-500 dark:text-gray-300">
                {t("Feature1.description")}
              </p>
            </div>

            <div className="space-y-3">
              <span className="inline-block p-3 text-[#00acee] bg-gray-200 rounded-xl dark:text-white dark:bg-blue-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-smartphone-charging"
                >
                  <rect width="14" height="20" x="5" y="2" rx="2" ry="2" />
                  <path d="M12.667 8 10 12h4l-2.667 4" />
                </svg>
              </span>

              <h1 className="text-xl font-semibold text-[#1f2937] capitalize dark:text-white">
                {t("Feature2.title")}
              </h1>

              <p className="text-gray-500 dark:text-gray-300">
                {t("Feature2.description")}
              </p>
            </div>

            <div className="space-y-3">
              <span className="inline-block p-3 text-[#00acee] bg-gray-200 rounded-xl dark:text-white dark:bg-blue-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-layers-3"
                >
                  <path d="m12.83 2.18a2 2 0 0 0-1.66 0L2.6 6.08a1 1 0 0 0 0 1.83l8.58 3.91a2 2 0 0 0 1.66 0l8.58-3.9a1 1 0 0 0 0-1.83Z" />
                  <path d="m6.08 9.5-3.5 1.6a1 1 0 0 0 0 1.81l8.6 3.91a2 2 0 0 0 1.65 0l8.58-3.9a1 1 0 0 0 0-1.83l-3.5-1.59" />
                  <path d="m6.08 14.5-3.5 1.6a1 1 0 0 0 0 1.81l8.6 3.91a2 2 0 0 0 1.65 0l8.58-3.9a1 1 0 0 0 0-1.83l-3.5-1.59" />
                </svg>{" "}
              </span>

              <h1 className="text-xl font-semibold text-[#1f2937] capitalize dark:text-white">
                {t("Feature3.title")}
              </h1>

              <p className="text-gray-500 dark:text-gray-300">
                {t("Feature3.description")}
              </p>
            </div>

            <div className="space-y-3">
              <span className="inline-block p-3 text-[#00acee] bg-gray-200 rounded-xl dark:text-white dark:bg-blue-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-shopping-cart"
                >
                  <circle cx="8" cy="21" r="1" />
                  <circle cx="19" cy="21" r="1" />
                  <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12" />
                </svg>
              </span>

              <h1 className="text-xl font-semibold text-[#1f2937] capitalize dark:text-white">
                {t("Feature4.title")}
              </h1>

              <p className="text-gray-500 dark:text-gray-300">
                {t("Feature4.description")}
              </p>
            </div>

            <div className="space-y-3">
              <span className="inline-block p-3 text-[#00acee] bg-gray-200 rounded-xl dark:text-white dark:bg-blue-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-server"
                >
                  <rect width="20" height="8" x="2" y="2" rx="2" ry="2" />
                  <rect width="20" height="8" x="2" y="14" rx="2" ry="2" />
                  <line x1="6" x2="6.01" y1="6" y2="6" />
                  <line x1="6" x2="6.01" y1="18" y2="18" />
                </svg>
              </span>

              <h1 className="text-xl font-semibold text-[#1f2937] capitalize dark:text-white">
                {t("Feature5.title")}
              </h1>

              <p className="text-gray-500 dark:text-gray-300">
                {t("Feature5.description")}
              </p>
            </div>

            <div className="space-y-3">
              <span className="inline-block p-3 text-[#00acee] bg-gray-200 rounded-xl dark:text-white dark:bg-blue-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-bot"
                >
                  <path d="M12 8V4H8" />
                  <rect width="16" height="12" x="4" y="8" rx="2" />
                  <path d="M2 14h2" />
                  <path d="M20 14h2" />
                  <path d="M15 13v2" />
                  <path d="M9 13v2" />
                </svg>
              </span>

              <h1 className="text-xl font-semibold text-[#1f2937] capitalize dark:text-white">
                {t("Feature6.title")}
              </h1>

              <p className="text-gray-500 dark:text-gray-300">
                {t("Feature6.description")}
              </p>
            </div>
          </div>

          <div className="hidden lg:block lg:w-1/2">
            <div className="relative w-full h-full">
              <LazyLoadImage
                className="hidden md:block 2xl:block absolute animate-float-second left-[400px] md:left-3/4 h-64 w-56 md:h-56 lg:h-56 lg:w-56 2xl:h-64 2xl:w-64"
                src={Feature3}
               
                alt="Website of a business stadistics"
              />
              <LazyLoadImage
                className="hidden md:block 2xl:block absolute animate-float-first left-[100px] md:h-56 lg:h-56 lg:w-56 2xl:h-64 2xl:w-64"
                src={Feature2}
               
                
                
                alt="Shopping bag with a products inside"
              />
            </div>
            <LazyLoadImage
              className="relative right-0 animate-float duration-3000 ease-in-out infinite w-full md:w-auto md:h-auto lg:w-auto lg:h-auto 2xl:w-full 2xl:h-10/12"
              src={Feature1}
              effect="blur"
              alt="Rocket launching with a women sitting on top using a laptop"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
