import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import Animation from "../../assets/3d/animation-api.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { useTranslation } from 'react-i18next';
import "../../App.css";

export default function ApiDevelopment() {
    const { t } = useTranslation();
    const textArray = (t('apis.title') + ' ' + t('apis.title1')).split('');
    const textContainerRef = useRef(null);

    const line1 = t('apis.title');
    const line2 = t('apis.title1');

    const animateText = () => {
        const textArray = (line1 + ' ' + line2).split('');
        const shuffledIndices = shuffleArray(textArray.map((_, index) => index));

        shuffledIndices.forEach((index, animationIndex) => {
            gsap.fromTo(`.letter-${index}`, {
                opacity: 0,
                y: 50,
            }, {
                opacity: 1,
                y: 0,
                duration: 0.3,
                delay: animationIndex * 0.1,
            });
        });
    };

    function shuffleArray(array) {
        const shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    }

    useEffect(() => {
        animateText();
    }, []);
    return (
        <section className="max-w-[95rem] mx-auto dark:bg-gray-900">
            <style>{`
        #radius-shape-1 {
          height: 220px;
          width: 220px;
          top: -20px;
          left: -50px;
          background: radial-gradient(#00acee, #00acee);
          overflow: hidden;
        }

        #radius-shape-2 {
          border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
          bottom: -60px;
          right: -110px;
          width: 300px;
          height: 300px;
          background: radial-gradient(#00acee, #00acee);
          overflow: hidden;
        }
      `}</style>

            <div className="px-6 py-12 text-center md:px-12 lg:py-24 lg:text-left">
                <div className="w-full mx-auto sm:max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
                    <div className="grid items-center gap-12 lg:grid-cols-2">
                        <div className="lg:-mt-20">
                            <h1 ref={textContainerRef} className="text-4xl text-[#1f2937] font-nasalization font-bold lg:text-6xl lg:leading-tight dark:text-gray-200">
                                {textArray.map((letter, index) => (
                                    <span key={index} className={`letter-${index} ${index >= line1.length ? 'text-[#00acee]' : ''}`}>{letter}</span>
                                ))}
                            </h1>
                            <p className="text-gray-600 dark:text-gray-200">
                                {t('apis.description')}
                            </p>
                        </div>
                        <div className="relative lg:ml-20">
                            <div id="radius-shape-1" className="absolute rounded-full shadow-lg animate-float"></div>
                            <div id="radius-shape-2" className="absolute shadow-lg animate-float-second"></div>
                            <div className="relative">
                                <Player
                                    autoplay
                                    loop
                                    src={Animation}
                                    style={{ maxWidth: '100%', height: 'auto' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container mt-20 my-24 mx-auto md:px-6">
                <section className="mb-32 text-center">
                    <div className="flex justify-center">
                        <div className="max-w-[700px] text-center">
                            <h2 className="mb-6 text-center text-3xl font-bold text-[#1f2937] dark:text-white">
                                {t('apis.title2')}	<u className="text-[#00acee] dark:text-primary-400">{t('apis.title2b')}	</u> {t('apis.title2c')}<br />
                                <u className="text-[#00acee] dark:text-primary-400">
                                    {t('apis.title2d')}
                                </u>
                            </h2>
                            <p className="mb-16 text-md text-gray-400 text-neutral-500 dark:text-gray-200">
                                {t('apis.description2')}
                            </p>
                        </div>
                    </div>

                    <div className="grid gap-x-6 md:grid-cols-2 lg:grid-cols-4 lg:gap-x-12">
                        <div className="mb-12 lg:mb-0">
                            <div className="mb-6 inline-block rounded-full bg-indigo-50 p-4 text-[#1f2937] shadow-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                    stroke="currentColor" className="h-6 w-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
                                </svg>
                            </div>
                            <h5 className="mb-4 text-lg font-bold text-[#1f2937] dark:text-white">{t('apis.features1')}</h5>
                            <p className="text-neutral-500 dark:text-neutral-300">
                                {t('apis.features1Description')}
                            </p>
                        </div>

                        <div className="mb-12 lg:mb-0">
                            <div className="mb-6 inline-block rounded-full bg-indigo-50 p-4 text-[#1f2937] shadow-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                    stroke="currentColor" className="h-6 w-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                                </svg>
                            </div>
                            <h5 className="mb-4 text-lg font-bold text-[#1f2937] dark:text-white">{t('apis.features2')}	</h5>
                            <p className="text-neutral-500 dark:text-neutral-300">
                                {t('apis.features2Description')}
                            </p>
                        </div>

                        <div className="mb-12 md:mb-0">
                            <div className="mb-6 inline-block rounded-full bg-indigo-50 p-4 text-[#1f2937] shadow-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                    stroke="currentColor" className="h-6 w-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                                </svg>
                            </div>
                            <h5 className="mb-4 text-lg font-bold text-[#1f2937] dark:text-white">{t('apis.features3')}	</h5>
                            <p className="text-neutral-500 dark:text-neutral-300">
                                {t('apis.features3Description')}
                            </p>
                        </div>

                        <div className="mb-12 md:mb-0">
                            <div className="mb-6 inline-block rounded-full bg-indigo-50 p-4 text-[#1f2937] shadow-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                    stroke="currentColor" className="h-6 w-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                                </svg>
                            </div>
                            <h5 className="mb-4 text-lg font-bold text-[#1f2937] dark:text-white">{t('apis.features4')}	</h5>
                            <p className="text-neutral-500 dark:text-neutral-300">
                                {t('apis.features4Description')}
                            </p>
                        </div>
                    </div>
                </section>
            </div>



            <div className="max-w-3xl mx-auto text-center mb-10 lg:mb-14">
                <h2 className="text-2xl font-bold md:text-3xl md:leading-tight text-gray-800 dark:text-gray-200">
                    {t('apis.faqtitle')}
                </h2>
            </div>

            <div className="max-w-5xl mx-auto px-4 ">
                <div className="grid sm:grid-cols-2 gap-6 md:gap-12">
                    <div>
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                            {t('apis.faq1')}
                        </h3>
                        <p className="mt-2 text-gray-600 dark:text-gray-400">
                            {t('apis.faq1Description')}
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                            {t('apis.faq2')}
                        </h3>
                        <p className="mt-2 text-gray-600 dark:text-gray-400">
                            {t('apis.faq2Description')}
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                            {t('apis.faq3')}
                        </h3>
                        <p className="mt-2 text-gray-600 dark:text-gray-400">
                            {t('apis.faq3Description')}
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                            {t('apis.faq4')}
                        </h3>
                        <p className="mt-2 text-gray-600 dark:text-gray-400">
                            {t('apis.faq4Description')}
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                            {t('apis.faq5')}
                        </h3>
                        <p className="mt-2 text-gray-600 dark:text-gray-400">
                            {t('apis.faq5Description')}
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                            {t('apis.faq6')}
                        </h3>
                        <p className="mt-2 text-gray-600 dark:text-gray-400">
                            {t('apis.faq6Description')}
                        </p>
                    </div>
                </div>

            </div>


            <div className="container mt-20 mb-10 flex flex-col items-center px-4 py-12 mx-auto text-center">
                <h2 className="text-2xl font-bold tracking-tight text-[#1f2937] xl:text-3xl dark:text-white">
                    {t('webDevelopment.ctatitle')}
                </h2>

                <p className="block max-w-4xl mt-4 text-gray-600 dark:text-gray-300">
                    {t('webDevelopment.ctatext')}
                </p>

                <div className="mt-6">

                    <a href="/contact"
                        className="inline-flex items-center justify-center w-full px-4 py-2.5 mt-4 overflow-hidden text text-white transition-colors duration-300 bg-[#00acee] rounded-lg shadow sm:w-auto sm:mx-2 sm:mt-0 hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                        <svg className="w-5 h-5 mx-2 fill-current" viewBox="-28 0 512 512.00075" xmlns="http://www.w3.org/2000/svg">
                            <path d="m432.320312 215.121094-361.515624-208.722656c-14.777344-8.53125-32.421876-8.53125-47.203126 0-.121093.070312-.230468.148437-.351562.21875-.210938.125-.421875.253906-.628906.390624-14.175782 8.636719-22.621094 23.59375-22.621094 40.269532v417.445312c0 17.066406 8.824219 32.347656 23.601562 40.878906 7.390626 4.265626 15.496094 6.398438 23.601563 6.398438s16.214844-2.132812 23.601563-6.398438l361.519531-208.722656c14.777343-8.53125 23.601562-23.8125 23.601562-40.878906s-8.824219-32.347656-23.605469-40.878906zm-401.941406 253.152344c-.21875-1.097657-.351562-2.273438-.351562-3.550782v-417.445312c0-2.246094.378906-4.203125.984375-5.90625l204.324219 213.121094zm43.824219-425.242188 234.21875 135.226562-52.285156 54.539063zm-6.480469 429.679688 188.410156-196.527344 54.152344 56.484375zm349.585938-201.835938-80.25 46.332031-60.125-62.714843 58.261718-60.773438 82.113282 47.40625c7.75 4.476562 8.589844 11.894531 8.589844 14.875s-.839844 10.398438-8.589844 14.875zm0 0">
                            </path>
                        </svg>

                        <span className="mx-2">
                            {t('webDevelopment.ctabtn')}
                        </span>
                    </a>
                </div>
            </div>
        </section>
    );
}

