import React from "react"
import { useTranslation } from "react-i18next";

export default function Faq () {
    const { t } = useTranslation();
    return (
        <div className="max-w-[95rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
                <h2 className="text-2xl font-bold md:text-4xl md:leading-tight text-gray-800 dark:text-gray-200 font-nasalization">
                    <div dangerouslySetInnerHTML={{ __html: t('FAQTitle.text') }} />
                </h2>
            </div>

            <div className="max-w-6xl mx-auto">
                <div className="grid sm:grid-cols-2 gap-6 md:gap-12">
                    <div>
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                            <div dangerouslySetInnerHTML={{ __html: t('FAQ1Title.title') }} />
                        </h3>
                        <p className="mt-2 text-gray-600 dark:text-gray-400">
                            <div dangerouslySetInnerHTML={{ __html: t('FAQ1Title.response') }} />
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                            <div dangerouslySetInnerHTML={{ __html: t('FAQ2Title.title') }} />
                        </h3>
                        <p className="mt-2 text-gray-600 dark:text-gray-400">
                            <div dangerouslySetInnerHTML={{ __html: t('FAQ2Title.response') }} />
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                            <div dangerouslySetInnerHTML={{ __html: t('FAQ3Title.title') }} />
                        </h3>
                        <p className="mt-2 text-gray-600 dark:text-gray-400">
                            <div dangerouslySetInnerHTML={{ __html: t('FAQ3Title.response') }} />
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                            <div dangerouslySetInnerHTML={{ __html: t('FAQ4Title.title') }} />
                        </h3>
                        <p className="mt-2 text-gray-600 dark:text-gray-400">
                            <div dangerouslySetInnerHTML={{ __html: t('FAQ4Title.response') }} />
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                            <div dangerouslySetInnerHTML={{ __html: t('FAQ5Title.title') }} />
                        </h3>
                        <p className="mt-2 text-gray-600 dark:text-gray-400">
                            <div dangerouslySetInnerHTML={{ __html: t('FAQ5Title.response') }} />
                        </p>
                    </div>

                    <div>
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                            <div dangerouslySetInnerHTML={{ __html: t('FAQ6Title.title') }} />
                        </h3>
                        <p className="mt-2 text-gray-600 dark:text-gray-400">
                            <div dangerouslySetInnerHTML={{ __html: t('FAQ6Title.response') }} />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
