import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import Animation from "../../../assets/hosting/vps.json";

export default function Hero() {
  return (
    <div class=" py-20">
      <div class="container mx-auto px-6 md:px-12">
        <div class="flex flex-col md:flex-row items-center">
          <div class="md:w-1/2 lg:w-2/3">
            <h1 class="text-4xl md:text-6xl lg:text-7xl text-white font-bold mb-6">
              El Mejor <br class="hidden md:block" />
              <span class="text-yellow-700">VPS</span> Para Ti
            </h1>
            <p class="text-lg text-gray-300 mb-8 pr-0 md:pr-72">
              Nuestro VPS es la mejor forma de alojar tu sitio web. Puedes alojar
              tu sitio web de forma segura y confiable. Asegúrate de que tu sitio
              web esté siempre en línea con nuestros servicios de alojamiento web.
            </p>
            <div class="flex gap-2">
              <a
                href="#pricing"
                class="bg-yellow-700 hover:bg-yellow-400 rounded-full text-black font-medium py-3 px-6"
              >
                Ver Planes
              </a>
              <a
                href="/contact"
                class="bg-gray-700 hover:bg-gray-600 text-white font-medium py-3 px-6 rounded-full"
              >
                Contactar
              </a>
            </div>
          </div>
          <div class="md:w-1/3 lg:w-1/4 mt-8 md:mt-0 ml-0 md:-ml-24">
          <Player
              autoplay
              loop
              speed={1}
              src={Animation}
              style={{ height: "auto", width: "600px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
