import Navbar from "../navbar";
import Hero from "./hero";
import Features from "./features";
import Pricing from "./pricing";
import FeaturesFooter from "./featuresFooter";
import Datacenters from "./datacenters";
import FAQ from "./faq";
import Footer from "../../promo/footer";

export default function MainWebHosting() {
  return (
    <>
      <div className="bg-gray-900 dark:bg-gray-900">
        <Navbar />
        <Hero />
        <Features />
        <Pricing />
        <FeaturesFooter />
        <Datacenters />
        <FAQ />
        <Footer />
      </div>

    </>
  );
}
