import React from "react";

import DELL from "../../../assets/hosting/Dell.png";
import HewlettPackard from "../../../assets/hosting/Hewlett.png";
import AMD from "../../../assets/hosting/AMD.png";
import Samsung from "../../../assets/hosting/Samsung.png";

export default function Brands() {
  return (
    <div className="mb-24 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto flex flex-wrap justify-around gap-8 py-12 px-4">
        <p className="w-full mb-4 -mt-4 text-center text-base font-semibold uppercase text-gray-400 tracking-wider">
          Con quién trabajamos para construir nuestros servidores
        </p>

        <a className="flex items-center justify-center text-gray-400 hover:text-gray-200">
          <img src={DELL} alt="DELL Logo" className="w-full h-20 object-contain" />
        </a>

        <a className="flex items-center justify-center text-gray-400 hover:text-gray-200">
          <img
            src={HewlettPackard}
            alt="Hewlett Packard Logo"
            className="w-full h-20 object-contain"
          />
        </a>

        <a className="flex items-center justify-center text-gray-400 hover:text-gray-200">
          <img src={AMD} alt="AMD Logo" className="w-full h-20 object-contain" />
        </a>

        <a className="flex items-center justify-center text-gray-400 hover:text-gray-200">
          <img src={Samsung} alt="Samsung Logo" className="w-full h-24 object-contain" />
        </a>
      </div>
    </div>
  );
}
