import React, { useRef, useEffect } from "react";
import MERNLogo from "../../assets/images/logos/MERNLogo.webp";
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import "../../App.css";

export default function AppDevelopment() {
    const { t } = useTranslation();

    const textContainerRef = useRef(null);

    const text = t('AppDevelopment.title');

    const animateText = () => {
        const textArray = text.split('');
        const shuffledIndices = shuffleArray(textArray.map((_, index) => index));

        shuffledIndices.forEach((index, animationIndex) => {
            gsap.fromTo(`.letter-${index}`, {
                opacity: 0,
                y: 50,
            }, {
                opacity: 1,
                y: 0,
                duration: 1.5,
                delay: animationIndex * 0.1,

            });
        });
    }

    function shuffleArray(array) {
        const shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    }

    useEffect(() => {
        animateText();
    }
        , []);

    return (
        <div className="max-w-[95rem] mt-10 mx-auto px-4 sm:px-6 lg:px-8 dark:bg-gray-900">
            <div className="grid lg:grid-cols-7 lg:gap-x-8 xl:gap-x-12 lg:items-center">
                <div className="lg:col-span-3">
                    <h1 ref={textContainerRef} className="block text-center text-3xl font-nasalization font-bold text-[#1f2937] sm:text-4xl md:text-5xl lg:text-6xl dark:text-white">
                        {text.split('').map((letter, index) => (
                            <span key={index} className={`letter-${index}`}>{letter}</span>
                        ))}
                    </h1>
                    <p className="mt-3 text-lg text-[#1f2937] dark:text-gray-400">{t('AppDevelopment.description')}</p>

                    <div className="mt-5 lg:mt-8 flex flex-col items-center gap-2 sm:flex-row sm:gap-3">

                        <a className="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-[#00acee] text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="/contact">
                            {t('AppDevelopment.btn')}
                        </a>
                    </div>

                    <div className="mt-6 lg:mt-12">
                        <span className="text-xs font-medium text-[#1f2937] uppercase dark:text-gray-200">{t('AppDevelopment.tech')}</span>

                        <div className="mt-4 flex gap-x-8">
                            <LazyLoadImage effect="blur" className="h-10" src={MERNLogo} alt="Mirage" />

                        </div>
                    </div>
                </div>

                <div className="lg:col-span-4 mt-10 lg:mt-0">
                    <LazyLoadImage effect="blur" className="w-full rounded-xl animate-float-second" src="https://images.unsplash.com/photo-1514575110897-1253ff7b2ccb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Image Description" />
                </div>
            </div>


            <div className="grid sm:grid-cols-2 mt-20 lg:grid-cols-4 items-center gap-12">
                <div>
                    <svg className="flex-shrink-0 w-9 h-9 text-[#1f2937] dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect width="10" height="14" x="3" y="8" rx="2" /><path d="M5 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2h-2.4" /><path d="M8 18h.01" /></svg>
                    <div className="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
                        <div className="bg-gray-400 w-9 h-0.5"></div>
                    </div>
                    <div className="mt-5">
                        <h3 className="text-lg font-semibold text-[#1f2937] dark:text-white">{t('AppDevelopment.features1')}</h3>
                        <p className="mt-1 text-gray-600 dark:text-gray-400">{t('AppDevelopment.features1Description')}</p>
                    </div>
                </div>

                <div>
                    <svg className="flex-shrink-0 w-9 h-9 text-[#1f2937] dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 7h-9" /><path d="M14 17H5" /><circle cx="17" cy="17" r="3" /><circle cx="7" cy="7" r="3" /></svg>
                    <div className="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
                        <div className="bg-gray-400 w-9 h-0.5"></div>
                    </div>
                    <div className="mt-5">
                        <h3 className="text-lg font-semibold text-[#1f2937] dark:text-white">{t('AppDevelopment.features2')}</h3>
                        <p className="mt-1 text-gray-600 dark:text-gray-400">{t('AppDevelopment.features2Description')}</p>
                    </div>
                </div>

                <div>
                    <svg className="flex-shrink-0 w-9 h-9 text-[#1f2937] dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" /><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" /></svg>
                    <div className="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
                        <div className="bg-gray-400 w-9 h-0.5"></div>
                    </div>
                    <div className="mt-5">
                        <h3 className="text-lg font-semibold text-[#1f2937] dark:text-white">{t('AppDevelopment.features3')}</h3>
                        <p className="mt-1 text-gray-600 dark:text-gray-400">{t('AppDevelopment.features3Description')}</p>
                    </div>
                </div>

                <div>
                    <svg className="flex-shrink-0 w-9 h-9 text-[#1f2937] dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z" /><path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1" /></svg>
                    <div className="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
                        <div className="bg-gray-400 w-9 h-0.5"></div>
                    </div>
                    <div className="mt-5">
                        <h3 className="text-lg font-semibold text-[#1f2937] dark:text-white">{t('AppDevelopment.features4')}</h3>
                        <p className="mt-1 text-gray-600 dark:text-gray-400">{t('AppDevelopment.features4Description')}</p>
                    </div>
                </div>
            </div>


            <div className="container flex flex-col items-center mt-20 px-4 py-12 mx-auto xl:flex-row">
                <div className="flex justify-center xl:w-1/2">
                    <LazyLoadImage effect="blur" className="h-80 w-80 sm:w-[28rem] sm:h-[28rem] flex-shrink-0 object-cover rounded-full" src="https://images.unsplash.com/photo-1551650975-87deedd944c3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80" alt="" />
                </div>

                <div className="flex flex-col items-center mt-6 xl:items-start xl:w-1/2 xl:mt-0">
                    <h2 className="text-2xl font-bold tracking-tight text-[#1f2937] xl:text-3xl dark:text-white">
                        {t('AppDevelopment.title2')}
                    </h2>

                    <p className="block max-w-2xl mt-4 text-gray-600 dark:text-gray-300"> {t('AppDevelopment.description2')}</p>


                </div>
            </div>


            <div className="container mt-10 px-6 py-10 mx-auto">
                <h1 className="text-2xl font-bold text-[#1f2937] capitalize lg:text-3xl dark:text-white"> {t('AppDevelopment.title3')}</h1>

                <p className="mt-4 text-gray-600 xl:mt-6 dark:text-gray-300">
                    {t('AppDevelopment.description3')}
                </p>

                <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-2 xl:grid-cols-3">
                    <div className="space-y-3">
                        <span className="inline-block p-3 text-[#00acee] bg-indigo-50 rounded-full dark:text-white dark:bg-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                            </svg>
                        </span>

                        <h1 className="text-xl font-semibold text-[#1f2937] capitalize dark:text-white">{t('AppDevelopment.features5')}</h1>

                        <p className="text-gray-600 dark:text-gray-300">
                            {t('AppDevelopment.features5Description')}
                        </p>

                    </div>

                    <div className="space-y-3">
                        <span className="inline-block p-3 text-[#00acee] bg-indigo-50 rounded-full dark:text-white dark:bg-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                            </svg>
                        </span>

                        <h1 className="text-xl font-semibold text-[#1f2937] capitalize dark:text-white">{t('AppDevelopment.features6')}</h1>

                        <p className="text-gray-600 dark:text-gray-300">
                            {t('AppDevelopment.features6Description')}
                        </p>


                    </div>

                    <div className="space-y-3">
                        <span className="inline-block p-3 text-[#00acee] bg-indigo-50 rounded-full dark:text-white dark:bg-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                            </svg>
                        </span>

                        <h1 className="text-xl font-semibold text-[#1f2937] capitalize dark:text-white">{t('AppDevelopment.features7')}</h1>

                        <p className="text-gray-600 dark:text-gray-300">
                            {t('AppDevelopment.features7Description')}
                        </p>


                    </div>

                    <div className="space-y-3">
                        <span className="inline-block p-3 text-[#00acee] bg-indigo-50 rounded-full dark:text-white dark:bg-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z" />
                            </svg>
                        </span>

                        <h1 className="text-xl font-semibold text-[#1f2937] capitalize dark:text-white">{t('AppDevelopment.features8')}</h1>

                        <p className="text-gray-600 dark:text-gray-300">
                            {t('AppDevelopment.features8Description')}
                        </p>


                    </div>

                    <div className="space-y-3">
                        <span className="inline-block p-3 text-[#00acee] bg-indigo-50 rounded-full dark:text-white dark:bg-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
                            </svg>
                        </span>

                        <h1 className="text-xl font-semibold text-[#1f2937] capitalize dark:text-white">{t('AppDevelopment.features9')}</h1>

                        <p className="text-gray-600 dark:text-gray-300">
                            {t('AppDevelopment.features9Description')}
                        </p>


                    </div>

                    <div className="space-y-3">
                        <span className="inline-block p-3 text-[#00acee] bg-indigo-50 rounded-full dark:text-white dark:bg-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                            </svg>
                        </span>

                        <h1 className="text-xl font-semibold text-[#1f2937] capitalize dark:text-white">{t('AppDevelopment.features10')}</h1>

                        <p className="text-gray-600 dark:text-gray-300">
                            {t('AppDevelopment.features10Description')}
                        </p>


                    </div>
                </div>
            </div>

            <div className="container mt-20 mb-10 flex flex-col items-center px-4 py-12 mx-auto text-center">
                <h2 className="text-2xl font-bold tracking-tight text-[#1f2937] xl:text-3xl dark:text-white">
                    {t('webDevelopment.ctatitle')}
                </h2>

                <p className="block max-w-4xl mt-4 text-gray-600 dark:text-gray-300">
                    {t('webDevelopment.ctatext')}
                </p>

                <div className="mt-6">

                    <a href="/contact"
                        className="inline-flex items-center justify-center w-full px-4 py-2.5 mt-4 overflow-hidden text text-white transition-colors duration-300 bg-[#00acee] rounded-lg shadow sm:w-auto sm:mx-2 sm:mt-0 hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                        <svg className="w-5 h-5 mx-2 fill-current" viewBox="-28 0 512 512.00075" xmlns="http://www.w3.org/2000/svg">
                            <path d="m432.320312 215.121094-361.515624-208.722656c-14.777344-8.53125-32.421876-8.53125-47.203126 0-.121093.070312-.230468.148437-.351562.21875-.210938.125-.421875.253906-.628906.390624-14.175782 8.636719-22.621094 23.59375-22.621094 40.269532v417.445312c0 17.066406 8.824219 32.347656 23.601562 40.878906 7.390626 4.265626 15.496094 6.398438 23.601563 6.398438s16.214844-2.132812 23.601563-6.398438l361.519531-208.722656c14.777343-8.53125 23.601562-23.8125 23.601562-40.878906s-8.824219-32.347656-23.605469-40.878906zm-401.941406 253.152344c-.21875-1.097657-.351562-2.273438-.351562-3.550782v-417.445312c0-2.246094.378906-4.203125.984375-5.90625l204.324219 213.121094zm43.824219-425.242188 234.21875 135.226562-52.285156 54.539063zm-6.480469 429.679688 188.410156-196.527344 54.152344 56.484375zm349.585938-201.835938-80.25 46.332031-60.125-62.714843 58.261718-60.773438 82.113282 47.40625c7.75 4.476562 8.589844 11.894531 8.589844 14.875s-.839844 10.398438-8.589844 14.875zm0 0">
                            </path>
                        </svg>

                        <span className="mx-2">
                            {t('webDevelopment.ctabtn')}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    )
}

