import { useState as _useState$ } from "react";
import { renderReactScope as _renderReactScope$ } from "million/react";
import { block as _block$ } from "million/react";
// search.tsx

import React, { useState } from "react";
import { Search, MapPin, Briefcase, ChevronDown } from "lucide-react";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
const M$2 = ({
  handleFilter
}) => {
  const [locationOpen, setLocationOpen] = useState(false);
  const [jobTypeOpen, setJobTypeOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const {
    t
  } = useTranslation();
  const Locations = ["Heredia", "San José", "Remote", "Hibrido"];
  const JobTypes = ["Full Time", "Part Time", "Temporary", "Commission", "Per Hour", "Internship"];
  const handleSearch = () => {
    handleFilter(selectedLocation, selectedJobType, searchInput);
  };
  const _portal = _useState$(() => ({
      $: new Array(9)
    }))[0],
    _ = _renderReactScope$(<Search className="text-gray-400 dark:text-gray-300" />, false, _portal.$, 0, false),
    _2 = t("jobs.searchPlaceholder"),
    _3 = e => setSearchInput(e.target.value),
    _4 = () => setLocationOpen(!locationOpen),
    _5 = _renderReactScope$(<MapPin className="text-gray-400 dark:text-gray-300" />, false, _portal.$, 1, false),
    _6 = _renderReactScope$(selectedLocation || t("jobs.location"), false, _portal.$, 2, false),
    _7 = _renderReactScope$(<ChevronDown className={`transform ${locationOpen ? "rotate-180" : ""}`} />, false, _portal.$, 3, false),
    _8 = _renderReactScope$(locationOpen && <div className="absolute mt-2 w-full bg-white dark:bg-gray-700 rounded-lg shadow-lg z-10 animate-fadeIn">
                {Locations.map(location => <div key={location} className="px-4 py-2 hover:bg-blue-100 dark:hover:bg-blue-900 cursor-pointer" onClick={() => {
        setSelectedLocation(location);
        setLocationOpen(false);
      }}>
                    {location}
                  </div>)}
              </div>, false, _portal.$, 4, false),
    _9 = () => setJobTypeOpen(!jobTypeOpen),
    _10 = _renderReactScope$(<Briefcase className="text-gray-400 dark:text-gray-300" />, false, _portal.$, 5, false),
    _11 = _renderReactScope$(selectedJobType || t("jobs.jobType"), false, _portal.$, 6, false),
    _12 = _renderReactScope$(<ChevronDown className={`transform ${jobTypeOpen ? "rotate-180" : ""}`} />, false, _portal.$, 7, false),
    _13 = _renderReactScope$(jobTypeOpen && <div className="absolute mt-2 w-full bg-white dark:bg-gray-700 rounded-lg shadow-lg z-10 animate-fadeIn">
                {JobTypes.map(jobType => <div key={jobType} className="px-4 py-2 hover:bg-blue-100 dark:hover:bg-blue-900 cursor-pointer" onClick={() => {
        setSelectedJobType(jobType);
        setJobTypeOpen(false);
      }}>
                    {jobType}
                  </div>)}
              </div>, false, _portal.$, 8, false),
    _14 = t("jobs.searchButton");
  return <><P$ _={_} _2={_2} searchInput={searchInput} _3={_3} _4={_4} _5={_5} _6={_6} _7={_7} _8={_8} _9={_9} _10={_10} _11={_11} _12={_12} _13={_13} handleSearch={handleSearch} _14={_14} />{_portal.$.map(p => p.portal)}</>;
};
const P$ = /*million:transform*/_block$(({
  _,
  _2,
  searchInput,
  _3,
  _4,
  _5,
  _6,
  _7,
  _8,
  _9,
  _10,
  _11,
  _12,
  _13,
  handleSearch,
  _14
}) => {
  return <div className="flex justify-center items-center py-8 px-4 dark:bg-gray-800">
      <div className="w-full max-w-4xl p-6 bg-white shadow-2xl rounded-lg dark:bg-gray-900">
        <div className="flex flex-col md:flex-row items-center gap-4">
          {void 0}
          <div className="relative flex items-center w-full md:w-2/5 border rounded-full px-4 py-2 bg-gray-50 dark:bg-gray-800 dark:text-gray-100 transition-all focus-within:shadow-lg">
            {_}
            <input type="text" placeholder={_2} value={searchInput} onChange={_3} className="w-full bg-transparent border-none outline-none px-3 dark:text-white" />
          </div>

          {void 0}
          <div className="relative w-full md:w-1/4">
            <button onClick={_4} className="flex items-center justify-between w-full px-4 py-2 bg-gray-50 border rounded-full dark:bg-gray-800 dark:text-gray-100 transition-all focus:outline-none focus:ring-2 focus:ring-blue-500">
              <span className="flex items-center space-x-2">
                {_5}
                <span>{_6}</span>
              </span>
              {_7}
            </button>
            {_8}
          </div>

          {void 0}
          <div className="relative w-full md:w-1/4">
            <button onClick={_9} className="flex items-center justify-between w-full px-4 py-2 bg-gray-50 border rounded-full dark:bg-gray-800 dark:text-gray-100 transition-all focus:outline-none focus:ring-2 focus:ring-blue-500">
              <span className="flex items-center space-x-2">
                {_10}
                <span>{_11}</span>
              </span>
              {_12}
            </button>
            {_13}
          </div>

          {void 0}
          <button onClick={handleSearch} className="w-full md:w-auto flex items-center justify-center px-8 py-2 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition-all dark:bg-blue-700 dark:hover:bg-blue-600">
            {_14}
          </button>
        </div>
      </div>
    </div>;
}, {
  svg: false,
  shouldUpdate: (a, b) => a?._ !== b?._ || a?._2 !== b?._2 || a?.searchInput !== b?.searchInput || a?._3 !== b?._3 || a?._4 !== b?._4 || a?._5 !== b?._5 || a?._6 !== b?._6 || a?._7 !== b?._7 || a?._8 !== b?._8 || a?._9 !== b?._9 || a?._10 !== b?._10 || a?._11 !== b?._11 || a?._12 !== b?._12 || a?._13 !== b?._13 || a?.handleSearch !== b?.handleSearch || a?._14 !== b?._14
});
const SearchBar = M$2;
export default SearchBar;