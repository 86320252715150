import Navbar from "../navbar";
import WebHosting from "./webHosting";
import FeaturesHosting from "./features";
import PricingTable from "./pricingTable";
import Apps from "./apps";
import CTA from "./cta";
import FAQ from "./faq";
import FeaturesFooter from "./featuresFooter";
import Footer from "../../home/footer";

export default function MainWebHosting() {
  return (
    <>
      <div className="bg-gray-900 dark:bg-gray-900">
        <Navbar />
        <WebHosting/> {/* Ajustar el estilo inline */}
      </div>
      <div className="bg-white dark:bg-gray-900 -mt-14 sm:-mt-14 lg:-mt-14">
        <FeaturesHosting />
        <PricingTable />
        <Apps />
        <CTA />
        <FeaturesFooter />
        <FAQ />
        <Footer />
      </div>
    </>
  );
}
