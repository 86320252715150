import React from "react";

import DELL from "../../../assets/hosting/Dell.png";
import HewlettPackard from "../../../assets/hosting/HPEwhite.png";
import AMD from "../../../assets/hosting/AMDwhite.png";
import Samsung from "../../../assets/hosting/samsungwhite.webp";
export default function Hero() {
  return (
    <>
      <div className="bg-gray-900">
        <div className="max-w-7xl mx-auto px-4 xl:px-4 xl:px-0 pt-24 lg:pt-32 pb-24">
          <h1 className="font-semibold text-white text-5xl md:text-6xl">
            <span className="text-yellow-700">VDS</span> <br/> La mejor opción para tus proyectos profesionales
          </h1>
          <div className="max-w-4xl">
            <p className="mt-5 text-neutral-400 text-lg">
              Con nuestros servidores VDS, obtienes la potencia y el control de un servidor dedicado, pero a un precio mucho más asequible. Nuestros servidores VDS son la mejor opción para aquellos que necesitan más recursos y potencia para sus proyectos.
            </p>
          </div>
        </div>
      </div>

      <div className="relative overflow-hidden pt-4 bg-gray-900">
        <svg
          className="absolute -bottom-20 start-1/2 w-[1900px] transform -translate-x-1/2"
          width="2745"
          height="488"
          viewBox="0 0 2745 488"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 330.864C232.505 403.801 853.749 527.683 1482.69 439.719C2111.63 351.756 2585.54 434.588 2743.87 487"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 308.873C232.505 381.81 853.749 505.692 1482.69 417.728C2111.63 329.765 2585.54 412.597 2743.87 465.009"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 286.882C232.505 359.819 853.749 483.701 1482.69 395.738C2111.63 307.774 2585.54 390.606 2743.87 443.018"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 264.891C232.505 337.828 853.749 461.71 1482.69 373.747C2111.63 285.783 2585.54 368.615 2743.87 421.027"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 242.9C232.505 315.837 853.749 439.719 1482.69 351.756C2111.63 263.792 2585.54 346.624 2743.87 399.036"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 220.909C232.505 293.846 853.749 417.728 1482.69 329.765C2111.63 241.801 2585.54 324.633 2743.87 377.045"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 198.918C232.505 271.855 853.749 395.737 1482.69 307.774C2111.63 219.81 2585.54 302.642 2743.87 355.054"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 176.927C232.505 249.864 853.749 373.746 1482.69 285.783C2111.63 197.819 2585.54 280.651 2743.87 333.063"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 154.937C232.505 227.873 853.749 351.756 1482.69 263.792C2111.63 175.828 2585.54 258.661 2743.87 311.072"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 132.946C232.505 205.882 853.749 329.765 1482.69 241.801C2111.63 153.837 2585.54 236.67 2743.87 289.082"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 110.955C232.505 183.891 853.749 307.774 1482.69 219.81C2111.63 131.846 2585.54 214.679 2743.87 267.091"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 88.9639C232.505 161.901 853.749 285.783 1482.69 197.819C2111.63 109.855 2585.54 192.688 2743.87 245.1"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 66.9729C232.505 139.91 853.749 263.792 1482.69 175.828C2111.63 87.8643 2585.54 170.697 2743.87 223.109"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 44.9819C232.505 117.919 853.749 241.801 1482.69 153.837C2111.63 65.8733 2585.54 148.706 2743.87 201.118"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 22.991C232.505 95.9276 853.749 219.81 1482.69 131.846C2111.63 43.8824 2585.54 126.715 2743.87 179.127"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
          <path
            d="M0.5 1C232.505 73.9367 853.749 197.819 1482.69 109.855C2111.63 21.8914 2585.54 104.724 2743.87 157.136"
            className="stroke-neutral-700/50"
            stroke="currentColor"
          />
        </svg>

        <div className="relative z-10 -mt-5">
          <div className="max-w-7xl xl:px-4 mx-auto py-10 mb-6 px-4">
            <div className="mb-4">
              <h2 className="text-neutral-400">
                Usamos la mejor tecnología para ofrecerte el mejor rendimiento
              </h2>
            </div>

            <div className="flex justify-between gap-6">
                <div className="flex flex-col items-center">
                    <img src={DELL} alt="DELL" className="w-full h-20 object-contain" />
                </div>

                <div className="flex flex-col items-center">
                    <img src={HewlettPackard} alt="Hewlett Packard" className="w-full h-20 object-contain" />
                </div>

                <div className="flex flex-col items-center">
                    <img src={AMD} alt="AMD" className="w-full h-16 object-contain" />
                </div>


                <div className="flex flex-col items-center">
                    <img src={Samsung} alt="Samsung" className="w-full h-10 object-contain" />
                </div>

              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
