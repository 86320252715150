import React from "react";

import Img1 from "../../../assets/hosting/ip.png";
import Img2 from "../../../assets/hosting/guaranteed.png";
import Img3 from "../../../assets/hosting/support.png";
import Img4 from "../../../assets/hosting/uptime.png";
import Img5 from "../../../assets/hosting/backup.png";
import Img6 from "../../../assets/hosting/global.png";

import { Player } from "@lottiefiles/react-lottie-player";
import Animation from "../../../assets/hosting/subhero.json";
export default function Details() {
  return (
    <section class="dark:bg-gray-900">
      <div class="container px-6 py-10  ">
        <h1 class="text-3xl font-semibold capitalize text-right lg:text-4xl dark:text-white">
          ¿Por qué elegirnos?
        </h1>
        <p class="mt-4 text-lg text-gray-600 text-right dark:text-gray-400 ">
          Cada uno de nuestros planes de hosting viene con características{" "}
          <br />
          que te ayudarán a tener un sitio web rápido y seguro.{" "}
        </p>

        <div class="mt-2 text-right">
          <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
          <span class="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
          <span class="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
        </div>

        <div class="mt-8 xl:mt-12 lg:flex lg:items-center">
          <div class="hidden lg:flex lg:w-1/2 lg:justify-center">
            <Player
              autoplay
              loop
              speed={1}
              src={Animation}
              style={{ height: "auto", width: "100%", marginLeft: "-70px"}}
            />
          </div>
          <div class="grid w-full grid-cols-1 gap-8 lg:w-1/2 xl:gap-16 md:grid-cols-2">
            <div class="space-y-3">
              <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-xl dark:text-white dark:bg-blue-500">
                <img src={Img1} alt="Dedicated IP" className="w-14 h-14" />
              </span>

              <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">
                IP Dedicada
              </h1>

              <p class="text-gray-500 dark:text-gray-300">
                Todos nuestros planes VPS, VDS y Servidores Dedicados vienen con
                una IP dedicada, permitiéndote tener un sitio web seguro y
                rápido.
              </p>
            </div>

            <div class="space-y-3">
              <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-xl dark:text-white dark:bg-blue-500">
                <img
                  src={Img2}
                  alt="Guaranteed Resources"
                  className="w-14 h-14"
                />
              </span>

              <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">
                Recursos Garantizados
              </h1>

              <p class="text-gray-500 dark:text-gray-300">
                Los recursos de tu plan de hosting están garantizados, dandote
                la seguridad de que tu sitio web siempre tendrá el rendimiento
                que necesitas.
              </p>
            </div>

            <div class="space-y-3">
              <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-xl dark:text-white dark:bg-blue-500">
                <img src={Img3} alt="24/7 Support" className="w-14 h-14" />
              </span>

              <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">
                Soporte 24/7
              </h1>

              <p class="text-gray-500 dark:text-gray-300">
                Nuestro equipo de soporte está disponible las 24 horas del día,
                los 7 días de la semana, para ayudarte con cualquier problema
                que tengas.
              </p>
            </div>

            <div class="space-y-3">
              <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-xl dark:text-white dark:bg-blue-500">
                <img src={Img4} alt="99.9% Uptime" className="w-14 h-14" />
              </span>

              <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">
                99.9% Uptime
              </h1>

              <p class="text-gray-500 dark:text-gray-300">
                Nuestros servidores tienen un tiempo de actividad del 99.9%,
                asegurando que tu sitio web esté siempre disponible.
              </p>
            </div>

            <div class="space-y-3">
              <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-xl dark:text-white dark:bg-blue-500">
                <img src={Img5} alt="Daily Backups" className="w-14 h-14" />
              </span>

              <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">
                Copias de Seguridad Diarias
              </h1>

              <p class="text-gray-500 dark:text-gray-300">
                Realizamos copias de seguridad diarias de tu sitio web,
                asegurando que tus datos estén siempre seguros.
              </p>
            </div>

            <div class="space-y-3">
              <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-xl dark:text-white dark:bg-blue-500">
                <img src={Img6} alt="Global Servers" className="w-14 h-14" />
              </span>

              <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">
                Servidores Globales
              </h1>

              <p class="text-gray-500 dark:text-gray-300">
                Contamos con 11 datacenters en 8 regiones del mundo,
                permitiéndote elegir la ubicación que mejor se adapte a tus
                necesidades.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
