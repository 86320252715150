import React from "react";
import { useTranslation } from "react-i18next";

import Image from "../../assets/3d/parallax-1.webp";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
export default function CtaImage() {
  const { t } = useTranslation();
  return (
    <section class="">
      <div class="container flex flex-col items-center px-4 py-12 mx-auto xl:flex-row">
        <div class="flex justify-center xl:w-1/2">
          <LazyLoadImage
            class="md:h-[34rem] md:w-[100rem] h-[20rem] w-[30rem] object-cover rounded-lg "
            src={Image}
            effect="blur"
            alt=""
          />
        </div>

        <div class="flex flex-col items-center mt-6 xl:items-start xl:w-1/2 xl:mt-0">
          <h2 class="text-2xl font-semibold tracking-tight text-white xl:text-3xl dark:text-white">
            {t("promo.ctaTitle")}
          </h2>

          <p class="block max-w-2xl mt-4 text-gray-200 dark:text-gray-300">
            {t("promo.ctaSubtitle")}
          </p>

          <div class="mt-6 sm:-mx-2">
            <a
              className="py-2.5 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-[#00acee] text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              href="https://api.whatsapp.com/send?phone=50670602186&text=Hola,%20quisiera%20solicitar%20informaci%C3%B3n%20sobre%20la%20promoci%C3%B3n%20por%20tiempo%20limitado%20de%20sitios%20web."
            >
              {t("promo.ctaButton")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
