import React, { useState, useEffect } from "react";

// Importamos el JSON con los datos de los planes
import data from "../data.json";

export default function Pricing() {
  // Estado para el ciclo de facturación
  const [billingCycle, setBillingCycle] = React.useState("monthly");
  const [plans, setPlans] = useState([]);

  // Función para alternar entre el ciclo de facturación
  const toggleBillingCycle = () => {
    setBillingCycle(billingCycle === "monthly" ? "yearly" : "monthly");
  };

  // Condicional para aplicar descuento si es anual
  const isAnnual = billingCycle === "yearly";

  useEffect(() => {
    setPlans(data.dedicated_servers);
  }, []);

  return (
    <div className="pt-5 bg-gray-900" id="pricing">
      <div className="mx-auto pb-20 mt-24 max-w-7xl">
        <div className="mx-auto max-w-4xl text-center">
          <h1 className="text-base font-semibold leading-7 text-indigo-400">
            Nuestros Paquetes
          </h1>
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            Servicios Dedicados
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
          Elige el plan que mejor se adapte a tus necesidades.
        </p>
        <div className="mt-12 ml-4 md:ml-0 mx-auto sm:mt-16 lg:mt-0 lg:flex-shrink-0">
          <div className="flex rounded-full shadow-sm">
            <button
              type="button"
              onClick={toggleBillingCycle}
              className={`relative inline-flex items-center rounded-l-full border border-transparent ${
                billingCycle === "monthly"
                  ? "bg-gradient-to-r from-yellow-700 to-yellow-900 text-white"
                  : "bg-white text-gray-700"
              } px-6 py-3 text-sm font-medium `}
            >
              Mensual
            </button>
            <button
              type="button"
              onClick={toggleBillingCycle}
              className={`relative inline-flex items-center rounded-r-full border border-gray-300 ${
                billingCycle === "yearly"
                  ? "bg-gradient-to-r from-yellow-700 to-yellow-900 text-white"
                  : "bg-white text-gray-700"
              } px-6 py-3 text-sm font-medium `}
            >
              Anual
            </button>
          </div>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-3xl grid-cols-1 px-4 md:px-0 lg:mx-0 lg:max-w-max lg:grid-cols-5 justify-center gap-5 hover:shadow-2xl transition-shadow duration-300">
          {/* Botones para alternar entre el ciclo de facturación */}
          {/* Mapeo sobre los datos de los planes */}
          {plans.map((plan) => (
            <div
              key={plan.id}
              className="ring-1 ring-yellow-700 rounded-xl p-8 xl:p-6"
            >
              <div className="flex items-center justify-between gap-x-4">
                <h2
                  id={`product${plan.id}`}
                  className="text-lg font-semibold leading-8 text-white"
                >
                  {plan.title}
                </h2>
              </div>
              <p className="mt-6 flex items-baseline gap-x-1">
                {/* Condicional para el precio mensual o anual */}
                <span className="text-4xl font-bold tracking-tight text-white">
                  {/* Si es anual, aplicar descuento del 25% */}
                  {billingCycle === "yearly"
                    ? `${(plan.price * 12 * 0.80).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}` // Precio anual con descuento del 25%
                    : `${plan.price.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}`}
                </span>
              </p>
              {/* Mostrar ahorro solo si es ciclo anual */}
              {isAnnual && (
                <p className="mt-6 text-sm leading-6 text-yellow-700 font-semibold">
                  Ahorras{" "}
                  {`${(plan.price * 12 * 0.20).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}{" "}
                  al año
                </p>
              )}
              <a
                href="/order"
                aria-describedby={`product${plan.id}`}
                className="bg-yellow-700 hover:bg-yellow-400 text-black  focus-visible:outline-white mt-6 block rounded-lg py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                Comprar ahora
              </a>
              <ul
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10"
              >
                {/* Mapeo sobre los detalles del plan */}
                {plan.details.map((detail, index) => (
                  <li key={index} className="flex gap-x-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                      className="h-6 w-5 flex-none text-white"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-white dark:text-gray-400 font-medium">
                      {detail}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          {/* Fin del mapeo */}
        </div>
      </div>
    </div>
  );
}
