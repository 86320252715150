import React, { useState, useRef } from "react";
import { Nav } from "../home/navbar";
import ReCAPTCHA from "react-google-recaptcha";


import { useTranslation } from 'react-i18next';

export const Contact = () => {
    const { t } = useTranslation();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [result, setResult] = useState(null);
    const [isVerified, setIsVerified] = useState(false);

    const captchaRef = useRef(null)

    const handleVerify = () => {
        setIsVerified(true);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isVerified) {
            setResult(t('ErrorCatpcha.text'))
            return;
        }

        try {
            const res = await fetch("http://localhost:3002/api/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name,
                    email,
                    phone: phone ? phone : null,
                    message,
                }),
            });
            const data = await res.json();
            setResult(data.message);
            setName("");
            setEmail("");
            setPhone("");
            setMessage("");
        } catch (error) {
            setResult(t('ContactError.text'));
        }
    };

    return (
        <div className="dark:bg-gray-900 ">
        
            <Nav />
            <link
                rel="stylesheet"
                href="https://cdn.tailgrids.com/tailgrids-fallback.css"
            />

            <section className="bg-white py-20 lg:py-[120px] overflow-hidden relative h-full min-h-screen dark:bg-gray-900">
                <div className="container ">
                    <div className="flex flex-wrap lg:justify-between -mx-4 ">
                        <div className="w-full lg:w-1/2 xl:w-6/12 px-4 ">
                            <div className="max-w-[570px] mb-12 lg:mb-0 ">
                                <span className="block mb-4 text-base text-[#00acee] font-semibold ">
                                    {t('contact.ContactSub')}
                                </span>
                                <h2
                                    className="
                    tmax-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto dark:text-white
                    "
                                >
                                    {t('contact.ContactTitle')}
                                </h2>
                                <p className="text-base text-gray-700 md:text-lg dark:text-gray-200">
                                    {t('contact.ContactText')}
                                </p>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 xl:w-5/12 px-4">
                            <div className="bg-white relative rounded-[10px] p-8 sm:p-12 shadow-lg dark:bg-gray-900">
                                <form onSubmit={handleSubmit}>
                                    {result && (
                                        <p
                                            className={`text-base ${result.includes(t('contact.ContactSuccess'))
                                                ? "text-red-500"
                                                : "text-green-500"
                                                }`}
                                        >
                                            {result}
                                        </p>
                                    )}
                                    <div className="mb-6">
                                        <input
                                            type="text"
                                            value={name}
                                            placeholder={t('contact.ContactName')}
                                            className="
                          w-full
                          rounded-[10px]
                          py-3
                          px-[14px]
                          border border-[f0f0f0]
                          outline-none
                          focus-visible:shadow-none
                          focus:border-[#00acee]
                          dark:bg-gray-900
                          dark:text-white
                          "
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <input
                                            type="email"
                                            value={email}
                                            placeholder={t('contact.ContactEmail')}
                                            className="
                          w-full
                          rounded-[10px]
                          py-3
                          px-[14px]
                          
                          border border-[f0f0f0]
                          outline-none
                          focus-visible:shadow-none
                          focus:border-[#00acee]
                          dark:bg-gray-900
                          dark:text-white
                          "
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <input
                                            type="tel"
                                            value={phone}
                                            placeholder={t('contact.ContactPhone')}
                                            className="
        w-full
      rounded-[10px]
      py-3
      px-[14px]
      
      border border-[f0f0f0]
      outline-none
      focus-visible:shadow-none
      focus:border-[#00acee]
      dark:bg-gray-900
      dark:text-white
    "
                                            pattern="^[+]?[0-9]*$"
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </div>

                                    <div className="mb-6">
                                        <textarea
                                            rows={6}
                                            value={message}
                                            placeholder={t('contact.ContactMessage')}
                                            className="
                          w-full
                          rounded-[10px]
                          py-3
                          px-[14px]
                          
                          border border-[f0f0f0]
                          resize-none
                          outline-none
                          focus-visible:shadow-none
                          focus:border-[#00acee]
                          dark:bg-gray-900
                          dark:text-white
                          "
                                            onChange={(e) => setMessage(e.target.value)}
                                            defaultValue={""}
                                        />
                                    </div>
                                    <ReCAPTCHA
                                        sitekey="6Ldh3y8mAAAAAAp516ZDmVvqaV6SmvIyg_YhShIv"
                                        ref={captchaRef}
                                        onChange={handleVerify}
                                    />
                                    <div className="mb-6">
                                        <button
                                            type="submit"
                                            className="
                          w-full
                          bg-[#00acee]
                          hover:bg-[#00acee]
                          text-white
                          rounded-[10px]
                          py-3
                          px-[14px]
                          text-base
                          font-semibold
                          transition
                          duration-300
                          ease-in-out
                          mt-5
                          "
                                        >
                                            {t('contact.ContactButton')}
                                        </button>
                                    </div>
                                </form>
                                <div>
                                    <span className="absolute -top-10 -right-9 z-[-1]">
                                        <svg
                                            width={100}
                                            height={100}
                                            viewBox="0 0 100 100"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
                                                fill="#00acee"
                                            />
                                        </svg>
                                    </span>
                                    <span className="absolute -right-10 top-[90px] z-[-1] ">
                                        <svg
                                            width={34}
                                            height={134}
                                            viewBox="0 0 34 134"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="31.9993"
                                                cy={132}
                                                r="1.66667"
                                                transform="rotate(180 31.9993 132)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy="117.333"
                                                r="1.66667"
                                                transform="rotate(180 31.9993 117.333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy="102.667"
                                                r="1.66667"
                                                transform="rotate(180 31.9993 102.667)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy={88}
                                                r="1.66667"
                                                transform="rotate(180 31.9993 88)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy="73.3333"
                                                r="1.66667"
                                                transform="rotate(180 31.9993 73.3333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy={45}
                                                r="1.66667"
                                                transform="rotate(180 31.9993 45)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy={16}
                                                r="1.66667"
                                                transform="rotate(180 31.9993 16)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy={59}
                                                r="1.66667"
                                                transform="rotate(180 31.9993 59)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy="30.6666"
                                                r="1.66667"
                                                transform="rotate(180 31.9993 30.6666)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy="1.66665"
                                                r="1.66667"
                                                transform="rotate(180 31.9993 1.66665)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy={132}
                                                r="1.66667"
                                                transform="rotate(180 17.3333 132)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy="117.333"
                                                r="1.66667"
                                                transform="rotate(180 17.3333 117.333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy="102.667"
                                                r="1.66667"
                                                transform="rotate(180 17.3333 102.667)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy={88}
                                                r="1.66667"
                                                transform="rotate(180 17.3333 88)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy="73.3333"
                                                r="1.66667"
                                                transform="rotate(180 17.3333 73.3333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy={45}
                                                r="1.66667"
                                                transform="rotate(180 17.3333 45)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy={16}
                                                r="1.66667"
                                                transform="rotate(180 17.3333 16)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy={59}
                                                r="1.66667"
                                                transform="rotate(180 17.3333 59)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy="30.6666"
                                                r="1.66667"
                                                transform="rotate(180 17.3333 30.6666)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy="1.66665"
                                                r="1.66667"
                                                transform="rotate(180 17.3333 1.66665)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy={132}
                                                r="1.66667"
                                                transform="rotate(180 2.66536 132)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy="117.333"
                                                r="1.66667"
                                                transform="rotate(180 2.66536 117.333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy="102.667"
                                                r="1.66667"
                                                transform="rotate(180 2.66536 102.667)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy={88}
                                                r="1.66667"
                                                transform="rotate(180 2.66536 88)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy="73.3333"
                                                r="1.66667"
                                                transform="rotate(180 2.66536 73.3333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy={45}
                                                r="1.66667"
                                                transform="rotate(180 2.66536 45)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy={16}
                                                r="1.66667"
                                                transform="rotate(180 2.66536 16)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy={59}
                                                r="1.66667"
                                                transform="rotate(180 2.66536 59)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy="30.6666"
                                                r="1.66667"
                                                transform="rotate(180 2.66536 30.6666)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy="1.66665"
                                                r="1.66667"
                                                transform="rotate(180 2.66536 1.66665)"
                                                fill="#00acee"
                                            />
                                        </svg>
                                    </span>
                                    <span className="absolute -left-7 -bottom-7 z-[-1] ">
                                        <svg
                                            width={107}
                                            height={134}
                                            viewBox="0 0 107 134"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="104.999"
                                                cy={132}
                                                r="1.66667"
                                                transform="rotate(180 104.999 132)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="104.999"
                                                cy="117.333"
                                                r="1.66667"
                                                transform="rotate(180 104.999 117.333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="104.999"
                                                cy="102.667"
                                                r="1.66667"
                                                transform="rotate(180 104.999 102.667)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="104.999"
                                                cy={88}
                                                r="1.66667"
                                                transform="rotate(180 104.999 88)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="104.999"
                                                cy="73.3333"
                                                r="1.66667"
                                                transform="rotate(180 104.999 73.3333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="104.999"
                                                cy={45}
                                                r="1.66667"
                                                transform="rotate(180 104.999 45)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="104.999"
                                                cy={16}
                                                r="1.66667"
                                                transform="rotate(180 104.999 16)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="104.999"
                                                cy={59}
                                                r="1.66667"
                                                transform="rotate(180 104.999 59)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="104.999"
                                                cy="30.6666"
                                                r="1.66667"
                                                transform="rotate(180 104.999 30.6666)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="104.999"
                                                cy="1.66665"
                                                r="1.66667"
                                                transform="rotate(180 104.999 1.66665)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="90.3333"
                                                cy={132}
                                                r="1.66667"
                                                transform="rotate(180 90.3333 132)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="90.3333"
                                                cy="117.333"
                                                r="1.66667"
                                                transform="rotate(180 90.3333 117.333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="90.3333"
                                                cy="102.667"
                                                r="1.66667"
                                                transform="rotate(180 90.3333 102.667)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="90.3333"
                                                cy={88}
                                                r="1.66667"
                                                transform="rotate(180 90.3333 88)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="90.3333"
                                                cy="73.3333"
                                                r="1.66667"
                                                transform="rotate(180 90.3333 73.3333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="90.3333"
                                                cy={45}
                                                r="1.66667"
                                                transform="rotate(180 90.3333 45)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="90.3333"
                                                cy={16}
                                                r="1.66667"
                                                transform="rotate(180 90.3333 16)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="90.3333"
                                                cy={59}
                                                r="1.66667"
                                                transform="rotate(180 90.3333 59)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="90.3333"
                                                cy="30.6666"
                                                r="1.66667"
                                                transform="rotate(180 90.3333 30.6666)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="90.3333"
                                                cy="1.66665"
                                                r="1.66667"
                                                transform="rotate(180 90.3333 1.66665)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="75.6654"
                                                cy={132}
                                                r="1.66667"
                                                transform="rotate(180 75.6654 132)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy={132}
                                                r="1.66667"
                                                transform="rotate(180 31.9993 132)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="75.6654"
                                                cy="117.333"
                                                r="1.66667"
                                                transform="rotate(180 75.6654 117.333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy="117.333"
                                                r="1.66667"
                                                transform="rotate(180 31.9993 117.333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="75.6654"
                                                cy="102.667"
                                                r="1.66667"
                                                transform="rotate(180 75.6654 102.667)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy="102.667"
                                                r="1.66667"
                                                transform="rotate(180 31.9993 102.667)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="75.6654"
                                                cy={88}
                                                r="1.66667"
                                                transform="rotate(180 75.6654 88)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy={88}
                                                r="1.66667"
                                                transform="rotate(180 31.9993 88)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="75.6654"
                                                cy="73.3333"
                                                r="1.66667"
                                                transform="rotate(180 75.6654 73.3333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy="73.3333"
                                                r="1.66667"
                                                transform="rotate(180 31.9993 73.3333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="75.6654"
                                                cy={45}
                                                r="1.66667"
                                                transform="rotate(180 75.6654 45)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy={45}
                                                r="1.66667"
                                                transform="rotate(180 31.9993 45)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="75.6654"
                                                cy={16}
                                                r="1.66667"
                                                transform="rotate(180 75.6654 16)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy={16}
                                                r="1.66667"
                                                transform="rotate(180 31.9993 16)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="75.6654"
                                                cy={59}
                                                r="1.66667"
                                                transform="rotate(180 75.6654 59)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy={59}
                                                r="1.66667"
                                                transform="rotate(180 31.9993 59)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="75.6654"
                                                cy="30.6666"
                                                r="1.66667"
                                                transform="rotate(180 75.6654 30.6666)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy="30.6666"
                                                r="1.66667"
                                                transform="rotate(180 31.9993 30.6666)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="75.6654"
                                                cy="1.66665"
                                                r="1.66667"
                                                transform="rotate(180 75.6654 1.66665)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="31.9993"
                                                cy="1.66665"
                                                r="1.66667"
                                                transform="rotate(180 31.9993 1.66665)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="60.9993"
                                                cy={132}
                                                r="1.66667"
                                                transform="rotate(180 60.9993 132)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy={132}
                                                r="1.66667"
                                                transform="rotate(180 17.3333 132)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="60.9993"
                                                cy="117.333"
                                                r="1.66667"
                                                transform="rotate(180 60.9993 117.333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy="117.333"
                                                r="1.66667"
                                                transform="rotate(180 17.3333 117.333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="60.9993"
                                                cy="102.667"
                                                r="1.66667"
                                                transform="rotate(180 60.9993 102.667)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy="102.667"
                                                r="1.66667"
                                                transform="rotate(180 17.3333 102.667)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="60.9993"
                                                cy={88}
                                                r="1.66667"
                                                transform="rotate(180 60.9993 88)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy={88}
                                                r="1.66667"
                                                transform="rotate(180 17.3333 88)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="60.9993"
                                                cy="73.3333"
                                                r="1.66667"
                                                transform="rotate(180 60.9993 73.3333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy="73.3333"
                                                r="1.66667"
                                                transform="rotate(180 17.3333 73.3333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="60.9993"
                                                cy={45}
                                                r="1.66667"
                                                transform="rotate(180 60.9993 45)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy={45}
                                                r="1.66667"
                                                transform="rotate(180 17.3333 45)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="60.9993"
                                                cy={16}
                                                r="1.66667"
                                                transform="rotate(180 60.9993 16)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy={16}
                                                r="1.66667"
                                                transform="rotate(180 17.3333 16)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="60.9993"
                                                cy={59}
                                                r="1.66667"
                                                transform="rotate(180 60.9993 59)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy={59}
                                                r="1.66667"
                                                transform="rotate(180 17.3333 59)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="60.9993"
                                                cy="30.6666"
                                                r="1.66667"
                                                transform="rotate(180 60.9993 30.6666)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy="30.6666"
                                                r="1.66667"
                                                transform="rotate(180 17.3333 30.6666)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="60.9993"
                                                cy="1.66665"
                                                r="1.66667"
                                                transform="rotate(180 60.9993 1.66665)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="17.3333"
                                                cy="1.66665"
                                                r="1.66667"
                                                transform="rotate(180 17.3333 1.66665)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="46.3333"
                                                cy={132}
                                                r="1.66667"
                                                transform="rotate(180 46.3333 132)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy={132}
                                                r="1.66667"
                                                transform="rotate(180 2.66536 132)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="46.3333"
                                                cy="117.333"
                                                r="1.66667"
                                                transform="rotate(180 46.3333 117.333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy="117.333"
                                                r="1.66667"
                                                transform="rotate(180 2.66536 117.333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="46.3333"
                                                cy="102.667"
                                                r="1.66667"
                                                transform="rotate(180 46.3333 102.667)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy="102.667"
                                                r="1.66667"
                                                transform="rotate(180 2.66536 102.667)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="46.3333"
                                                cy={88}
                                                r="1.66667"
                                                transform="rotate(180 46.3333 88)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy={88}
                                                r="1.66667"
                                                transform="rotate(180 2.66536 88)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="46.3333"
                                                cy="73.3333"
                                                r="1.66667"
                                                transform="rotate(180 46.3333 73.3333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy="73.3333"
                                                r="1.66667"
                                                transform="rotate(180 2.66536 73.3333)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="46.3333"
                                                cy={45}
                                                r="1.66667"
                                                transform="rotate(180 46.3333 45)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy={45}
                                                r="1.66667"
                                                transform="rotate(180 2.66536 45)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="46.3333"
                                                cy={16}
                                                r="1.66667"
                                                transform="rotate(180 46.3333 16)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy={16}
                                                r="1.66667"
                                                transform="rotate(180 2.66536 16)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="46.3333"
                                                cy={59}
                                                r="1.66667"
                                                transform="rotate(180 46.3333 59)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy={59}
                                                r="1.66667"
                                                transform="rotate(180 2.66536 59)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="46.3333"
                                                cy="30.6666"
                                                r="1.66667"
                                                transform="rotate(180 46.3333 30.6666)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy="30.6666"
                                                r="1.66667"
                                                transform="rotate(180 2.66536 30.6666)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="46.3333"
                                                cy="1.66665"
                                                r="1.66667"
                                                transform="rotate(180 46.3333 1.66665)"
                                                fill="#00acee"
                                            />
                                            <circle
                                                cx="2.66536"
                                                cy="1.66665"
                                                r="1.66667"
                                                transform="rotate(180 2.66536 1.66665)"
                                                fill="#00acee"
                                            />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
