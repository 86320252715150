import React from "react";
import SVG from "../../../assets/hosting/datacenter.svg";

export default function Datacenters () {
    return (
        <section className="bg-gray-900 dark:bg-gray-900 py-12 lg:py-4">
            <div className="py-12 lg:py-8 container mx-auto max-w-7xl">
                <div className="flex flex-col items-center justify-center gap-6 lg:gap-8">
                    <h1 className="text-5xl font-bold text-white text-center dark:text-white">Datacenters</h1>
                    <h2 className="text-3xl font-bold text-white text-center dark:text-white">8 Regiones, 11 Data Centers, Cobertura Global</h2>
                    <div className="w-full mx-auto">
                        <img src={SVG} alt="Datacenters" />
                    </div>
                </div>
            </div>
            <div className="container px-4 mx-auto max-w-7xl">
                <div className="grid grid-cols-1 mt-8 gap-6 lg:grid-cols-3 ">
                    <div className="bg-white dark:bg-gray-900 shadow-lg rounded-lg p-6 hover:-translate-y-1 transition-transform duration-300 hover:shadow-xl border border-yellow-700">
                      <img src='https://images.ctfassets.net/uhwn5kvabnul/1UJ8ud70w9ViOJ90lIEtal/c6915573c66903a043d53e10484dccb7/location-EU_EN.png?w=240&fit=pad&q=80&fm=webp' alt="Datacenter 1" className="w-20 h-20 mx-auto" />
                        <h3 className="text-2xl font-bold dark:text-white text-center mt-2 mb-2">Europa</h3>
                        <p className="text-gray-600 dark:text-gray-400">Servimos a Europa, África y Medio Oriente desde 5 centros de datos en Alemania. Seleccione Unión Europea mientras configura su servidor para implementar sus servicios allí.</p>
                    </div>
                    <div className="bg-white dark:bg-gray-900 shadow-lg rounded-lg p-6 hover:-translate-y-1 transition-transform duration-300 hover:shadow-xl border border-yellow-700">
                      <img src='https://images.ctfassets.net/uhwn5kvabnul/6PK4klnJBERK51DwIImYU1/26593abc03f29746a9ea6c98678dd9a5/location-US.png?w=240&fit=pad&q=80&fm=webp' alt="Datacenter 2" className="w-20 h-20 mx-auto" />
                        <h3 className="text-xl font-bold dark:text-white text-center mt-2 mb-2">Estados Unidos</h3>
                        <p className="text-gray-600 dark:text-gray-400">Para América hay 3 Regiones disponibles. Puede elegir entre las regiones EE.UU. Este (Nueva York), EE.UU. Oeste (Seattle) y EE.UU. Central (St. Louis).</p>
                    </div>
                    <div className="bg-white dark:bg-gray-900 shadow-lg rounded-lg p-6 hover:-translate-y-1 transition-transform duration-300 hover:shadow-xl border border-yellow-700">
                      <img src='https://images.ctfassets.net/uhwn5kvabnul/2XDGW4obbgTgqREYhRboNL/51552ed3791364c317bc591946d18fe3/location-UK.png?w=240&fit=pad&q=80&fm=webp' alt="Datacenter 3" className="w-20 h-20 mx-auto" />
                        <h3 className="text-xl font-bold dark:text-white text-center mt-2 mb-2">Reino Unido</h3>
                        <p className="text-gray-600 dark:text-gray-400">El Reino Unido e Irlanda recibe servicio desde nuestro centro de datos en Portsmouth, Inglaterra. Para aprovechar seleccione Reino Unido en el configurador.</p>
                    </div>
                    <div className="bg-white dark:bg-gray-900 shadow-lg rounded-lg p-6 hover:-translate-y-1 transition-transform duration-300 hover:shadow-xl border border-yellow-700">
                      <img src='https://images.ctfassets.net/uhwn5kvabnul/50rkXGhkCS8zq6JUtOKRHU/525fe33b861d7173fb9f0d9b7ad028d5/location-asia.png?w=240&fit=pad&q=80&fm=webp' alt="Datacenter 4" className="w-20 h-20 mx-auto" />
                        <h3 className="text-xl font-bold dark:text-white text-center mt-2 mb-2">Singapur</h3>
                        <p className="text-gray-600 dark:text-gray-400">Nuestros clientes en el sur y sudeste de Asia pueden aprovechar nuestro centro de datos en Singapur. Para hacerlo, elija Asia (Singapur) en el configurador de productos.</p>
                    </div>
                    <div className="bg-white dark:bg-gray-900 shadow-lg rounded-lg p-6 hover:-translate-y-1 transition-transform duration-300 hover:shadow-xl border border-yellow-700">
                      <img src='https://images.ctfassets.net/uhwn5kvabnul/7mOsuTTRQ84NOl9QJYdJ8c/5edb7bba59e55c7bda6bcad6c8696fb8/location-jp.png?w=240&fit=pad&q=80&fm=webp' alt="Datacenter 5" className="w-20 h-20 mx-auto" />
                        <h3 className="text-xl font-bold dark:text-white text-center mt-2 mb-2">Japón</h3>
                        <p className="text-gray-600 dark:text-gray-400">Las personas en Japón, Corea y el este de Asia obtendrán la mejor experiencia al seleccionar nuestro centro de datos en Tokio. Seleccione Asia (Japón) mientras configura su servidor.</p>
                    </div>
                    <div className="bg-white dark:bg-gray-900 shadow-lg rounded-lg p-6 hover:-translate-y-1 transition-transform duration-300 hover:shadow-xl border border-yellow-700">
                      <img src='https://images.ctfassets.net/uhwn5kvabnul/TcWstXwmZbhcH7EMI0D6M/11d1b3a2fa0965f45afac6a3ebfb2ff4/location-australia.png?w=240&fit=pad&q=80&fm=webp' alt="Datacenter 6" className="w-20 h-20 mx-auto" />
                        <h3 className="text-xl font-bold dark:text-white text-center mt-2 mb-2">Australia</h3>
                        <p className="text-gray-600 dark:text-gray-400">Brindamos conectividad premium a todos en Australia desde nuestro centro de datos de Sydney.</p>
                    </div>
                </div>
            </div>
            <div className="container mx-auto py-6  lg:py-6">
                <div className="flex flex-col items-center justify-center gap-6 lg:gap-6">
                    <h2 className="text-3xl font-bold text-white text-center dark:text-white">Próximos Datacenters</h2>
                    <p className="text-center text-gray-300 dark:text-gray-400">Estamos trabajando en la expansión de nuestra red de centros de datos. <br/>Próximamente en <strong>Costa Rica</strong> e <strong>India</strong>.</p>
                </div>
            </div>
        </section>
    );
}
