import React from "react";
import costabravaLogo from "../../assets/images/companylogos/costabrava.png";
import fractalLogo from "../../assets/images/companylogos/fractal.png";
import hkLogo from "../../assets/images/companylogos/hk.png";
import julioLogo from "../../assets/images/companylogos/julioC.png";

export default function Testimonials() {
  return (
    <div className="max-w-[95rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <h2 className="text-2xl mb-5 font-nasalization text-[#1f2937] font-bold md:text-4xl md:leading-tight dark:text-white">
        Reviews
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Testimonial 1 */}
        <div className="flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700">
          <div className="flex-auto p-4 md:p-6">
            <div className="flex items-center p-4 rounded-b-xl md:px-6">
              <img
                src={fractalLogo}
                alt="Julián Monge"
                className="w-12 h-12 rounded-full mr-4"
              />
              <div>
                <h3 className="text-sm font-semibold text-gray-800 sm:text-base dark:text-gray-200">
                  Julián Monge
                </h3>
                <p className="text-sm text-gray-500">Fractal Agency CEO</p>
              </div>
            </div>
            <p className="mt-3 sm:mt-6 text-base text-gray-800 md:text-xl dark:text-white">
              <em>
                "En Fractal Creative Agency nos caracterizamos por trabajar con
                los mejores equipos. Por lo mismo, nos enorgullece tener al
                equipo de WeCode en nuestro portafolio laboral. Es un equipo de
                profesionales responsable que sobrepasa las expectativas que se
                podrían tener de una empresa que brinda soluciones para
                facilitar los procesos empresariales."
              </em>
            </p>
          </div>
        </div>

        {/* Testimonial 2 */}
        <div className="flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700">
          <div className="flex-auto p-4 md:p-6">
            <div className="flex items-center p-4 rounded-b-xl md:px-6">
              <img
                src={costabravaLogo}
                alt="Rodolfo Castro"
                className="w-12 h-12 rounded-full mr-4"
              />
              <div>
                <h3 className="text-sm font-semibold text-gray-800 sm:text-base dark:text-gray-200">
                  Rodolfo Castro
                </h3>
                <p className="text-sm text-gray-500">Costa Brava Real Estate</p>
              </div>
            </div>
            <p className="mt-3 sm:mt-6 text-base text-gray-800 md:text-xl dark:text-white">
              <em>
                "WeCode es una empresa que nos ha ayudado a crecer en el mundo
                digital, con su experiencia y conocimiento nos han ayudado a
                crear nuestra pagina web y atraer mas clientes."
              </em>
            </p>
          </div>
        </div>

        {/* Testimonial 3 */}
        <div className="flex flex-col bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700">
          <div className="flex-auto p-4 md:p-6">
            <div className="flex items-center p-4 rounded-b-xl md:px-6">
              <img
                src={julioLogo}
                alt="Julio Carvajal"
                className="w-12 h-12 rounded-full mr-4"
              />
              <div>
                <h3 className="text-sm font-semibold text-gray-800 sm:text-base dark:text-gray-200">
                  Julio Carvajal
                </h3>
                <p className="text-sm text-gray-500">
                  Filmmaker & Fotógrafo para Netflix, CNN, Discovery Channel,
                  DJI Global, GoPro, Ford, Hyundai
                </p>
              </div>
            </div>
            <p className="mt-3 sm:mt-6 text-base text-gray-800 md:text-xl dark:text-white">
              <em>
                "En varias ocasiones he tenido la fortuna de contratar los
                servicios de WeCode y en cada una de esas ocasiones han
                sobresalido. El team de Developers es muy capaz y tiene un
                excelente customer service. Lo recomendaria al 100%"
              </em>
            </p>
          </div>
          <div className="flex items-center p-4 rounded-b-xl md:px-6"></div>
        </div>
      </div>
    </div>
  );
}
