import React from "react";

import DeliveryPage from "../../assets/images/projects/deliveryPage.webp";
import Construccion from "../../assets/images/projects/ConstruccionCR.webp";
import Julio from "../../assets/images/projects/julio.webp";
import CostaBrava from "../../assets/images/projects/CostaBrava.webp";
import FlordeCholate from "../../assets/images/projects/flordecholoate.webp";
import DWeb from "../../assets/images/projects/3D-web.webp";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { useTranslation } from "react-i18next";

export default function Projects() {
  const { t } = useTranslation();

  return (
    <div
      id="clients"
      className="max-w-[95rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
    >
      <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
        <h2 className="text-2xl font-nasalization text-[#1f2937] font-bold md:text-4xl md:leading-tight dark:text-white">
          {t("ProjectsTitle.text")}
        </h2>
        <p className="mt-6 text-gray-600 dark:text-gray-300">
          {t("ProjectsText.text")}
        </p>
      </div>

      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <a
          className="group rounded-xl overflow-hidden"
          href="https://juliocarvajal.cr/"
          target="_blank"
        >
          <div className="relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden">
            <LazyLoadImage
              className="w-full h-full absolute top-0 left-0 object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl"
              src={Julio}
              
              
              alt="Julio Carvajal"
            />
          </div>

          <div className="mt-7">
            <h3 className="text-xl font-semibold text-[#1f2937] group-hover:text-gray-600 dark:text-gray-200">
              {t("Project4Title.text")}
            </h3>
            <p className="mt-3 text-[#1f2937] dark:text-gray-200">
              {t("Project4Text.text")}
            </p>
            <p className="mt-5 inline-flex items-center gap-x-1.5 text-[#00acee] decoration-2 group-hover:underline font-medium">
              {t("VistitButton.text")}
              <svg
                className="w-2.5 h-2.5"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </p>
          </div>
        </a>

        <a
          className="group rounded-xl overflow-hidden"
          target="_blank"
          href="https://costabravarealestate.cr/"
        >
          <div className="relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden">
            <LazyLoadImage
              className="w-full h-full absolute top-0 left-0 object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl"
              src={CostaBrava}
              
              alt="Costa Brava Real Estate"
            />
          </div>

          <div className="mt-7">
            <h3 className="text-xl font-semibold text-[#1f2937] group-hover:text-gray-600 dark:text-gray-200">
              {t("Project6Title.text")}
            </h3>
            <p className="mt-3 text-[#1f2937] dark:text-gray-200">
              {t("Project6Text.text")}
            </p>
            <p className="mt-5 inline-flex items-center gap-x-1.5 text-[#00acee] decoration-2 group-hover:underline font-medium">
              {t("VistitButton.text")}
              <svg
                className="w-2.5 h-2.5"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </p>
          </div>
        </a>

        <a
          className="group rounded-xl overflow-hidden"
          target="_blank"
          href="https://flordechocolatecr.com/"
        >
          <div className="relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden">
            <LazyLoadImage
              className="w-full h-full absolute top-0 left-0 object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl"
              src={FlordeCholate}
              
              alt="Flor de Chocolate"
            />
          </div>

          <div className="mt-7">
            <h3 className="text-xl font-semibold text-[#1f2937] group-hover:text-gray-600 dark:text-gray-200">
              {t("Project7Title.text")}
            </h3>
            <p className="mt-3 text-[#1f2937] dark:text-gray-200">
              {t("Project7Text.text")}
            </p>
            <p className="mt-5 inline-flex items-center gap-x-1.5 text-[#00acee] decoration-2 group-hover:underline font-medium">
              {t("VistitButton.text")}
              <svg
                className="w-2.5 h-2.5"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </p>
          </div>
        </a>

        <a
          className="group rounded-xl overflow-hidden "
          href="https://3dsite.wecodecr.com/"
          target="_blank"
        >
          <div className="relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden">
            <LazyLoadImage
              className="w-full h-full absolute top-0 left-0 object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl"
              src={DWeb}
              
              alt="Sitio Web 3D"
            />
            {/* <span className="absolute top-0 right-0 rounded-tr-xl rounded-bl-xl text-xs font-medium bg-gray-800 text-white py-1.5 px-3 dark:bg-gray-900">
              Cliente
            </span> */}
          </div>

          <div className="mt-7">
            <h3 className="text-xl font-semibold text-[#1f2937] group-hover:text-gray-600 dark:text-gray-200">
              {t("Project2Title.text")}
            </h3>
            <p className="mt-3 text-[#1f2937] dark:text-gray-200">
              {t("Project2Text.text")}
            </p>
            <p className="mt-5 inline-flex items-center gap-x-1.5 text-[#00acee] decoration-2 group-hover:underline font-medium">
              {t("VistitButton.text")}
              <svg
                className="w-2.5 h-2.5"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </p>
          </div>
        </a>

        <a
          className="group rounded-xl overflow-hidden"
          href="https://constructora.wecodecr.com/"
          target="_blank"
        >
          <div className="relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden">
            <LazyLoadImage
              className="w-full h-full absolute top-0 left-0 object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl"
              src={Construccion}
              
              alt="Sitio Web de una empresa de construcción"
            />
            {/* <span className="absolute top-0 right-0 rounded-tr-xl rounded-bl-xl text-xs font-medium bg-gray-800 text-white py-1.5 px-3 dark:bg-gray-900">
              Sponsored
            </span> */}
          </div>

          <div className="mt-7">
            <h3 className="text-xl font-semibold text-[#1f2937] group-hover:text-gray-600 dark:text-gray-200">
              {t("Project3Title.text")}
            </h3>
            <p className="mt-3 text-[#1f2937] dark:text-gray-200">
              {t("Project3Text.text")}
            </p>
            <p className="mt-5 inline-flex items-center gap-x-1.5 text-[#00acee] decoration-2 group-hover:underline font-medium">
              {t("VistitButton.text")}
              <svg
                className="w-2.5 h-2.5"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </p>
          </div>
        </a>

        <a
          className="group rounded-xl overflow-hidden"
          href="https://delivery.wecodecr.com/"
          target="_blank"
        >
          <div className="relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden">
            <LazyLoadImage
              className="w-full h-full absolute top-0 left-0 object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl"
              src={DeliveryPage}
              
              alt="Sitio Web de una empresa de Pedidos y Entregas"
            />
          </div>

          <div className="mt-7">
            <h3 className="text-xl font-semibold text-[#1f2937] group-hover:text-gray-600 dark:text-gray-200">
              {t("Project1Title.text")}
            </h3>
            <p className="mt-3 text-[#1f2937] dark:text-gray-200">
              {t("Project1Text.text")}
            </p>
            <p className="mt-5 inline-flex items-center gap-x-1.5 text-[#00acee] decoration-2 group-hover:underline font-medium">
              {t("VistitButton.text")}
              <svg
                className="w-2.5 h-2.5"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </p>
          </div>
        </a>

        {/* <a
          className="group relative flex flex-col w-full min-h-[15rem] bg-center bg-cover rounded-xl hover:shadow-lg transition bg-[url('https://images.unsplash.com/photo-1634017839464-5c339ebe3cb4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3000&q=80')]"
          href="#"
        >
          <div className="flex-auto p-4 md:p-6">
            <h3 className="text-xl text-white/[.9] group-hover:text-white">
              <span className="font-bold">Preline</span> Press publishes books about
              economic and technological advancement.
            </h3>
          </div>
          <div className="pt-0 p-4 md:p-6">
            <div className="inline-flex items-center gap-2 text-sm font-medium text-white group-hover:text-white/[.7]">
              Visit the site
              <svg
                className="w-2.5 h-2.5"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
        </a> */}
      </div>
    </div>
  );
}
