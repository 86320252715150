import React from "react";

export default function FeaturesFooter() {
  return (
    <section id="new-features" className="py-8 bg-white sm:py-10 lg:py-16 dark:bg-gray-900">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-700 leading-tight  sm:text-4xl xl:text-5xl dark:text-white">
            Nuestras características
          </h2>
          <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-8 dark:text-gray-300">
            Obtienes lo mejor de nuestro servicio de alojamiento web con estas
            características avanzadas.
          </p>
        </div>
        <div className="grid grid-cols-1 mt-10 text-center sm:mt-16 sm:grid-cols-2 sm:gap-x-12 gap-y-12 md:grid-cols-3 md:gap-0 xl:mt-24">
          <div className="md:p-8 lg:p-14 flex flex-col justify-center items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/4537/4537406.png"
              alt="lock"
              className="w-12 h-12"
            />
            <h3 className="mt-12 text-xl font-bold text-gray-900 dark:text-white">
              Garantía de tiempo de actividad
            </h3>
            <p className="mt-5 text-base text-gray-600 dark:text-gray-300">
              Ofrecemos una garantía de tiempo de actividad para tu sitio web,
              asegurando que esté siempre en línea y accesible para tus
              visitantes.
            </p>
          </div>

          <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 flex flex-col justify-center items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/508/508250.png"
              alt="lock"
              className="w-12 h-12"
            />
            <h3 className="mt-12 text-xl font-bold text-gray-900 dark:text-white">
              Protección avanzada contra amenazas
            </h3>
            <p className="mt-5 text-base text-gray-600 dark:text-gray-300">
              Utilizamos firewalls de última generación, sistemas de detección
              de intrusiones (IDS) y prevención de intrusiones (IPS), así como
              filtros de seguridad a nivel de aplicación (WAF).
            </p>
          </div>

          <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 flex flex-col justify-center items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/9160/9160755.png"
              alt="lock"
              className="w-12 h-12"
            />
            <h3 className="mt-12 text-xl font-bold text-gray-900 dark:text-white">
              Actualizaciones automáticas
            </h3>
            <p className="mt-5 text-base text-gray-600 dark:text-gray-300">
              Mantenemos nuestros servidores y software actualizados
              regularmente con los últimos versiones, asegurando que tu sitio
              web esté protegido contra las últimas amenazas de seguridad.
            </p>
          </div>

          <div className="md:p-8 lg:p-14 md:border-t md:border-gray-200 flex flex-col justify-center items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/1150/1150638.png"
              alt="lock"
              className="w-12 h-12"
            />
            <h3 className="mt-12 text-xl font-bold text-gray-900 dark:text-white">
              Monitoreo 24/7 del servidor
            </h3>
            <p className="mt-5 text-base text-gray-600 dark:text-gray-300">
              Nuestro equipo de operaciones monitorea constantemente el
              rendimiento y la disponibilidad del servidor las 24 horas del día,
              los 7 días de la semana.
            </p>
          </div>

          <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 md:border-t flex flex-col justify-center items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/2818/2818744.png"
              alt="lock"
              className="w-12 h-12"
            />
            <h3 className="mt-12 text-xl font-bold text-gray-900 dark:text-white">
              Respaldos y Copias de Seguridad
            </h3>
            <p className="mt-5 text-base text-gray-600 dark:text-gray-300">
              Realizamos copias de seguridad diarias de tu sitio web y base de
              datos, asegurando que tus datos estén seguros y protegidos en caso
              de pérdida de datos o corrupción.
            </p>
          </div>

          <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 md:border-t flex flex-col justify-center items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/3094/3094910.png"
              alt="lock"
              className="w-12 h-12"
            />
            <h3 className="mt-12 text-xl font-bold text-gray-900 dark:text-white">
              Optimización de rendimiento
            </h3>
            <p className="mt-5 text-base text-gray-600 dark:text-gray-300">
              Optimizamos la configuración del servidor, la infraestructura de
              red y una velocidad de carga rápida para tu sitio web, mejorando
              la experiencia del usuario y el posicionamiento en los motores de
              búsqueda.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
