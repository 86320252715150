import React, { useState, useEffect } from "react";
import data from "../data.json";

export default function Pricing() {
  // State for billing cycle
  const [isAnnual, setIsAnnual] = React.useState(false);
  const [plans, setPlans] = useState([]);

  // Function to toggle billing cycle
  const toggleBillingCycle = () => setIsAnnual(!isAnnual);

  useEffect(() => {
    setPlans(data.vps);
  }, []);

  return (
    <div class="max-w-7xl mx-auto py-20">
      <div
        id="plans"
        className="w-2/3 sm:w-1/2 lg:w-1/3 mx-auto text-center mt-10 md:mt-14 lg:mt-24"
      >
        <h1 className="dark:text-white text-gray-700 text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
          Los mejores planes de hosting
        </h1>
      </div>
      <div className="flex justify-center items-center ">
        <label
          htmlFor="pricing-switch"
          className="min-w-[3.5rem] text-sm text-gray-600 mr-3 dark:text-gray-200"
        >
          Mensual
        </label>
        <input
          type="checkbox"
          id="pricing-switch"
          className="relative shrink-0 w-[3.25rem] h-7 bg-gray-300 text-violet-900 checked:bg-none checked:bg-[#00acee] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 border border-transparent ring-1 ring-transparent focus:border-slate-900 focus:ring-slate-900 ring-offset-white focus:outline-none appearance-none dark:bg-gray-700 dark:checked:bg-yellow-500 dark:focus:ring-offset-gray-800 before:inline-block before:w-6 before:h-6 before:bg-white before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-gray-400"
          checked={isAnnual}
          onChange={toggleBillingCycle}
        />
        <label
          htmlFor="pricing-switch"
          className="min-w-[3.5rem] text-sm text-gray-600 ml-3 dark:text-gray-200"
        >
          Anual
        </label>
      </div>
      {/* End Switch */}
      {/* Grid */}
      <div className="mt-6 md:mt-12 grid sm:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-6 lg:gap-3 xl:gap-6 lg:items-center">
        {/* Map over hosting data */}
        {/* Mostrar solo los primeros 4 vps */}
        {plans.slice(0, 4).map((vps) => (
          <div
            key={vps.id}
            className={`flex flex-col bg-white border border-gray-200 text-center rounded-lg p-4 md:p-8 dark:bg-slate-900 dark:border-gray-700 hover:shadow-lg ${
              vps.featured
                ? "border-2 border-yellow-700 shadow-lg h-full hover:shadow-xl transform hover:-translate-y-1 transition-transform duration-300 dark:border-yellow-500"
                : "h-full hover:-translate-y-1 transition-transform duration-300"
            }`}
          >
            <img className="h-16 w-auto mx-auto mt-4 mb-4" src={vps.icon} />
            <h4 className="font-medium text-lg text-gray-800 dark:text-gray-200">
              {vps.title}
            </h4>
            {/* Conditional rendering for price */}
            <span className="mt-7 font-bold text-3xl md:text-3xl xl:text-4xl text-gray-800 dark:text-gray-200">
  {isAnnual
    ? `$${(vps.price * 12 * 0.8).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` // 20% discount for annual
    : `$${vps.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}{" "}
  /{" "}
  <span className="text-sm text-gray-500 dark:text-gray-400">
    {isAnnual ? "año" : "mes"}
  </span>
  {isAnnual && (
    <span className="block text-sm text-yellow-900 mt-1 dark:text-yellow-500">
      Ahorras $ {(vps.price * 12 * 0.2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} al año
    </span>
  )}
</span>


            <p className="mt-2 text-md text-gray-700 font-semibold dark:text-gray-300">
              {vps.description}
            </p>
            {/* Mapping over details */}
            <ul className="mt-7 space-y-2.5 text-sm">
              {vps.details.map((detail, index) => (
                <li key={index} className="flex space-x-2">
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-blue-700"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5219 4.0949C11.7604 3.81436 12.181 3.78025 12.4617 4.01871C12.7422 4.25717 12.7763 4.6779 12.5378 4.95844L6.87116 11.6251C6.62896 11.91 6.1998 11.94 5.9203 11.6916L2.9203 9.02494C2.64511 8.78033 2.62032 8.35894 2.86493 8.08375C3.10955 7.80856 3.53092 7.78378 3.80611 8.02839L6.29667 10.2423L11.5219 4.0949Z"
                      fill="currentColor"
                    />
                  </svg>
                  <span className="text-gray-800 dark:text-gray-400 font-medium">
                    {detail}
                  </span>
                </li>
              ))}
            </ul>
            <a
              className="mt-5 inline-flex justify-center items-center gap-1.5 py-2 px-4 bg-blue-700 hover:bg-yellow-700 text-white text-sm font-medium rounded-lg transition-colors duration-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-violet-500"
              href="#"
            >
              Comprar ahora
            </a>
          </div>
        ))}
        {/* End Map */}
      </div>
      {/* End Grid */}
    </div>
  );
}
