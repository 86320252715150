import React from "react";
import Navbar from "../navbar";
import Hero  from "./hero";
import Features from "./features";
import Pricing from "./pricing";
import FeaturesFooter from "./featuresFooter";
import CTA from "./cta";
import FAQ from "./faq";
import Footer from "../../home/footer";

export default function MainVDS() {
  return (
    <>
      <div className="bg-gray-900">
        <Navbar />
        <Hero />
      </div>
        <div className="bg-white dark:bg-gray-900 -mt-2 sm:-mt-2 lg:-mt-2">
        <Features />
        <Pricing />
        <FeaturesFooter />
        <CTA />
        <FAQ />
        <Footer />
      </div>
    </>
  );
}
