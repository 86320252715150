import React from "react";
import { Nav } from "../home/navbar";
import Footer from "../home/footer";
import LazyLoadImage1 from "../../assets/3d/parallax-1.webp";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const Team = () => {
  const { t } = useTranslation();

  return (
    <div className="dark:bg-gray-900">
      <Nav />
      <div className="relative font-sans flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0 ">
        <div className="inset-y-0 top-0 -right-20 w-full max-w-xl px-4 mx-auto md:w-6/12 lg:w-7/12 lg:max-w-full lg:absolute  ">
          <LazyLoadImage
            className="object-cover w-full h-68 lg:rounded-none lg:shadow-none md:h-96 lg:h-full lg:w-9/12"
            src={LazyLoadImage1}
            effect="blur"
            alt=""
          />
        </div>
        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
          <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-[#1f2937] uppercase rounded-full bg-teal-accent-400 dark:text-white">
              {t("team.HeroTeamsBar")}{" "}
            </p>
            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-[#1f2937] sm:text-4xl sm:leading-none dark:text-white">
              {t("team.HeroTitle1")} <br className="hidden md:block" />
              {t("team.HeroTitle2")}{" "}
              <span className="inline-block text-[#00acee]">
                {t("team.HeroTitle3")}{" "}
              </span>
            </h2>
            <p className="pr-5 mb-5 text-base text-gray-700 md:text-lg dark:text-gray-300">
              {t("team.HeroSubtitle")}{" "}
            </p>
            <div className="flex items-center">
              <a
                href="/contact"
                className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded-[10px] shadow-md bg-[#00acee] hover:bg-[#00acee] dark:bg-[#007cad] focus:shadow-outline focus:outline-none hover:scale-105"
              >
                {t("team.HeroButton")}{" "}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="relative font-sans max-w-screen-xl p-4 px-4 mx-auto bg-white dark:bg-gray-900 sm:px-6 lg:px-8 py-26 lg:mt-20">
        <div className="relative">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="ml-auto lg:col-start-2 lg:max-w-2xl">
              <p className="text-base font-bold text-[#00acee] uppercase">
                {t("team.BarMissionandVision")}{" "}
              </p>
              <h4 className="mt-2 text-2xl font-bold text-[#1f2937] dark:text-white sm:text-3xl sm:leading-9">
                {t("team.TitleMissionandVision")}{" "}
              </h4>
              <p className="mt-4 text-lg leading-6 text-gray-500 dark:text-gray-300">
                {t("team.SubtitleMissionandVision")}{" "}
              </p>
              <div className="space-y-6 lg:space-y-10 mt-5">
                <div className="flex">
                  <svg
                    className="flex-shrink-0 mt-2 h-6 w-6 text-[#1f2937] dark:text-white hover:text-[#00acee] hover:scale-105 transition duration-200"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                    />
                    <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                  </svg>
                  <div className="ml-5 sm:ml-8">
                    <h3 className="text-base sm:text-lg font-semibold text-[#1f2937] dark:text-gray-200 hover:text-[#00acee]">
                      {t("team.CompanyValues")}{" "}
                    </h3>
                    <p className="mt-1 text-gray-600 dark:text-gray-400">
                      {t("team.CompanyValuesDescription")}{" "}
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <svg
                    className="flex-shrink-0 mt-2 h-6 w-6 text-[#1f2937] dark:text-white hover:text-[#00acee] hover:scale-105 transition duration-200"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                  </svg>
                  <div className="ml-5 sm:ml-8">
                    <h3 className="text-base sm:text-lg font-semibold text-[#1f2937] dark:text-gray-200 hover:text-[#00acee] ">
                      {t("team.OurMission")}{" "}
                    </h3>
                    <p className="mt-1 text-gray-600 dark:text-gray-400">
                      {t("team.OurMissionDescription")}{" "}
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <svg
                    className="flex-shrink-0 mt-2 h-6 w-6 text-[#1f2937] dark:text-white hover:text-[#00acee] hover:scale-105 transition duration-200"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                  </svg>
                  <div className="ml-5 sm:ml-8">
                    <h3 className="text-base sm:text-lg font-semibold text-[#1f2937] dark:text-gray-200 hover:text-[#00acee]">
                      {t("team.OurVision")}{" "}
                    </h3>
                    <p className="mt-1 text-gray-600 dark:text-gray-400">
                      {t("team.OurVisionDescription")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative mt-10 lg:-mx-4 relative-20 lg:mt-0 lg:col-start-1">
              <div className="relative space-y-4">
                <div className="flex items-end justify-center space-x-4 lg:justify-start">
                  <LazyLoadImage
                    className="w-32 rounded-lg shadow-lg md:w-56 hover:scale-105 transition duration-200"
                    width="200"
                    effect="blur"
                    src="https://images.pexels.com/photos/3183148/pexels-photo-3183148.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="1"
                  />
                  <LazyLoadImage
                    className="w-40 rounded-lg shadow-lg md:w-64 hover:scale-105 transition duration-200"
                    width="260"
                    effect="blur"
                    src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
                    alt="2"
                  />
                </div>
                <div className="flex items-start justify-center ml-12 space-x-4 lg:justify-start">
                  <LazyLoadImage
                    className="w-24 rounded-lg shadow-lg md:w-40 hover:scale-105 transition duration-200"
                    width="170"
                    effect="blur"
                    src="https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80"
                    alt="3"
                  />
                  <LazyLoadImage
                    className="w-32 rounded-lg shadow-lg md:w-56 hover:scale-105 transition duration-200"
                    width="200"
                    effect="blur"
                    src="https://images.pexels.com/photos/3861964/pexels-photo-3861964.jpeg"
                    alt="4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-24 mx-auto md:px-6 p-4 px-4 sm:px-6">
        <section className="mb-32">
          <div className="flex flex-wrap">
            <div className="mb-12 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-5/12">
              <div className="flex lg:py-12">
                <LazyLoadImage
                  src="https://mdbcdn.b-cdn.net/img/new/standard/people/033.jpg"
                  className="w-full rounded-xl shadow-lg dark:shadow-black/20 lg:ml-[50px] z-[10]"
                  effect="blur"
                  alt="image"
                />
              </div>
            </div>
            <div className="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
              <div className="flex h-full items-center rounded-xl bg-blue-800 p-6 text-center text-white lg:pl-12 lg:text-left">
                <div className="lg:pl-12">
                  <h2 className="mb-6 text-3xl font-bold">
                    {t("team.ctatitle")}
                  </h2>
                  <p className="mb-6 pb-2 lg:pb-0">{t("team.ctatext")}</p>
                  <a
                    href="/contact"
                    className="rounded-xl border-2 border-neutral-50 px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-100 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    {t("team.ctabtn")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
