import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Main } from "./components/home/main";
import { MainPromo } from "./components/promo/main";
import Hosting from "./components/hosting/main/hosting";
import ServicesMain from "./components/services/ServicesMain.tsx";
import { Team } from "./components/about/main";
import { Contact } from "./components/contact/main";
import i18n from "./translations/i18n";
import { ErrorBoundary } from "@sentry/react";
import Careers from "./components/about/careers/careers";
import NotFound from "./components/notfound/main";

// Hosting
import MainVPS from "./components/hosting/vps/mainVps";
import VDS from "./components/hosting/vds/main";
import ServersDedicated from "./components/hosting/serversDedicated/main";
import MainWebHosting from "./components/hosting/webHosting/main";
import Checkout from "./components/hosting/checkout.js";

// Preline imports
import "preline/preline";
import { IStaticMethods } from "preline/preline";
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}
function App() {
  // Configuración automática del idioma
  useEffect(() => {
    const userLang = navigator.language;
    i18n.changeLanguage(userLang);
  }, []);
  return <Router>
      <AppContent />
    </Router>;
}
function AppContent() {
  const location = useLocation();
  useEffect(() => {
    window.HSStaticMethods.autoInit();
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return <ErrorBoundary fallback={<div>An error has occurred</div>}>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/services/*" element={<ServicesMain />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/promo" element={<MainPromo />} />
        {/* Define la ruta principal de hosting */}
        <Route path="/hosting" element={<Hosting />} />
        {/* Define las rutas de hosting */}
        <Route path="/hosting/web-hosting" element={<MainWebHosting />} />
        <Route path="/hosting/vps" element={<MainVPS />} />
        <Route path="/hosting/vds" element={<VDS />} />
        <Route path="/hosting/servers-dedicated" element={<ServersDedicated />} />
        <Route path="/hosting/checkout" element={<Checkout />} />
        {/* Ruta genérica para NotFound */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ErrorBoundary>;
}
export default App;