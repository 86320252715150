import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import "../../App.css";

export default function WebDevelopment() {
  const { t } = useTranslation();
  const textContainerRef = useRef(null);

  const text = t("webDevelopment.title");

  const animateText = () => {
    const textArray = text.split("");
    const shuffledIndices = shuffleArray(textArray.map((_, index) => index));

    shuffledIndices.forEach((index, animationIndex) => {
      gsap.fromTo(
        `.letter-${index}`,
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          duration: 1.5,
          delay: animationIndex * 0.1,
        }
      );
    });
  };

  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }

  useEffect(() => {
    animateText();
  }, []);

  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto dark:bg-gray-900">
      <div className="mx-auto max-w-2xl mb-8 lg:mb-14 text-center">
        <h2
          ref={textContainerRef}
          className="text-4xl font-nasalization lg:text-5xl text-[#1f2937] font-bold dark:text-gray-200"
        >
          {text.split("").map((letter, index) => (
            <span key={index} className={`letter-${index}`}>
              {letter}
            </span>
          ))}
        </h2>
        <p className="mt-3 text-[#1f2937] dark:text-gray-400">
          {t("webDevelopment.description")}
        </p>
      </div>

      <div className="mx-auto max-w-3xl grid grid-cols-12 gap-6 lg:gap-8">
        <div className="col-span-6 sm:col-span-4 text-center">
          <svg
            className="mx-auto h-auto w-7 md:w-9 text-[#1f2937] dark:text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect width="10" height="14" x="3" y="8" rx="2" />
            <path d="M5 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2h-2.4" />
            <path d="M8 18h.01" />
          </svg>
          <div className="mt-2 sm:mt-6">
            <h3 className="text-lg font-semibold text-[#1f2937] dark:text-gray-200">
              {t("webDevelopment.btn1")}
            </h3>
          </div>
        </div>

        <div className="col-span-6 sm:col-span-4 text-center">
          <svg
            className="mx-auto h-auto w-7 md:w-9 text-[#1f2937] dark:text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M20 7h-9" />
            <path d="M14 17H5" />
            <circle cx="17" cy="17" r="3" />
            <circle cx="7" cy="7" r="3" />
          </svg>
          <div className="mt-2 sm:mt-6">
            <h3 className="text-lg font-semibold text-[#1f2937] dark:text-gray-200">
              {t("webDevelopment.btn2")}
            </h3>
          </div>
        </div>

        <div className="col-span-6 col-start-4 sm:col-span-4 text-center">
          <svg
            className="mx-auto h-auto w-7 md:w-9 text-[#1f2937] dark:text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z" />
            <path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1" />
          </svg>
          <div className="mt-2 sm:mt-6">
            <h3 className="text-lg font-semibold text-[#1f2937] dark:text-gray-400">
              {t("webDevelopment.btn3")}
            </h3>
          </div>
        </div>
      </div>

      <div className="mt-20 grid grid-cols-12 items-center gap-x-2 sm:gap-x-6 lg:gap-x-8">
        <div className="hidden md:block col-span-4 md:col-span-3">
          <LazyLoadImage
            effect="blur"
            className="rounded-xl animate-float-second"
            src="https://images.unsplash.com/photo-1606868306217-dbf5046868d2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1981&q=80"
            alt="Computer"
          />
        </div>

        <div className="col-span-4 md:col-span-3">
          <LazyLoadImage
            effect="blur"
            className="rounded-xl animate-float"
            src="https://images.unsplash.com/photo-1507238691740-187a5b1d37b8?q=80&w=1955&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Computer"
          />
        </div>

        <div className="col-span-4 md:col-span-3">
          <LazyLoadImage
            effect="blur"
            className="rounded-xl animate-float-second"
            src="https://images.unsplash.com/photo-1597740985671-2a8a3b80502e?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Code on a computer screen"
          />
        </div>

        <div className="col-span-4 md:col-span-3">
          <LazyLoadImage
            effect="blur"
            className="rounded-xl animate-float-first"
            src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=2015&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Website wireframe on a computer screen"
          />
        </div>
      </div>

      <div className="mt-20 flex items-center justify-center">
        <div className="grid gap-12 grid-cols-2 sm:gap-16 lg:grid-cols-3 lg:gap-40">
          <div>
            <h4 className="text-lg sm:text-xl font-semibold text-[#1f2937] dark:text-gray-200">
              {t("webDevelopment.statstitle1")}
            </h4>
            <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-[#00acee]">
              99.95%
            </p>
            <p className="mt-1 text-gray-500">
              {t("webDevelopment.statstext1")}
            </p>
          </div>

          <div>
            <h4 className="text-lg sm:text-xl font-semibold text-[#1f2937] dark:text-gray-200">
              {t("webDevelopment.statstitle2")}
            </h4>
            <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-[#00acee]">
              2,000+
            </p>
            <p className="mt-1 text-gray-500">
              {t("webDevelopment.statstext2")}
            </p>
          </div>

          <div>
            <h4 className="text-lg sm:text-xl font-semibold text-[#1f2937] dark:text-gray-200">
              {t("webDevelopment.statstitle3")}
            </h4>
            <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-[#00acee]">
              99%
            </p>
            <p className="mt-1 text-gray-500">
              {t("webDevelopment.statstext3")}
            </p>
          </div>
        </div>
      </div>

      <div className="relative p-6 md:p-16 mt-20">
        <div className="relative z-10 lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center">
          <div className="mb-10 lg:mb-0 lg:col-span-6 lg:col-start-8 lg:order-2">
            <h2 className="text-2xl text-[#1f2937] font-bold sm:text-3xl dark:text-gray-200">
              {t("webDevelopment.cardtitle")}
            </h2>

            <nav
              className="grid gap-4 mt-5 md:mt-10"
              aria-label="Tabs"
              role="tablist"
            >
              <button
                type="button"
                className="hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 active"
                id="tabs-with-card-item-1"
                data-hs-tab="#tabs-with-card-1"
                aria-controls="tabs-with-card-1"
                role="tab"
              >
                <span className="flex">
                  <svg
                    className="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-[#00acee] text-[#1f2937] dark:hs-tab-active:text-blue-500 dark:text-gray-200"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M5 5.5A3.5 3.5 0 0 1 8.5 2H12v7H8.5A3.5 3.5 0 0 1 5 5.5z" />
                    <path d="M12 2h3.5a3.5 3.5 0 1 1 0 7H12V2z" />
                    <path d="M12 12.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 1 1-7 0z" />
                    <path d="M5 19.5A3.5 3.5 0 0 1 8.5 16H12v3.5a3.5 3.5 0 1 1-7 0z" />
                    <path d="M5 12.5A3.5 3.5 0 0 1 8.5 9H12v7H8.5A3.5 3.5 0 0 1 5 12.5z" />
                  </svg>
                  <span className="grow ms-6">
                    <span className="block text-lg font-semibold hs-tab-active:text-[#00acee] text-[#1f2937] dark:hs-tab-active:text-blue-500 dark:text-gray-200">
                      {t("webDevelopment.cardtext2")}
                    </span>
                    <span className="block mt-1 text-[#1f2937] dark:hs-tab-active:text-gray-200 dark:text-gray-200">
                      {t("webDevelopment.cardtext3")}
                    </span>
                  </span>
                </span>
              </button>

              <button
                type="button"
                className="hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                id="tabs-with-card-item-2"
                data-hs-tab="#tabs-with-card-2"
                aria-controls="tabs-with-card-2"
                role="tab"
              >
                <span className="flex">
                  <svg
                    className="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-[#00acee] text-[#1f2937] dark:hs-tab-active:text-blue-500 dark:text-gray-200"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m12 14 4-4" />
                    <path d="M3.34 19a10 10 0 1 1 17.32 0" />
                  </svg>
                  <span className="grow ms-6">
                    <span className="block text-lg font-semibold hs-tab-active:text-[#00acee] text-[#1f2937] dark:hs-tab-active:text-blue-500 dark:text-gray-200">
                      {t("webDevelopment.cardtext4")}
                    </span>
                    <span className="block mt-1 text-[#1f2937] dark:hs-tab-active:text-gray-200 dark:text-gray-200">
                      {t("webDevelopment.cardtext5")}
                    </span>
                  </span>
                </span>
              </button>

              <button
                type="button"
                className="hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                id="tabs-with-card-item-3"
                data-hs-tab="#tabs-with-card-3"
                aria-controls="tabs-with-card-3"
                role="tab"
              >
                <span className="flex">
                  <svg
                    className="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-[#00acee] text-[#1f2937] dark:hs-tab-active:text-blue-500 dark:text-gray-200"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
                    <path d="M5 3v4" />
                    <path d="M19 17v4" />
                    <path d="M3 5h4" />
                    <path d="M17 19h4" />
                  </svg>
                  <span className="grow ms-6">
                    <span className="block text-lg font-semibold hs-tab-active:text-[#00acee] text-[#1f2937] dark:hs-tab-active:text-blue-500 dark:text-gray-200">
                      {t("webDevelopment.cardtext6")}
                    </span>
                    <span className="block mt-1 text-[#1f2937] dark:hs-tab-active:text-gray-200 dark:text-gray-200">
                      {t("webDevelopment.cardtext7")}
                    </span>
                  </span>
                </span>
              </button>
            </nav>
          </div>

          <div className="lg:col-span-6">
            <div className="relative">
              <div>
                <div
                  id="tabs-with-card-1"
                  role="tabpanel"
                  aria-labelledby="tabs-with-card-item-1"
                >
                  <LazyLoadImage
                    effect="blur"
                    className="shadow-xl shadow-gray-200 rounded-xl dark:shadow-gray-900/[.2]"
                    src="https://images.unsplash.com/photo-1605629921711-2f6b00c6bbf4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&h=1220&q=80"
                    alt="Image Description"
                  />
                </div>

                <div
                  id="tabs-with-card-2"
                  className="hidden"
                  role="tabpanel"
                  aria-labelledby="tabs-with-card-item-2"
                >
                  <LazyLoadImage
                    effect="blur"
                    className="shadow-xl shadow-gray-200 rounded-xl dark:shadow-gray-900/[.2]"
                    src="https://images.unsplash.com/photo-1665686306574-1ace09918530?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&h=1220&q=80"
                    alt="Image Description"
                  />
                </div>

                <div
                  id="tabs-with-card-3"
                  className="hidden"
                  role="tabpanel"
                  aria-labelledby="tabs-with-card-item-3"
                >
                  <LazyLoadImage
                    effect="blur"
                    className="shadow-xl shadow-gray-200 rounded-xl dark:shadow-gray-900/[.2]"
                    src="https://images.unsplash.com/photo-1598929213452-52d72f63e307?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&h=1220&q=80"
                    alt="Image Description"
                  />
                </div>
              </div>

              <div className="hidden absolute top-0 end-0 translate-x-20 md:block lg:translate-x-20">
                <svg
                  className="w-16 h-auto text-[#00acee]"
                  width="121"
                  height="135"
                  viewBox="0 0 121 135"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164"
                    stroke="currentColor"
                    strokeWidth="10"
                    strokeLinecap="round"
                  />
                  <path
                    d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5"
                    stroke="currentColor"
                    strokeWidth="10"
                    strokeLinecap="round"
                  />
                  <path
                    d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874"
                    stroke="currentColor"
                    strokeWidth="10"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute inset-0 grid grid-cols-12 w-full h-full">
          <div className="col-span-full lg:col-span-7 lg:col-start-6 bg-gray-100 w-full h-5/6 rounded-xl sm:h-3/4 lg:h-full dark:bg-white/[.075]"></div>
        </div>
      </div>

      <div className="max-w-4xl mx-auto mt-20">
        <div className="grid md:grid-cols-2 gap-6 lg:gap-12">
          <div className="space-y-6 lg:space-y-10">
            <div className="flex">
              <svg
                className="flex-shrink-0 mt-2 h-8 w-8 text-[#1f2937] dark:text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect width="18" height="10" x="3" y="11" rx="2" />
                <circle cx="12" cy="5" r="2" />
                <path d="M12 7v4" />
                <line x1="8" x2="8" y1="16" y2="16" />
                <line x1="16" x2="16" y1="16" y2="16" />
              </svg>
              <div className="ms-5 sm:ms-8">
                <h3 className="text-base sm:text-lg font-semibold text-[#1f2937] dark:text-gray-200">
                  {t("webDevelopment.iconstext1")}
                </h3>
                <p className="mt-1 text-gray-600 dark:text-gray-400">
                  {t("webDevelopment.iconstext2")}
                </p>
              </div>
            </div>

            <div className="flex">
              <svg
                className="flex-shrink-0 mt-2 h-8 w-8 text-[#1f2937] dark:text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m7.5 4.27 9 5.15" />
                <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
                <path d="m3.3 7 8.7 5 8.7-5" />
                <path d="M12 22V12" />
              </svg>
              <div className="ms-5 sm:ms-8">
                <h3 className="text-base sm:text-lg font-semibold text-[#1f2937] dark:text-gray-200">
                  {t("webDevelopment.iconstext3")}
                </h3>
                <p className="mt-1 text-gray-600 dark:text-gray-400">
                  {t("webDevelopment.iconstext4")}
                </p>
              </div>
            </div>

            <div className="flex">
              <svg
                className="flex-shrink-0 mt-2 h-8 w-8 text-[#1f2937] dark:text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" />
              </svg>
              <div className="ms-5 sm:ms-8">
                <h3 className="text-base sm:text-lg font-semibold text-[#1f2937] dark:text-gray-200">
                  {t("webDevelopment.iconstext5")}
                </h3>
                <p className="mt-1 text-gray-600 dark:text-gray-400">
                  {t("webDevelopment.iconstext6")}
                </p>
              </div>
            </div>
          </div>

          <div className="space-y-6 lg:space-y-10">
            <div className="flex">
              <svg
                className="flex-shrink-0 mt-2 h-8 w-8 text-[#1f2937] dark:text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M6 9H4.5a2.5 2.5 0 0 1 0-5H6" />
                <path d="M18 9h1.5a2.5 2.5 0 0 0 0-5H18" />
                <path d="M4 22h16" />
                <path d="M10 14.66V17c0 .55-.47.98-.97 1.21C7.85 18.75 7 20.24 7 22" />
                <path d="M14 14.66V17c0 .55.47.98.97 1.21C16.15 18.75 17 20.24 17 22" />
                <path d="M18 2H6v7a6 6 0 0 0 12 0V2Z" />
              </svg>
              <div className="ms-5 sm:ms-8">
                <h3 className="text-base sm:text-lg font-semibold text-[#1f2937] dark:text-gray-200">
                  {t("webDevelopment.iconstext7")}
                </h3>
                <p className="mt-1 text-gray-600 dark:text-gray-400">
                  {t("webDevelopment.iconstext8")}
                </p>
              </div>
            </div>

            <div className="flex">
              <svg
                className="flex-shrink-0 mt-2 h-8 w-8 text-[#1f2937] dark:text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
                <path d="m9 12 2 2 4-4" />
              </svg>
              <div className="ms-5 sm:ms-8">
                <h3 className="text-base sm:text-lg font-semibold text-[#1f2937] dark:text-gray-200">
                  {t("webDevelopment.iconstext9")}
                </h3>
                <p className="mt-1 text-gray-600 dark:text-gray-400">
                  {t("webDevelopment.iconstext10")}
                </p>
              </div>
            </div>

            <div className="flex">
              <svg
                className="flex-shrink-0 mt-2 h-8 w-8 text-[#1f2937] dark:text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                <circle cx="9" cy="7" r="4" />
                <polyline points="16 11 18 13 22 9" />
              </svg>
              <div className="ms-5 sm:ms-8">
                <h3 className="text-base sm:text-lg font-semibold text-[#1f2937] dark:text-gray-200">
                  {t("webDevelopment.iconstext11")}
                </h3>
                <p className="mt-1 text-gray-600 dark:text-gray-400">
                  {t("webDevelopment.iconstext12")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-20 flex flex-col items-center px-4 py-12 mx-auto text-center">
        <h2 className="text-2xl font-bold tracking-tight text-[#1f2937] xl:text-3xl dark:text-white">
          {t("webDevelopment.ctatitle")}
        </h2>

        <p className="block max-w-4xl mt-4 text-gray-600 dark:text-gray-300">
          {t("webDevelopment.ctatext")}
        </p>

        <div className="mt-6">
          <a
            href="/contact"
            className="inline-flex items-center justify-center w-full px-4 py-2.5 mt-4 overflow-hidden text text-white transition-colors duration-300 bg-[#00acee] rounded-lg shadow sm:w-auto sm:mx-2 sm:mt-0 hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
          >
            <svg
              className="w-5 h-5 mx-2 fill-current"
              viewBox="-28 0 512 512.00075"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m432.320312 215.121094-361.515624-208.722656c-14.777344-8.53125-32.421876-8.53125-47.203126 0-.121093.070312-.230468.148437-.351562.21875-.210938.125-.421875.253906-.628906.390624-14.175782 8.636719-22.621094 23.59375-22.621094 40.269532v417.445312c0 17.066406 8.824219 32.347656 23.601562 40.878906 7.390626 4.265626 15.496094 6.398438 23.601563 6.398438s16.214844-2.132812 23.601563-6.398438l361.519531-208.722656c14.777343-8.53125 23.601562-23.8125 23.601562-40.878906s-8.824219-32.347656-23.605469-40.878906zm-401.941406 253.152344c-.21875-1.097657-.351562-2.273438-.351562-3.550782v-417.445312c0-2.246094.378906-4.203125.984375-5.90625l204.324219 213.121094zm43.824219-425.242188 234.21875 135.226562-52.285156 54.539063zm-6.480469 429.679688 188.410156-196.527344 54.152344 56.484375zm349.585938-201.835938-80.25 46.332031-60.125-62.714843 58.261718-60.773438 82.113282 47.40625c7.75 4.476562 8.589844 11.894531 8.589844 14.875s-.839844 10.398438-8.589844 14.875zm0 0"></path>
            </svg>

            <span className="mx-2">{t("webDevelopment.ctabtn")}</span>
          </a>
        </div>
      </div>
    </div>
  );
}
