import React from 'react';
import { Routes, Route } from 'react-router-dom';
import WebDevelopment from './web-development';
import AppDevelopment from './app-development';
import Ecommerce from './ecommerce';
import PersonalizedSoftware from './personalized-software';
import BusinessSoftware from './business-software';
import ApiDevelopment from './api-development';
import AnotherServices from './another-services';
import AiDevelopment from './ai-development';
import ServiceLayout from './ServiceLayout.tsx';
function ServicesMain() {
  return <Routes>
      <Route path="/web-development" element={<ServiceLayout><WebDevelopment /></ServiceLayout>} />
      <Route path="/app-development" element={<ServiceLayout><AppDevelopment /></ServiceLayout>} />
      <Route path="/ecommerce" element={<ServiceLayout><Ecommerce /></ServiceLayout>} />
      <Route path="/personalized-software" element={<ServiceLayout><PersonalizedSoftware /></ServiceLayout>} />
      <Route path="/business-software" element={<ServiceLayout><BusinessSoftware /></ServiceLayout>} />
      <Route path="/api-development" element={<ServiceLayout><ApiDevelopment /></ServiceLayout>} />
      <Route path="/another-services" element={<ServiceLayout><AnotherServices /></ServiceLayout>} />
      <Route path="/ai-development" element={<ServiceLayout><AiDevelopment /></ServiceLayout>} />
      {/* Agrega rutas para otros servicios */}
    </Routes>;
}
export default ServicesMain;