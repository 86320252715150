import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";

import "../../App.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Ecommerce() {
    const { t } = useTranslation();
    const textContainerRef = useRef(null);

    const text = t('ecommerce.title');

    const animateText = () => {
        const textArray = text.split('');
        const shuffledIndices = shuffleArray(textArray.map((_, index) => index));

        shuffledIndices.forEach((index, animationIndex) => {
            gsap.fromTo(`.letter-${index}`, {
                opacity: 0,
                y: 50,
            }, {
                opacity: 1,
                y: 0,
                duration: 1.5,
                delay: animationIndex * 0.1,

            });
        });
    }

    function shuffleArray(array) {
        const shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    }

    useEffect(() => {
        animateText();
    }
        , []);

    return (
        <div className="max-w-[95rem] -mt-10 mx-auto dark:bg-gray-900">
            <div className="pt-20 pb-10">

                <div className="mt-5 max-w-xl text-center mx-auto">
                    <h1 ref={textContainerRef} className="block font-nasalization font-bold text-[#1f2937] text-4xl md:text-5xl lg:text-6xl dark:text-gray-200">
                        {text.split('').map((letter, index) => (
                            <span key={index} className={`letter-${index}`}>{letter}</span>
                        ))}
                    </h1>
                </div>

                <div className="mt-5 max-w-3xl text-center mx-auto">
                    <p className="text-lg text-gray-600 dark:text-gray-400"> {t('ecommerce.description')} </p>
                </div>

            </div>
            <div className="max-w-7xl px-4 py-10 sm:px-6 lg:px-9 lg:py-14 mx-auto -mt-10">
                <div className="grid sm:grid-cols-12 gap-6">
                    <div className="sm:self-end col-span-12 sm:col-span-7 md:col-span-8 lg:col-span-5 lg:col-start-3">
                        <a className="group relative animate-float-first block rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                            <div className="aspect-w-12 aspect-h-7 sm:aspect-none rounded-xl overflow-hidden">
                                <LazyLoadImage effect="blur" className="group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl w-full object-cover" src="https://images.unsplash.com/photo-1513694203232-719a280e022f?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Image Description" />
                            </div>
                            <div className="absolute bottom-0 start-0 end-0 p-2 sm:p-4">
                                <div className="text-sm font-bold text-[#1f2937] rounded-lg bg-white p-4 md:text-xl dark:bg-gray-900 dark:text-gray-200">
                                    {t('ecommerce.img1')}
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="sm:self-end col-span-12 sm:col-span-5 md:col-span-4 lg:col-span-3">
                        <a className="group relative animate-float-second block rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" >
                            <div className="aspect-w-12 aspect-h-7 sm:aspect-none rounded-xl overflow-hidden">
                                <LazyLoadImage effect="blur" className="group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl w-full object-cover" src="https://images.unsplash.com/photo-1625698311031-f0dd15be5144?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Image Description" />
                            </div>
                            <div className="absolute bottom-0 start-0 end-0 p-2 sm:p-4">
                                <div className="text-sm font-bold text-[#1f2937] rounded-lg bg-white p-4 md:text-xl dark:bg-gray-900 dark:text-gray-200">
                                    {t('ecommerce.img2')}
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-span-12 md:col-span-4">
                        <a className="group relative animate-float-second block rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" >
                            <div className="aspect-w-12 aspect-h-7 sm:aspect-none rounded-xl overflow-hidden">
                                <LazyLoadImage effect="blur" className="group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl w-full object-cover" src="https://images.unsplash.com/photo-1540555700478-4be289fbecef?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Image Description" />
                            </div>
                            <div className="absolute bottom-0 start-0 end-0 p-2 sm:p-4">
                                <div className="text-sm font-bold text-[#1f2937] rounded-lg bg-white p-4 md:text-xl dark:bg-gray-900 dark:text-gray-200">
                                    {t('ecommerce.img3')}
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-span-12 sm:col-span-6 md:col-span-4">
                        <a className="group relative animate-float-first block rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                            <div className="aspect-w-12 aspect-h-7 sm:aspect-none rounded-xl overflow-hidden">
                                <LazyLoadImage effect="blur" className="group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl w-full object-cover" src="https://images.unsplash.com/photo-1531995811006-35cb42e1a022?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Image Description" />
                            </div>
                            <div className="absolute bottom-0 start-0 end-0 p-2 sm:p-4">
                                <div className="text-sm font-bold text-[#1f2937] rounded-lg bg-white p-4 md:text-xl dark:bg-gray-900 dark:text-gray-200">
                                    {t('ecommerce.img4')}
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="col-span-12 sm:col-span-6 md:col-span-4">
                        <a className="group relative animate-float block rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                            <div className="aspect-w-12 aspect-h-7 sm:aspect-none rounded-xl overflow-hidden">
                                <LazyLoadImage effect="blur" className="group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl w-full object-cover" src="https://images.unsplash.com/photo-1591238372338-22d30c883a86?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Image Description" />
                            </div>
                            <div className="absolute bottom-0 start-0 end-0 p-2 sm:p-4">
                                <div className="text-sm font-bold text-[#1f2937] rounded-lg bg-white p-4 md:text-xl dark:bg-gray-900 dark:text-gray-200">
                                    {t('ecommerce.img5')}
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>


            <div className="max-w-7xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                <div className="mb-10 md:mx-auto sm:text-center md:mb-12">

                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-[#1f2937] sm:text-4xl md:mx-auto dark:text-gray-200">
                        <span className="relative inline-block">

                            {t('ecommerce.title2')}
                        </span>{' '}

                    </h2>
                    <p className="text-base text-gray-700 md:text-lg max-w-3xl text-center mx-auto dark:text-gray-400">
                        {t('ecommerce.description2')}
                    </p>
                </div>
                <div className="grid max-w-screen-lg mx-auto space-y-6 lg:grid-cols-2 lg:space-y-0 lg:divide-x">
                    <div className="space-y-6 sm:px-16">
                        <div className="flex flex-col max-w-md sm:flex-row">
                            <div className="mb-4 mr-4">
                                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                                    <svg className="text-[#00acee]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m12.83 2.18a2 2 0 0 0-1.66 0L2.6 6.08a1 1 0 0 0 0 1.83l8.58 3.91a2 2 0 0 0 1.66 0l8.58-3.9a1 1 0 0 0 0-1.83Z" /><path d="m6.08 9.5-3.5 1.6a1 1 0 0 0 0 1.81l8.6 3.91a2 2 0 0 0 1.65 0l8.58-3.9a1 1 0 0 0 0-1.83l-3.5-1.59" /><path d="m6.08 14.5-3.5 1.6a1 1 0 0 0 0 1.81l8.6 3.91a2 2 0 0 0 1.65 0l8.58-3.9a1 1 0 0 0 0-1.83l-3.5-1.59" /></svg>
                                </div>
                            </div>
                            <div>
                                <h6 className="mb-3 text-xl font-bold leading-5 text-[#1f2937] dark:text-gray-200">
                                    {t('ecommerce.features1')}
                                </h6>
                                <p className="text-sm text-[#1f2937] dark:text-gray-400">
                                    {t('ecommerce.features1Description')}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col max-w-md sm:flex-row">
                            <div className="mb-4 mr-4">
                                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                                <svg className="text-[#00acee]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"/></svg>
                                </div>
                            </div>
                            <div>
                                <h6 className="mb-3 text-xl font-bold leading-5 text-[#1f2937] dark:text-gray-200">
                                    {t('ecommerce.features2')}
                                </h6>
                                <p className="text-sm text-[#1f2937] dark:text-gray-400">
                                    {t('ecommerce.features2Description')}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col max-w-md sm:flex-row">
                            <div className="mb-4 mr-4">
                                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                                <svg className="text-[#00acee]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8"/><path d="M12 18V6"/></svg>
                                </div>
                            </div>
                            <div>
                                <h6 className="mb-3 text-xl font-bold leading-5 text-[#1f2937] dark:text-gray-200">
                                    {t('ecommerce.features3')}
                                </h6>
                                <p className="text-sm text-[#1f2937] dark:text-gray-400">
                                    {t('ecommerce.features3Description')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="space-y-6 sm:px-16">
                        <div className="flex flex-col max-w-md sm:flex-row">
                            <div className="mb-4 mr-4">
                                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                                <svg className="text-[#00acee]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect width="8" height="4" x="8" y="2" rx="1" ry="1"/><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"/><path d="M12 11h4"/><path d="M12 16h4"/><path d="M8 11h.01"/><path d="M8 16h.01"/></svg>
                                </div>
                            </div>
                            <div>
                                <h6 className="mb-3 text-xl font-bold leading-5 text-[#1f2937] dark:text-gray-200">
                                    {t('ecommerce.features4')}
                                </h6>
                                <p className="text-sm text-[#1f2937] dark:text-gray-400">
                                    {t('ecommerce.features4Description')}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col max-w-md sm:flex-row">
                            <div className="mb-4 mr-4">
                                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                                <svg className="text-[#00acee]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M5 18H3c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v11"/><path d="M14 9h4l4 4v4c0 .6-.4 1-1 1h-2"/><circle cx="7" cy="18" r="2"/><path d="M15 18H9"/><circle cx="17" cy="18" r="2"/></svg>
                                </div>
                            </div>
                            <div>
                                <h6 className="mb-3 text-xl font-bold leading-5 text-[#1f2937] dark:text-gray-200">
                                    {t('ecommerce.features5')}
                                </h6>
                                <p className="text-sm text-[#1f2937] dark:text-gray-400">
                                    {t('ecommerce.features5Description')}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col max-w-md sm:flex-row">
                            <div className="mb-4 mr-4">
                                <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                                <svg className="text-[#00acee]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z"/><line x1="12" x2="12" y1="16" y2="12"/><line x1="12" x2="12.01" y1="8" y2="8"/></svg>
                                </div>
                            </div>
                            <div>
                                <h6 className="mb-3 text-xl font-bold leading-5 text-[#1f2937] dark:text-gray-200">
                                    {t('ecommerce.features6')}
                                </h6>
                                <p className="text-sm text-[#1f2937] dark:text-gray-400">
                                    {t('ecommerce.features6Description')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">

                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-[#1f2937] sm:text-4xl md:mx-auto dark:text-gray-200">
                        <span className="relative inline-block">
                            {t('ecommerce.title3')}

                        </span>

                    </h2>
                </div>
                <div className="relative grid gap-8 row-gap-5 mb-8 md:row-gap-8 lg:grid-cols-4 sm:grid-cols-2">
                    <div className="absolute inset-0 flex items-center justify-center sm:hidden lg:flex">
                        <div className="w-px h-full bg-gray-300 lg:w-full lg:h-px" />
                    </div>
                    <div className="p-5 duration-300 transform bg-white border rounded-xl shadow-sm hover:-translate-y-2 dark:bg-gray-900">
                        <div className="flex items-center justify-between mb-2">
                            <p className="text-lg font-bold leading-5 text-[#1f2937] dark:text-gray-200">{t('ecommerce.step1')}</p>
                            <p className="flex items-center justify-center w-6 h-6 font-bold rounded text-[#00acee] bg-indigo-50">
                                1
                            </p>
                        </div>
                        <p className="text-sm text-[#1f2937] dark:text-gray-400">
                            {t('ecommerce.step1Description')}
                        </p>
                    </div>
                    <div className="p-5 duration-300 transform bg-white border rounded-xl shadow-sm hover:-translate-y-2 dark:bg-gray-900">
                        <div className="flex items-center justify-between mb-2">
                            <p className="text-lg font-bold leading-5 text-[#1f2937] dark:text-gray-200">{t('ecommerce.step2')}</p>
                            <p className="flex items-center justify-center w-6 h-6 font-bold rounded text-[#00acee] bg-indigo-50">
                                2
                            </p>
                        </div>
                        <p className="text-sm text-[#1f2937] dark:text-gray-400">
                            {t('ecommerce.step2Description')}
                        </p>
                    </div>
                    <div className="p-5 duration-300 transform bg-white border rounded-xl shadow-sm hover:-translate-y-2 dark:bg-gray-900">
                        <div className="flex items-center justify-between mb-2">
                            <p className="text-lg font-bold leading-5 text-[#1f2937] dark:text-gray-200">{t('ecommerce.step3')}</p>
                            <p className="flex items-center justify-center w-6 h-6 font-bold rounded text-[#00acee] bg-indigo-50">
                                3
                            </p>
                        </div>
                        <p className="text-sm text-[#1f2937] dark:text-gray-400">
                            {t('ecommerce.step3Description')}
                        </p>
                    </div>
                    <div className="p-5 duration-300 transform bg-white border rounded-xl shadow-sm hover:-translate-y-2 dark:bg-gray-900">
                        <div className="flex items-center justify-between mb-2">
                            <p className="text-lg font-bold leading-5 text-[#1f2937] dark:text-gray-200">{t('ecommerce.step4')}</p>
                            <p className="flex items-center justify-center w-6 h-6 font-bold rounded text-[#00acee] bg-indigo-50">
                                4
                            </p>
                        </div>
                        <p className="text-sm text-[#1f2937] dark:text-gray-400">
                            {t('ecommerce.step4Description')}
                        </p>
                    </div>
                </div>

            </div>

            <div className="container mb-20 -mt-10 flex flex-col items-center px-4 py-12 mx-auto text-center">
                <h2 className="text-2xl font-bold tracking-tight text-[#1f2937] xl:text-3xl dark:text-white">
                    {t('webDevelopment.ctatitle')}
                </h2>

                <p className="block max-w-4xl mt-4 text-gray-600 dark:text-gray-300">
                    {t('webDevelopment.ctatext')}
                </p>

                <div className="mt-6">

                    <a href="/contact"
                        className="inline-flex items-center justify-center w-full px-4 py-2.5 mt-4 overflow-hidden text text-white transition-colors duration-300 bg-[#00acee] rounded-lg shadow sm:w-auto sm:mx-2 sm:mt-0 hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                        <svg className="w-5 h-5 mx-2 fill-current" viewBox="-28 0 512 512.00075" xmlns="http://www.w3.org/2000/svg">
                            <path d="m432.320312 215.121094-361.515624-208.722656c-14.777344-8.53125-32.421876-8.53125-47.203126 0-.121093.070312-.230468.148437-.351562.21875-.210938.125-.421875.253906-.628906.390624-14.175782 8.636719-22.621094 23.59375-22.621094 40.269532v417.445312c0 17.066406 8.824219 32.347656 23.601562 40.878906 7.390626 4.265626 15.496094 6.398438 23.601563 6.398438s16.214844-2.132812 23.601563-6.398438l361.519531-208.722656c14.777343-8.53125 23.601562-23.8125 23.601562-40.878906s-8.824219-32.347656-23.605469-40.878906zm-401.941406 253.152344c-.21875-1.097657-.351562-2.273438-.351562-3.550782v-417.445312c0-2.246094.378906-4.203125.984375-5.90625l204.324219 213.121094zm43.824219-425.242188 234.21875 135.226562-52.285156 54.539063zm-6.480469 429.679688 188.410156-196.527344 54.152344 56.484375zm349.585938-201.835938-80.25 46.332031-60.125-62.714843 58.261718-60.773438 82.113282 47.40625c7.75 4.476562 8.589844 11.894531 8.589844 14.875s-.839844 10.398438-8.589844 14.875zm0 0">
                            </path>
                        </svg>

                        <span className="mx-2">
                            {t('webDevelopment.ctabtn')}
                        </span>
                    </a>
                </div>
            </div>

        </div>
    );
}

