import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.tsx';
import * as Sentry from "@sentry/react";

// import preline from 'preline/preline';
// preline();
import 'preline/preline';
import reportWebVitals from './reportWebVitals';

// Configurar Sentry
Sentry.init({
  dsn: "https://b1839dd194c29274ffb8a55088f12308@o4506861338361856.ingest.us.sentry.io/4506861343014912",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: "production",
  // Performance Monitoring
  tracesSampleRate: 1.0, // Captura el 100% de las transacciones
  // Establecer 'tracePropagationTargets' para controlar en qué URLs se debe habilitar el rastreo distribuido
  tracePropagationTargets: ["localhost", /^https:\/\/wecodecr\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // Esto establece la tasa de muestreo en 10%. Puedes cambiarlo al 100% durante el desarrollo y luego reducirlo en producción.
  replaysOnErrorSampleRate: 1.0, // Si no estás muestreando ya toda la sesión, cambia la tasa de muestreo al 100% cuando muestres sesiones donde ocurran errores.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Si deseas comenzar a medir el rendimiento en tu aplicación, pasa una función
// para registrar resultados (por ejemplo: reportWebVitals(console.log))
// o enviar a un punto de análisis. Aprende más: https://bit.ly/CRA-vitals
reportWebVitals();
