import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import Google from '../../assets/images/stock/google.gif';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function AnotherServices() {
    const { t } = useTranslation();
    const textArray = (t('anotherServices.title') + ' ' + t('anotherServices.title1')).split('');
    const textContainerRef = useRef(null);

    const line1 = t('anotherServices.title');
    const line2 = t('anotherServices.title1');

    const animateText = () => {
        const textArray = (line1 + ' ' + line2).split('');
        const shuffledIndices = shuffleArray(textArray.map((_, index) => index));

        shuffledIndices.forEach((index, animationIndex) => {
            gsap.fromTo(`.letter-${index}`, {
                opacity: 0,
                y: 50,
            }, {
                opacity: 1,
                y: 0,
                duration: 0.1,
                delay: animationIndex * 0.1,
            });
        });
    };

    function shuffleArray(array) {
        const shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    }

    useEffect(() => {
        animateText();
    }, []);
  
    return (
        <div className="max-w-[95rem] mx-auto dark:bg-gray-900">
            <div className=" relative flex flex-col-reverse px-4 py-16 mx-auto lg:block lg:flex-col lg:py-32 xl:py-48 md:px-8 sm:max-w-xl md:max-w-full">
                <div className="z-0 flex justify-center h-full -mx-4 overflow-hidden lg:pt-24 lg:pb-16 lg:pr-8 xl:pr-0 lg:w-1/2 lg:absolute lg:justify-end lg:bottom-0 lg:left-0 lg:items-center">
                    <div className="mockup-window border bg-base-300 lg:ml-0">
                        <div className="flex justify-center bg-base-200">
                            <LazyLoadImage effect="blur" src={Google} alt="hero" className="object-cover object-center  w-[350px] h-[230px] md:w-full md:h-full " />
                        </div>
                    </div>
                </div>
                <div className="relative mr-5 flex justify-end max-w-xl mx-auto xl:pr-32 lg:max-w-screen-xl">
                    <div className="mb-16 lg:pr-5 lg:max-w-lg -mr-20 lg:mb-0">
                        <div className="max-w-xl mb-6 text-center lg:text-left md:ml-20 lg:ml-0">
                            <div>

                            </div>
                            <h2 ref={textContainerRef} className="max-w-lg mb-6 font-nasalization text-3xl text-[#1f2937] font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none dark:text-white">
                            {textArray.map((letter, index) => (
                                    <span key={index} className={`letter-${index} ${index >= line1.length ? 'text-[#00acee]' : ''}`}>{letter}</span>
                                ))}
                            </h2>
                            <p className="text-base text-gray-600 md:text-lg dark:text-gray-400">
                                {t('anotherServices.description')}
                            </p>
                        </div>

                    </div>
                </div>
            </div>



            <div id="consulting" className="container my-24 mx-auto md:px-6">
                <section className="mb-10">
                    <div className="flex flex-wrap">
                        <div className="mb-6 w-full shrink-0 grow-0 basis-auto px-3 lg:mb-0 lg:w-4/12">
                            <p className="mb-6 text-[#00acee] font-bold uppercase  dark:text-primary-400">
                            {t('anotherServices.subtitle1')}
                            </p>
                            <h2 className="mb-6 text-3xl font-bold text-[#1f2937] dark:text-white">
                            {t('anotherServices.title2')}
                            </h2>

                            <p className="mb-12 text-gray-600 dark:text-gray-400">
                            {t('anotherServices.description2')}
                            </p>
                        </div>

                        <div className="mb-md-0 mb-6 w-full shrink-0 grow-0 basis-auto px-3 lg:w-8/12">
                            <div className="flex flex-wrap">
                                <div className="mb-12 w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:px-3">
                                    <div className="flex">
                                        <div className="shrink-0">
                                            <div className="mb-6 inline-block rounded-md bg-primary-100 p-4 text-[#00acee]">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-book-open-check"><path d="M8 3H2v15h7c1.7 0 3 1.3 3 3V7c0-2.2-1.8-4-4-4Z" /><path d="m16 12 2 2 4-4" /><path d="M22 6V3h-6c-2.2 0-4 1.8-4 4v14c0-1.7 1.3-3 3-3h7v-2.3" /></svg>
                                            </div>
                                        </div>
                                        <div className="ml-4 grow">
                                            <p className="mb-3 font-bold text-[#1f2937] dark:text-gray-200">{t('anotherServices.features1')}</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                            {t('anotherServices.features1Description')}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-12 w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:px-3">
                                    <div className="flex">
                                        <div className="shrink-0">
                                            <div className="mb-6 inline-block rounded-md bg-primary-100 p-4 text-[#00acee]">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-line-chart"><path d="M3 3v18h18" /><path d="m19 9-5 5-4-4-3 3" /></svg>
                                            </div>
                                        </div>
                                        <div className="ml-4 grow">
                                            <p className="mb-3 font-bold text-[#1f2937] dark:text-gray-200">{t('anotherServices.features2')}</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                            {t('anotherServices.features2Description')}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-12 w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:px-3">
                                    <div className="flex">
                                        <div className="shrink-0">
                                            <div className="mb-6 inline-block rounded-md bg-primary-100 p-4 text-[#00acee]">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-calendar-clock"><path d="M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5" /><path d="M16 2v4" /><path d="M8 2v4" /><path d="M3 10h5" /><path d="M17.5 17.5 16 16.25V14" /><path d="M22 16a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z" /></svg>
                                            </div>
                                        </div>
                                        <div className="ml-4 grow">
                                            <p className="mb-3 font-bold text-[#1f2937] dark:text-gray-200">{t('anotherServices.features3')}</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                            {t('anotherServices.features3Description')}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-12 w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:px-3">
                                    <div className="flex">
                                        <div className="shrink-0">
                                            <div className="mb-6 inline-block rounded-md bg-primary-100 p-4 text-[#00acee]">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-badge-check"><path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" /><path d="m9 12 2 2 4-4" /></svg>
                                            </div>
                                        </div>
                                        <div className="ml-4 grow">
                                            <p className="mb-3 font-bold text-[#1f2937] dark:text-gray-200">{t('anotherServices.features4')}</p>
                                            <p className="text-gray-500 dark:text-gray-400">
                                            {t('anotherServices.features4Description')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>



            <div id="seo" className="container my-24 mx-auto md:px-6">
                <section className="mb-32 text-center">
                    <h2 className="mb-20 text-3xl font-bold text-[#1f2937] dark:text-white">{t('anotherServices.title3')}</h2>

                    <div className="grid lg:grid-cols-3 lg:gap-x-12 px-3 lg:px-0">
                        <div className="mb-12 lg:mb-0">
                            <div
                                className="block h-full rounded-xl bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-slate-900">
                                <div className="flex justify-center">
                                    <div className="-mt-8 inline-block rounded-full bg-primary-100 p-4 text-[#00acee] shadow-md dark:bg-slate-700">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                            stroke="currentColor" className="h-7 w-7">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="p-6">
                                    <h5 className="mb-4 text-lg font-semibold text-[#1f2937] dark:text-white">{t('anotherServices.card1title')}</h5>
                                    <p className="text-gray-500 dark:text-gray-300">
                                    {t('anotherServices.card1text')}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="mb-12 lg:mb-0">
                            <div
                                className="block h-full rounded-xl bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-slate-900">
                                <div className="flex justify-center">
                                    <div className="-mt-8 inline-block rounded-full bg-primary-100 p-4 text-[#00acee] shadow-md dark:bg-slate-700">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                            stroke="currentColor" className="h-7 w-7">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="p-6">
                                    <h5 className="mb-4 text-lg font-semibold text-[#1f2937] dark:text-white">{t('anotherServices.card2title')}</h5>
                                    <p className="text-gray-500 dark:text-gray-300">
                                    {t('anotherServices.card2text')}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div
                                className="block h-full rounded-xl bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-slate-900">
                                <div className="flex justify-center">
                                    <div className="-mt-8 inline-block rounded-full bg-primary-100 p-4 text-[#00acee] shadow-md dark:bg-slate-700">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                            stroke="currentColor" className="h-7 w-7">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="p-6">
                                    <h5 className="mb-4 text-lg font-semibold text-[#1f2937] dark:text-white">{t('anotherServices.card3title')}</h5>
                                    <p className="text-gray-500 dark:text-gray-300">
                                    {t('anotherServices.card3text')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>



            <div id='support' className="container my-24 mx-auto md:px-6">
                <section className="mb-32">
                    <h2 className="mb-12 text-center text-3xl font-bold dark:text-white">
                    {t('anotherServices.title4')}
                    </h2>

                    <div className="flex flex-wrap items-center px-3 lg:px-0">
                        <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:px-6 lg:mb-0 lg:w-5/12">
                            <div className="relative overflow-hidden rounded-xl bg-cover bg-[50%] bg-no-repeat shadow-xl dark:shadow-black/20"
                                data-te-ripple-init data-te-ripple-color="light">
                                <LazyLoadImage effect="blur" src="https://images.unsplash.com/photo-1614741118887-7a4ee193a5fa?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" className="w-full" />
                                <a>
                                    <div
                                        className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,0%,0.4)] bg-fixed">
                                        <div className="flex h-full items-center justify-center">
                                            <div className="px-6 py-12 text-center text-white md:px-12">
                                                <h3 className="mb-6 text-2xl font-bold uppercase">
                                                {t('anotherServices.subtitle4')}
                                                    <u className="text-gray-200"> {t('anotherServices.subtitle4b')}</u>
                                                </h3>
                                                <p className="text-gray-200">
                                                {t('anotherServices.description4')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div
                                            className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="w-full shrink-0 grow-0 basis-auto md:px-6 lg:w-7/12">
                            <div className="mb-12 flex">
                                <div className="shrink-0">
                                    <div className="rounded-xl bg-indigo-50 p-4 dark:bg-gray-900">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                            stroke="currentColor" className="h-6 w-6 text-[#00acee]">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4 grow">
                                    <p className="mb-1 font-bold dark:text-white">{t('anotherServices.features5')}</p>
                                    <p className="text-neutral-500 dark:text-neutral-300">
                                    {t('anotherServices.features5Description')}
                                    </p>
                                </div>
                            </div>

                            <div className="mb-12 flex">
                                <div className="shrink-0">
                                    <div className="rounded-xl bg-indigo-50 p-4 dark:bg-gray-900">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                            stroke="currentColor" className="h-6 w-6 text-[#00acee]">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4 grow">
                                    <p className="mb-1 font-bold dark:text-white">{t('anotherServices.features6')}</p>
                                    <p className="text-neutral-500 dark:text-neutral-300">
                                    {t('anotherServices.features6Description')}
                                    </p>
                                </div>
                            </div>

                            <div className="mb-12 flex">
                                <div className="shrink-0">
                                    <div className="rounded-xl bg-indigo-50 p-4 dark:bg-gray-900">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                            stroke="currentColor" className="h-6 w-6 text-[#00acee]">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4 grow">
                                    <p className="mb-1 font-bold dark:text-white">{t('anotherServices.features7')}</p>
                                    <p className="text-neutral-500 dark:text-neutral-300">
                                    {t('anotherServices.features7Description')}
                                    </p>
                                </div>
                            </div>

                            <div className="flex">
                                <div className="shrink-0">
                                    <div className="rounded-xl bg-indigo-50 p-4 dark:bg-gray-900">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                            stroke="currentColor" className="h-6 w-6 text-[#00acee]">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4 grow">
                                    <p className="mb-1 font-bold dark:text-white">{t('anotherServices.features8')}</p>
                                    <p className="text-neutral-500 dark:text-neutral-300">
                                    {t('anotherServices.features8Description')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <div className="container mt-0 mb-10 flex flex-col items-center px-4 py-12 mx-auto text-center">
                <h2 className="text-2xl font-bold tracking-tight text-[#1f2937] xl:text-3xl dark:text-white">
                    {t('webDevelopment.ctatitle')}
                </h2>

                <p className="block max-w-4xl mt-4 text-gray-600 dark:text-gray-300">
                    {t('webDevelopment.ctatext')}
                </p>

                <div className="mt-6">

                    <a href="/contact"
                        className="inline-flex items-center justify-center w-full px-4 py-2.5 mt-4 overflow-hidden text text-white transition-colors duration-300 bg-[#00acee] rounded-lg shadow sm:w-auto sm:mx-2 sm:mt-0 hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                        <svg className="w-5 h-5 mx-2 fill-current" viewBox="-28 0 512 512.00075" xmlns="http://www.w3.org/2000/svg">
                            <path d="m432.320312 215.121094-361.515624-208.722656c-14.777344-8.53125-32.421876-8.53125-47.203126 0-.121093.070312-.230468.148437-.351562.21875-.210938.125-.421875.253906-.628906.390624-14.175782 8.636719-22.621094 23.59375-22.621094 40.269532v417.445312c0 17.066406 8.824219 32.347656 23.601562 40.878906 7.390626 4.265626 15.496094 6.398438 23.601563 6.398438s16.214844-2.132812 23.601563-6.398438l361.519531-208.722656c14.777343-8.53125 23.601562-23.8125 23.601562-40.878906s-8.824219-32.347656-23.605469-40.878906zm-401.941406 253.152344c-.21875-1.097657-.351562-2.273438-.351562-3.550782v-417.445312c0-2.246094.378906-4.203125.984375-5.90625l204.324219 213.121094zm43.824219-425.242188 234.21875 135.226562-52.285156 54.539063zm-6.480469 429.679688 188.410156-196.527344 54.152344 56.484375zm349.585938-201.835938-80.25 46.332031-60.125-62.714843 58.261718-60.773438 82.113282 47.40625c7.75 4.476562 8.589844 11.894531 8.589844 14.875s-.839844 10.398438-8.589844 14.875zm0 0">
                            </path>
                        </svg>

                        <span className="mx-2">
                            {t('webDevelopment.ctabtn')}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
};

