import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import Animation from "../../../assets/hosting/subhero.json";

export default function Features() {
  return (
    <div className="max-w-screen-xl mx-auto py-8 px-4 lg:py-16 lg:px-6 dark:bg-gray-900">
      <div className="text-center mb-10">
        <h2 className="text-4xl tracking-tight font-bold text-primary-800">
          ¿Por qué elegirnos?{" "}
        </h2>
      </div>

      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 lg:w-1/2 mt-8 md:mt-0 ml-0 md:-ml-24">
          <Player
            autoplay
            loop
            speed={1}
            src={Animation}
            style={{ height: "auto", width: "100%" }}
          />
        </div>

        <div className="flex-1 flex flex-col sm:flex-row flex-wrap -mb-4 -mx-2">
          <div className="w-full sm:w-1/2 mb-4 px-2 ">
            <div className="h-full py-4 px-6 border border-yellow-700 border-t-0 border-l-0 rounded-br-xl">
              <h3 className="text-2xl font-bold text-md mb-6">
                Servidores de alto rendimiento{" "}
              </h3>
              <p className="text-sm">
                Nuestros servidores VDS están equipados con los últimos
                procesadores y tecnología de vanguardia para garantizar un
                rendimiento óptimo y una experiencia de usuario excepcional.
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 mb-4 px-2 ">
            <div className="h-full py-4 px-6 border border-yellow-700 border-t-0 border-l-0 rounded-br-xl">
              <h3 className="text-2xl font-bold text-md mb-6">Escalabilidad</h3>
              <p className="text-sm">
                Nuestros servidores VDS son altamente escalables, lo que te
                permite aumentar o disminuir los recursos según tus necesidades
                sin interrumpir el servicio.
              </p>
            </div>
          </div>

          <div className="w-full sm:w-1/2 mb-4 px-2 ">
            <div className="h-full py-4 px-6 border border-yellow-700 border-t-0 border-l-0 rounded-br-xl">
              <h3 className="text-2xl font-bold text-md mb-6">
                Recursos dedicados
              </h3>
              <p className="text-sm">
                Con nuestros servidores VDS, obtienes recursos dedicados como
                CPU, RAM y almacenamiento para garantizar un rendimiento
                consistente y confiable en todo momento.
              </p>
            </div>
          </div>

          <div className="w-full sm:w-1/2 mb-4 px-2 ">
            <div className="h-full py-4 px-6 border border-yellow-700 border-t-0 border-l-0 rounded-br-xl">
              <h3 className="text-2xl font-bold text-md mb-6">
                Seguridad de primer nivel
              </h3>
              <p className="text-sm">
                Nuestros servidores VDS están protegidos con medidas de
                seguridad avanzadas, como firewalls, monitoreo de red y
                detección de intrusiones para garantizar la seguridad de tus
                datos.
              </p>
            </div>
          </div>

          <div className="w-full sm:w-1/2 mb-4 px-2 ">
            <div className="h-full py-4 px-6 border border-yellow-700 border-t-0 border-l-0 rounded-br-xl">
              <h3 className="text-2xl font-bold text-md mb-6">
                Hyper-Threading AMD
              </h3>
              <p className="text-sm">
                Utilizamos la tecnología Hyper-Threading de AMD, cada núcleo de
                procesador puede ejecutar dos hilos de instrucciones
                simultáneamente, lo que mejora el rendimiento y la capacidad de
                respuesta de las aplicaciones.
              </p>
            </div>
          </div>

          <div className="w-full sm:w-1/2 mb-4 px-2 ">
            <div className="h-full py-4 px-6 border border-yellow-700 border-t-0 border-l-0 rounded-br-xl">
              <h3 className="text-2xl font-bold text-md mb-6">Soporte 24/7</h3>
              <p className="text-sm">
                Nuestro equipo de soporte técnico está disponible las 24 horas
                del día, los 7 días de la semana para ayudarte con cualquier
                problema o pregunta que puedas tener.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
