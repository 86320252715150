const es = {
  translation: {
    // Navbar
    Navbar: {
      home: "Inicio",
      services: "Servicios",
      development: "Desarrollo",
      web: "Desarrollo Web",
      apps: "Desarrollo de Apps",
      store: "Tiendas Online | E-Commerce",
      software: "Software Personalizado",
      softwareBusiness: "Software Empresarial",
      apis: "Desarrollo de APIs",
      anothers: "Otros Servicios",
      consulting: "Consultoría",
      seo: "SEO",
      support: "Mantenimiento y Soporte Técnico",
      ia: "Inteligencia Artificial",
      chatbots: "Desarrollo de Chatbots",
      gpts: "GPTs Personalizados",
      news: "Con los GPTs de OpenAI, podemos desarrollar Chatbots y Asistentes Personalizados para satisfacer las necesidades específicas de su empresa o negocio. Estos sistemas están diseñados para ofrecer respuestas rápidas y precisas tanto a usted como a sus clientes, brindando un servicio ágil y efectivo que impulsa la satisfacción de los clientes y el rendimiento empresarial.",
      hosting: "Hosting",
      clients: "Clientes",
      about: "Nosotros",
      contact: "Contacto",
    },

    NotFoundTitle: {
      text: "Página no encontrada",
    },
    NotFoundDescription: {
      text: "Buscamos alto y bajo, pero no pudimos encontrar lo que estás buscando. Encontremos un mejor lugar para que vayas.",
    },
    NotFoundButton1: {
      text: "Volver",
    },
    NotFoundButton2: {
      text: "Contáctenos",
    },
    Projects: {
      text: "Proyectos",
    },
    ViewProjectsDescription: {
      text: "Tenemos muchos proyectos que puedes ver y aprender de ellos.",
    },
    View: {
      text: "Ver",
    },
    OurBlog: {
      text: "Nuestro Blog",
    },
    ViewOurBlog: {
      text: "En nuestro blog, puede encontrar mucha información sobre las últimas noticias de tecnología.",
    },
    ReadNewsBlog: {
      text: "Leer blog",
    },
    ChatToUs: {
      text: "Chatea con nosotros",
    },
    ChatDescription: {
      text: "Estamos aquí para ayudarte. Chatee con nosotros si tiene alguna pregunta.",
    },
    ChatButton: {
      text: "Chatea con nuestro equipo",
    },
    HeroTitle: {
      text: "¡A las Estrellas Juntos!",
    },
    Explore: {
      text: "Explorar",
    },
    PressBtn: {
      text: "Presiona el botón",
    },
    ParallaxTitle: {
      text: "Llevamos tu negocio a las nubes",
    },
    ParallaxTitle2: {
      text: "Desarrollamos tu idea y la llevamos a la realidad",
    },
    ParallaxTitle3: {
      text: "Web, Apps, Software, Tiendas, IA y más",
    },
    FeaturesTitle: {
      text: "Te ayudamos a <br/> llevar a tu negocio <br/> a las nubes.",
    },
    Feature1: {
      title: "Sitios Webs",
      description:
        "Creamos sitios web a la medida de tus necesidades, con las últimas tecnologías y con un diseño moderno y atractivo.",
    },
    Feature2: {
      title: "Apps Móviles",
      description:
        "Desarrollamos aplicaciones móviles nativas para Android y iOS con las últimas tecnologías y con un diseño moderno y atractivo.",
    },
    Feature3: {
      title: "Software a la medida",
      description:
        "Con un software a la medida de tus necesidades podrás optimizar tus procesos y aumentar la productividad de tu negocio.",
    },
    Feature4: {
      title: "Tiendas en línea (E-commerce)",
      description:
        "Digitaliza tu negocio y vende tus productos en línea con una tienda virtual a la medida de tus necesidades.",
    },
    Feature5: {
      title: "Alojamiento Web (Hosting)",
      description:
        "Te ofrecemos un alojamiento web seguro y confiable para tu sitio web, con un soporte técnico de calidad.",
    },
    Feature6: {
      title: "Inteligencia Artificial (IA)",
      description:
        "Desarrollamos soluciones de Inteligencia Artificial (IA) para automatizar procesos y aumentar la productividad de tu negocio.",
    },

    CTATitle: {
      text: "Nuestros Clientes Nos Recomiendan",
    },
    CTASubtitle: {
      text: "Cada cliente es unico y especial para nosotros, por eso nos esforzamos en dar lo mejor de nosotros para que su experiencia sea la mejor.",
    },
    CTAClientTestimonial: {
      text: "En varios ocasiones he tenido la fortuna de contratar los servicios de WeCode y en cada una de esas ocasiones han sobresalido. El team de Developers es muy capaz y tiene un excelente customer service. Lo recomendaria al 100%",
    },
    CTAStat1: {
      text: "En ventas en solo <strong>48 horas</strong> con nuestro sistema de Ecommerce y pasarela de pagos <strong>con un solo cliente.</strong>",
    },
    CTAStat2: {
      text: "De nuestros clientes han tenido un aumento en sus ventas en linea y consolida su marca.",
    },
    CTAStat3: {
      text: "De nuestros clientes han quedado satisfechos con nuestros servicios y nos recomiendan.",
    },
    CTAStat4: {
      text: "Es el aumento de trafico en las paginas web de nuestros clientes.",
    },

    NexusDescription: {
      text: "Un espacio donde puedes encontrar información sobre las últimas noticias de Tecnología, IA, Gaming y más.",
    },
    NexusDescription2: {
      text: "Nuestras últimas noticias",
    },
    ReadMore: {
      text: "Leer más",
    },

    ProjectsTitle: {
      text: "Nuestro Trabajo",
    },
    ProjectsText: {
      text: "Aqui podras ver algunos de nuestros proyectos que hemos realizado para nuestros clientes.",
    },
    Project1Title: {
      text: "Delivery",
    },
    Project1Text: {
      text: "Sitio web para una empresa de delivery, con un diseño moderno y atractivo, con un sistema de rastreo de pedidos en tiempo real.",
    },
    Project2Title: {
      text: "3D Sitio Web Demo",
    },
    Project2Text: {
      text: "Un sitio web demostrativo construido usando un diseño 3D, con animaciones y un diseño dinámico. (Es posible que el sitio web no funcione correctamente en dispositivos móviles porque fue construido con tecnologías experimentales).",
    },
    Project3Title: {
      text: "Construcción CR",
    },
    Project3Text: {
      text: "Experiencia en construcción de casas, apartamentos, oficinas, locales comerciales, remodelaciones, ampliaciones, acabados, entre otros.",
    },
    Project4Title: {
      text: "Julio Carvajal",
    },
    Project4Text: {
      // Julio es filmaker, fotografo, editor de video y Piloto de FPV Drone
      text: "Sitio web para Julio Carvajal, film maker, fotógrafo, editor de video y piloto de FPV Drone y DJI Drone, ademas de un sistema de pago para sus clientes.",
    },

    Project6Title: {
      text: "Costa Brava Real Estate",
    },
    Project6Text: {
      text: "Empresa de bienes raíces, con más de 15 años de experiencia en el sector inmobiliario.",
    },
    Project7Title: {
      text: "Flor de Chocolate",
    },
    Project7Text: {
      text: "Tienda virtual automatizada para un comercio de venta de flores de deliciosas fresas con chocolate.",
    },
    VistitButton: {
      text: "Visitar",
    },

    TechTitle: {
      text: "Trabajamos con las mejores tecnologías",
    },

    FAQTitle: {
      text: "Preguntas <br/> frecuentes",
    },
    FAQSubtitle: {
      text: "Aquí puedes encontrar algunas preguntas frecuentes que nos hacen nuestros clientes.",
    },
    FAQ1Title: {
      title: "¿Cómo puedo comenzar un proyecto con su empresa?",
      response:
        "Puede ponerse en contacto con nosotros a través de nuestro sitio web, por teléfono o correo electrónico. Estaremos encantados de discutir sus necesidades, objetivos y proporcionarle una consulta inicial sin compromiso.",
    },
    FAQ2Title: {
      title:
        "¿Cuánto tiempo lleva desarrollar una aplicación o un sitio web personalizado?",
      response:
        "El tiempo de desarrollo varía según la complejidad del proyecto. Realizamos una evaluación detallada al principio y proporcionamos un cronograma estimado. Factores como características específicas y revisiones pueden afectar la duración del proyecto.",
    },
    FAQ3Title: {
      title:
        "¿Cuál es su enfoque en la protección de datos y la privacidad del usuario?",
      response:
        "Nos tomamos muy en serio la protección de datos y la privacidad. Implementamos medidas robustas de seguridad, nos adherimos a las regulaciones de privacidad y trabajamos en estrecha colaboración con nuestros clientes para garantizar el cumplimiento normativo.",
    },
    FAQ4Title: {
      // precios de los servicios
      title:
        "¿Cuánto cuesta desarrollar una aplicación o un sitio web personalizado?",
      response:
        "El costo de un proyecto varía según la complejidad, el alcance y los requisitos específicos. Realizamos una evaluación detallada al principio y proporcionamos un presupuesto estimado. Factores como características específicas y revisiones pueden afectar el costo del proyecto.",
    },
    FAQ5Title: {
      title:
        "¿Ofrecen mantenimiento y soporte después del lanzamiento del proyecto?",
      response:
        "Sí, proporcionamos servicios integrales de soporte y mantenimiento para garantizar que su proyecto funcione de manera óptima. Esto incluye actualizaciones, correcciones de errores y asistencia técnica.",
    },
    FAQ6Title: {
      title:
        "¿Cómo manejan la escalabilidad de los proyectos a medida que crecen?",
      response:
        "Diseñamos nuestros proyectos teniendo en cuenta la escalabilidad. Utilizamos arquitecturas flexibles y tecnologías que permiten un crecimiento sin problemas. Además, ofrecemos servicios de consultoría para ajustar la infraestructura según sea necesario.",
    },

    FooterText: {
      text: "En WeCode nos dedicamos a desarrollar soluciones tecnológicas a la medida de tus necesidades, con las últimas tecnologías y con un diseño moderno y atractivo.",
      about: "Nosotros",
      blog: "Blog",
      contact: "Contacto",
      projects: "Proyectos",

      careers: "Ofertas de Trabajo",
      internship: "Prácticas",

      team: "Equipo",

      ia: "Inteligencia Artificial",
      gaming: "Gaming",
      technology: "Tecnología",

      copyright:
        "© 2024 WeCodeCR Impulsado por For All Mankind Tech. Todos los derechos reservados.",
    },

    webDevelopment: {
      title: "Desarrollo Web",
      description:
        "Para nosotros es importante que tu sitio web sea único, por eso nos encargamos de crear un diseño personalizado para tu negocio, con el objetivo de que tu sitio web sea único y se diferencie de la competencia.",
      btn1: "Responsive (Adaptable)",
      btn2: "Diseño Personalizado",
      btn3: "Formulario de Contacto",
      statstitle1: "Aumento",
      statstext1: "en sus visitas y ventas",
      statstitle2: "Más de",
      statstext2: "ventas de nuestros clientes",
      statstitle3: "Satisfacción",
      statstext3: "de nuestros clientes",

      cardtitle: "Para cualquier tipo de negocio o empresa",
      cardtext2: "Diseño personalizado",
      cardtext3:
        "Cada cliente es único, por eso nos encargamos de crear un diseño personalizado para tu negocio.",
      cardtext4: "Aumento de ventas",
      cardtext5:
        "Seas una persona o empresa, con un sitio web podrás aumentar tus ventas.",
      cardtext6: "Las mejores funcionalidades",
      cardtext7:
        "Tu sitio web contará con las mejores funcionalidades, como un formulario de contacto, un mapa de ubicación, un chat en vivo, animaciones, entre otros.",

      iconstext1: "Desarrollo Creativo",
      iconstext2:
        "Creamos sitios web únicos y atractivos que se adaptan a tu marca y a tu público objetivo.",
      iconstext3: "Actualizaciones sin Esfuerzo",
      iconstext4:
        "Benefíciate de actualizaciones automáticas en todos los sitios web en cualquier momento que necesites realizar un cambio.",
      iconstext5: "Funcionalidades Personalizadas",
      iconstext6:
        "Creamos funcionalidades personalizadas para que tu sitio web se adapte a tus necesidades.",
      iconstext7: "Conquista lo Mejor",
      iconstext8:
        "Con un sitio web profesional, tu negocio se verá más profesional y podrás competir con los mejores.",
      iconstext9: "Seguridad Primero",
      iconstext10:
        "Implementamos las mejores prácticas de seguridad para proteger la información valiosa de tu sitio web y de tus usuarios.",
      iconstext11: "Experiencia del Usuario",
      iconstext12:
        "Creamos experiencias de usuario atractivas y fáciles de usar para garantizar la satisfacción de tus visitantes.",

      ctatitle: "¿Estás listo para llevar tu negocio a las nubes?",
      ctatext:
        "Contamos con los mejores precios del mercado, además de que te ofrecemos un sitio web de calidad, las mejores funcionalidades y soporte técnico.",
      ctabtn: "Contáctanos",
    },

    AppDevelopment: {
      title: "Desarrollo de Apps",
      description:
        "Desarrollamos aplicaciones móviles nativas para Android y iOS con las últimas tecnologías y con un diseño moderno y atractivo.",
      btn: "Contáctanos",
      tech: "TECNOLOGÍAS",

      features1: "Compatibilidad",
      features1Description:
        "Desarrollamos aplicaciones móviles nativas para Android y iOS, para que tu aplicación sea compatible con todos los dispositivos móviles.",
      features2: "Diseño Moderno",
      features2Description:
        "Creamos un diseño moderno y atractivo para tu aplicación, para que tus usuarios tengan una buena experiencia de usuario.",
      features3: "Desarrollo Documentado",
      features3Description:
        "Documentamos todo el proceso de desarrollo de tu aplicación, para que puedas ver el progreso de tu aplicación en todo momento.",
      features4: "Soporte Técnico",
      features4Description:
        "Te ofrecemos soporte técnico para tu aplicación, para que puedas resolver cualquier problema que tengas con tu aplicación.",

      title2: "¿Por qué desarrollar una aplicación móvil?",
      description2:
        "Las aplicaciones móviles son una excelente opción para que tu negocio crezca, ya que te permiten llegar a más usuarios y aumentar tus ventas.",

      title3: "¿Cómo funciona el desarrollo de aplicaciones móviles?",
      description3:
        "El desarrollo de aplicaciones móviles es un proceso que consta de varias etapas, que van desde la planificación hasta el lanzamiento de la aplicación.",

      features5: "Planificación",
      features5Description:
        "En esta etapa, nos reunimos con el cliente para definir los objetivos y requisitos de la aplicación.",
      features6: "Diseño",
      features6Description:
        "En esta etapa, diseñamos el diseño de la aplicación, para que el cliente pueda ver cómo se verá la aplicación.",
      features7: "Desarrollo",
      features7Description:
        "En esta etapa, desarrollamos la aplicación, para que el cliente pueda ver cómo se verá la aplicación.",
      features8: "Pruebas",
      features8Description:
        "En esta etapa, probamos la aplicación, para que el cliente pueda ver cómo se verá la aplicación.",
      features9: "Control de Calidad",
      features9Description:
        "En esta etapa, controlamos la calidad de la aplicación, para que el cliente pueda ver cómo se verá la aplicación.",
      features10: "Lanzamiento",
      features10Description:
        "En esta etapa, lanzamos la aplicación, para que el cliente pueda ver cómo se verá la aplicación.",

      ctatext:
        "Contamos con los mejores precios del mercado, además de que te ofrecemos una aplicación de calidad, las mejores funcionalidades y soporte técnico.",
    },

    ecommerce: {
      title: "Tiendas Online | E-Commerce",
      description:
        "Digitaliza tu negocio y vende tus productos en línea con una tienda virtual a la medida de tus necesidades.",

      img1: "Hogar y Decoración",
      img2: "Moda y Ropa",
      img3: "Salud y Belleza",
      img4: "Joyas y Accesorios",
      img5: "Electrónica y Tecnología",

      title2: "¿Por qué desarrollar tu tienda en línea con nosotros?",
      description2:
        "Las tiendas en línea son una excelente opción para que tu negocio crezca, ya que te permiten llegar a más usuarios y aumentar tus ventas.",

      features1: "Diseño Personalizado",
      features1Description:
        "Creamos un diseño personalizado para tu tienda en línea, para que tu tienda en línea sea única y se diferencie de la competencia.",
      features2: "Integración con redes sociales",
      features2Description:
        "Integramos tu tienda en línea con las redes sociales, para que puedas compartir tus productos en las redes sociales.",
      features3: "Integración con pasarelas de pago",
      features3Description:
        "Integramos tu tienda en línea con las pasarelas de pago, para que tus clientes puedan pagar con tarjeta de crédito o débito.",
      features4: "Gestión de Inventario",
      features4Description:
        "Administra tus productos, inventario y ventas desde un solo lugar, para que puedas administrar tu tienda en línea de forma fácil y rápida.",
      features5: "Envío de Productos",
      features5Description:
        "Controla tus envíos y haz seguimiento de tus paquetes.",
      features6: "Soporte",
      features6Description:
        "Te ofrecemos soporte técnico para tu tienda en línea, para que puedas resolver cualquier problema que tengas con tu tienda en línea.",

      title3: "Lo sencillo que es tener tu propia tienda en línea",
      step1: "Planeación",
      step1Description:
        "Nos reunimos con el cliente para definir los objetivos y requisitos de la tienda en línea.",
      step2: "Diseño",
      step2Description:
        "Tu tienda debe ser única, por eso nos encargamos de diseñarla a tu medida.",
      step3: "Integración de métodos de pago",
      step3Description:
        "Acepta pagos con tarjeta de crédito, débito, PayPal y otros métodos de pago.",
      step4: "Lanzamiento",
      step4Description:
        "¡Tu tienda está lista! Ahora solo debes promocionarla para llegar a más clientes.",
    },

    CustomSoftware: {
      title: "Software Personalizado",
      description:
        "Ayudamos a las empresas a llevar sus ideas al mundo digital, diseñando e implementando las herramientas tecnológicas que necesitan para ganar.",
      svg1: "Mejorar",
      svg2: "Versatilidad",
      svg3: "Eficiencia",

      title2: "Te ayudamos a llegar a tus metas",
      description2:
        "Con software personalizado, puedes crear una solución que se adapte a tus necesidades específicas. Ya sea que esté creando una aplicación para uso interno o un producto para el mercado, el software personalizado puede ayudarlo a lograr sus objetivos comerciales.",
      features1: "Diseñado para ti o tu empresa",
      features1Description:
        "Se crea para satisfacer las necesidades específicas de su empresa, lo que significa que se adapta a sus requisitos y procesos comerciales únicos. Esto puede ayudarlo a lograr una mayor eficiencia y productividad en el lugar de trabajo.",
      features2: "Escalable y flexible",
      features2Description:
        "Se puede escalar y adaptar fácilmente a medida que su negocio crece y cambia. Esto le permite agregar nuevas funciones y capacidades a medida que las necesite, lo que le brinda la flexibilidad de adaptarse a los cambios en el mercado.",
      features3: "Seguro y confiable",
      features3Description:
        "Es más seguro que el software comercial, ya que está diseñado para satisfacer sus necesidades específicas. Esto significa que no hay características innecesarias que puedan ser vulnerables a los ataques cibernéticos, lo que hace que su software sea más seguro y confiable.",

      title3: "¿Por qué elegirnos?",
      description3:
        "Nuestro equipo de expertos en desarrollo de software puede ayudarlo a crear una solución que se adapte a sus necesidades específicas. Ya sea que esté creando una aplicación para uso interno o un producto para el mercado, podemos ayudarlo a lograr sus objetivos comerciales.",
      features4: "Desarrollo personalizado, resultados extraordinarios",
      features4Description:
        "Nuestro equipo de expertos en desarrollo de software puede ayudarlo a crear una solución que se adapte a sus necesidades específicas. Ya sea que esté creando una aplicación para uso interno o un producto para el mercado, podemos ayudarlo a lograr sus objetivos comerciales.",
      features5: "Eficiencia que impulsa tu competitividad",
      features5Description:
        "Cada empresa es diferente, por lo que necesita una solución que se adapte a sus necesidades específicas. Nuestro equipo de expertos en desarrollo de software puede ayudarlo a crear una solución que se adapte a sus necesidades específicas.",
      features6: "Diseño, escalabilidad y confiabilidad sin compromisos",
      features6Description:
        "Estamos listos para el siguiente paso en su viaje empresarial. Es por eso que cada software que creamos está diseñado para ser escalable y confiable, lo que le brinda la flexibilidad de adaptarse a los cambios en el mercado.",

      title4: "Ejemplos de <br/> Software",
      title41: "Personalizado",
      title5: "Sistema Personalizado de Gestión",
      description5: `Desarrollamos sistemas personalizados para empresas que optimizan procesos internos. <br />
  Ejemplos incluyen: <br />
  - Plataforma exclusiva para gestión de empleados <br />
  - Herramientas de gestión de recursos humanos adaptadas a necesidades específicas <br />
  - Soluciones para seguimiento de proyectos y tareas <br />`,

      title6: "Sistema Personalizado de Administración de Clientes y Empleados",
      description6: `Desarrollamos sistemas personalizados para gestionar de manera eficiente clientes y empleados. <br />Ejemplos incluyen: <br />
            - Plataforma exclusiva para administración de perfiles de clientes y empleados <br />
            - Soluciones personalizadas para seguimiento de historiales de ventas y clientes <br />
            - Herramientas adaptadas para registros de ventas y análisis de desempeño <br />`,

      title7: "Herramientas Personalizadas para Eficiencia Empresarial",
      description7: `Desarrollamos software personalizado para optimizar procesos y mejorar la eficiencia operativa. <br />Ejemplos incluyen: <br />
            - Herramientas de automatización adaptadas a procesos empresariales específicos <br />
            - Sistemas para seguimiento y mejora de la productividad interna <br />
            - Plataformas de gestión de proyectos diseñadas según necesidades particulares <br /> `,

      faqtitle: "Preguntas frecuentes",
      faq1: "¿Cómo garantizan la calidad del software?",
      faq1Description:
        "Realizamos pruebas exhaustivas en todas las etapas del desarrollo. Además, fomentamos la retroalimentación continua para asegurar que el producto final cumpla con sus expectativas de calidad.",
      faq2: "¿Qué tan escalable es el software que desarrollan?",
      faq2Description:
        "Desarrollamos soluciones altamente escalables que pueden crecer con su empresa. Pueden expandirse para incorporar nuevas funciones y capacidades a medida que evolucionan sus necesidades.",
      faq3: "¿Pueden integrar el nuevo software con mis sistemas existentes?",
      faq3Description:
        "Sí, diseñamos soluciones que se integran sin problemas con sus sistemas existentes. La interoperabilidad es clave para garantizar una transición suave.",
      faq4: "¿Qué tipo de soporte ofrecen después de implementar el software?",
      faq4Description:
        "Proporcionamos soporte continuo, incluyendo actualizaciones, mantenimiento y asistencia técnica. Estamos comprometidos a asegurar que su software funcione de manera óptima a lo largo del tiempo.",
      faq5: "¿Pueden desarrollar aplicaciones móviles como parte de los servicios personalizados?",
      faq5Description:
        "Sí, ofrecemos desarrollo de aplicaciones móviles personalizadas que se integran perfectamente con sus sistemas y objetivos comerciales.",
      faq6: "¿Cómo manejan los cambios en las regulaciones y estándares de la industria que puedan afectar al software?",
      faq6Description:
        "Mantenemos una vigilancia constante sobre cambios normativos y actualizamos su software de acuerdo con los nuevos requisitos para garantizar el cumplimiento continuo.",
    },

    businessSoftware: {
      title1: "Software para empresas",
      title2: "La solución que tu empresa necesita",
      subtitle1: "Nuestros clientes nos recomiendan",
      text1: "100+ clientes satisfechos",

      card1title: "Sitios Web, Portales y Plataformas Web",
      card1text:
        "Desarrollamos sitios web, portales y plataformas web a la medida de tus necesidades.",
      card2title: "Aplicaciones Móviles",
      card2text:
        "Desarrollamos aplicaciones móviles para Android y iOS a la medida de tus necesidades.",
      card3title: "Automatización de Procesos",
      card3text:
        "Automatizamos procesos de tu empresa para que puedas dedicar tu tiempo a lo que realmente importa.",

      title3: "¿Por qué somos",
      title3b: "la mejor opción?",
      description3:
        "Damos las mejores soluciones a nuestros clientes, desde el diseño hasta la implementación de las mismas. Nuestro equipo de trabajo está altamente calificado para brindarte la mejor atención y servicio.",
      features1: "Soporte 24/7",
      features1Description:
        "Contamos con un equipo de soporte técnico disponible las 24 horas del día, los 7 días de la semana.",
      features2: "Diseño a la medida",
      features2Description:
        "Nuestros diseños son únicos y a la medida de tus necesidades y requerimientos.",
      features3: "Tecnología de punta",
      features3Description:
        "Utilizamos las mejores tecnologías para el desarrollo de tus proyectos como lo son React, NextJS, NodeJS, MongoDB, entre otras.",
      features4: "Analítica de datos",
      features4Description:
        "Analizamos los datos de tu empresa para brindarte las mejores soluciones y así puedas tomar las mejores decisiones.",
      features5: "Seguridad",
      features5Description:
        "Nuestros servidores cuentan con las mejores medidas de seguridad para proteger tus datos, con un equipo de seguridad capaz de trabajar con las leyes COPPA, GDPR, HIPAA, entre otras.",
      features6: "Mantenimiento",
      features6Description:
        "Contamos con planes de mantenimiento para que tu sitio web, aplicación móvil o plataforma web siempre esté actualizada y en óptimas condiciones.",
      features7: "Desarrollo Sostenible",
      features7Description:
        "Nuestros desarrollos están pensados para ser escalables y sostenibles en el tiempo.",
      features8: "Sistemas ya desarrollados",
      features8Description:
        "Si cuentas con un sistema ya desarrollado y quieres actualizarlo, mejorarlo o agregarle nuevas funcionalidades, nosotros te ayudamos.",
    },

    apis: {
      title: "Desarrollo de",
      title1: "APIs",
      description:
        "Con el desarrollo de APIs, podemos crear una conexión entre tu aplicación y otras aplicaciones de terceros, como, por ejemplo, redes sociales, bancos, entre otros. De esta manera, podemos lograr una integración entre tu aplicación y otras aplicaciones de terceros.",
      title2: "¿Por qué las",
      title2b: "APIs",
      title2c: "son tan",
      title2d: "importantes para tu aplicación?",
      description2:
        "Las APIs son una parte fundamental de cualquier aplicación moderna. Son la forma en que las aplicaciones se comunican entre sí.",
      features1: "Documentación",
      features1Description:
        "Al crear una API, podemos documentarla para que otros desarrolladores puedan usarla. Esto es muy importante para que otros desarrolladores puedan comprenderla.",
      features2: "Seguridad",
      features2Description:
        "Protegemos toda la información que se envía a través de la API con un cifrado de 256 bits. Esto es esencial para evitar el robo de información.",
      features3: "Integración",
      features3Description:
        "Al crear una API, podemos integrarla con otras aplicaciones de terceros. Esto es crucial para que otras aplicaciones puedan aprovecharla.",
      features4: "Escalabilidad",
      features4Description:
        "Al crear una API, podemos escalarla para que pueda manejar más tráfico. Esto es esencial para evitar que la aplicación se caiga o falle.",

      faqtitle: "Preguntas frecuentes",
      faq1: "¿Qué es una API?",
      faq1Description:
        "Las APIs permiten que aplicaciones y servicios se comuniquen de manera estandarizada, facilitando la integración entre diferentes sistemas. Proporcionan un conjunto de funciones y procedimientos que permiten a los desarrolladores acceder a las características o datos de una aplicación, servicio o plataforma sin necesidad de conocer los detalles internos de su implementación.",
      faq2: "¿En qué me beneficia una API?",
      faq2Description:
        "Benefician a empresas, negocios e instituciones al permitir una integración fluida entre sistemas, acelerar el desarrollo mediante la reutilización de funciones existentes, facilitar el acceso a servicios externos, mejorar la experiencia del usuario, fomentar la innovación a través de la colaboración externa, ofrecer flexibilidad y escalabilidad, y posibilitar la colaboración eficiente con socios comerciales.",
      faq3: "¿Cuál es el costo de una API?",
      faq3Description:
        "El costo de una API depende de su complejidad y varía según la cantidad de endpoints, datos, usuarios, entre otros factores.",
      faq4: "¿Qué es un endpoint?",
      faq4Description:
        "Un endpoint es un punto de acceso a una API, es la URL a la que se debe llamar para obtener una respuesta de la API. Los endpoints pueden ser de diferentes tipos, por ejemplo, para obtener, enviar, actualizar o eliminar datos, entre otros.",
      faq5: "¿Cómo se protege una API?",
      faq5Description:
        "Las APIs se protegen mediante un cifrado de 256 bits, haciendo que la información enviada sea ilegible para cualquier persona que no tenga la clave de cifrado.",
      faq6: "¿Cuánto cuesta mantener una API?",
      faq6Description:
        "El costo de mantenimiento de una API depende de factores como la cantidad de usuarios, datos, endpoints, entre otros.",
    },

    anotherServices: {
      title: "Soluciones digitales para llevar tu negocio al",
      title1: "siguiente nivel",
      description:
        "En WeCode, nos encargamos de desarrollar soluciones digitales que se adapten a las necesidades de tu negocio, para que puedas llevarlo al siguiente nivel.",
      subtitle1: "Consultoría",
      title2: "¿Necesitas ayuda con tu proyecto?",
      description2:
        "Con nuestra consultoría, te ayudamos a definir la mejor estrategia para tu proyecto, para que puedas llevarlo a cabo de la mejor manera posible.",

      features1: "Estrategia",
      features1Description:
        "Te ayudamos a definir la mejor estrategia para tu proyecto, para que puedas llevarlo a cabo de la mejor manera posible.",
      features2: "Análisis",
      features2Description:
        "Analizamos tu proyecto y te ayudamos a definir la mejor estrategia para llevarlo a cabo.",
      features3: "Planificación",
      features3Description:
        "Te ayudamos a planificar tu proyecto, ya sea desde cero o si ya cuentas con uno, te ayudamos a mejorarlo.",
      features4: "Ejecución",
      features4Description:
        "Si tienes dudas sobre cómo llevar a cabo tu proyecto, te ayudamos a ejecutarlo de la mejor manera posible.",

      title3: "Servicios de SEO",
      card1title: "Optimización de Contenido",
      card1text:
        "Analizamos tu contenido y te ayudamos a optimizarlo para que sea más amigable con los motores de búsqueda.",
      card2title: "Análisis y Auditoría SEO",
      card2text:
        "Analizamos tu sitio web y te ayudamos a establecer una estrategia SEO para que puedas mejorar tu posicionamiento en los motores de búsqueda.",
      card3title: "Optimización Técnica",
      card3text:
        "Analizamos el código de tu sitio web y te ayudamos a optimizarlo o mejorarlo para que sea más amigable con los motores de búsqueda.",

      title4: "Mantenimiento y Soporte Técnico",
      subtitle4: "Te ayudamos a mantener tu sitio web",
      subtitle4b: "siempre actualizado",
      description4:
        "Con nuestro servicio de mantenimiento y soporte técnico, te ayudamos a mantener tu sitio web siempre actualizado, de manera que puedas tener la seguridad de que tu sitio web siempre estará funcionando correctamente.",
      features5: "Mantenimiento Preventivo",
      features5Description:
        "Con mantenimientos preventivos, tu sitio web siempre estará actualizado y funcionando correctamente, manteniendola estable y segura en todo momento.",
      features6: "Monitoreo del Sistema",
      features6Description:
        "Al monitorear el sistema, podemos detectar cualquier problema y solucionarlo antes de que se convierta en un problema mayor.",
      features7: "Actualización de Documentación",
      features7Description:
        "Con la actualización de la documentación, siempre tendrás la información de tu sitio web actualizada y disponible para cuando la necesites.",
      features8: "Resolución de Problemas",
      features8Description:
        "Al resolver problemas, podemos ayudarte a mantener tu sitio web funcionando correctamente, sin importar el problema que se presente.",
    },

    ia: {
      title: "El futuro de los negocios es la",
      title1: "Inteligencia Artificial",
      description:
        "En 2023, el 60% de las iniciativas de desarrollo de aplicaciones incluirán la IA co-desarrollada. La IA es una tecnología que se puede aplicar a cualquier sector, y que puede ayudar a las empresas a resolver problemas de manera más eficiente y a tomar mejores decisiones.",

      title2: "¿Cómo se puede utilizar la IA en su negocio?",
      description2:
        "La IA puede ayudar a las empresas a automatizar tareas repetitivas, a mejorar la experiencia del cliente y a tomar decisiones más inteligentes.",

      subtitle1: "Chatbots",
      description3:
        "Los chatbots son programas de software que pueden mantener conversaciones con los usuarios. Pueden ser utilizados para responder a preguntas frecuentes, proporcionar información sobre productos y servicios, o incluso para ayudar a los clientes a realizar compras, todo en tiempo real.",

      features1: "Asistentes Virtuales Personalizados (GPTs)",
      features1Description:
        "Con los nuevos GPTs podemos crear asistentes virtuales personalizados para su negocio. Estos asistentes pueden ayudar a los clientes a encontrar lo que están buscando, responder a sus preguntas y entrenarlos para que sepan todo sobre su negocio, productos y servicios.",
      features2: "Desarrollo de Contenido Automatizado",
      features2Description:
        "La IA puede ayudar a las empresas a crear contenido automatizado para sus sitios web, blogs y redes sociales. Esto puede ahorrar tiempo y dinero, ya que no es necesario contratar a escritores o editores de contenido.",
      features3: "Análisis de Datos y Generación de Informes",
      features3Description:
        "Con un GPT personalizado, podemos analizar los datos de su empresa y generar informes sobre los resultados. Esto puede ayudarle a tomar decisiones más inteligentes y a mejorar su negocio.",

      subtitle2: "Con la IA, su negocio puede ser más eficiente y rentable",
      description4:
        "Las empresas estan implementando la IA para todas las áreas de su negocio, desde la atención al cliente hasta la gestión de inventarios. La IA puede ayudar a las empresas a ser más eficientes y rentables.",

      features4: "Análisis Predictivo y Tendencias de Mercado",
      features4Description:
        "La IA puede ayudar a las empresas a predecir las tendencias del mercado y a tomar decisiones más inteligentes sobre sus productos y servicios. Esto puede ayudar a las empresas a ser más eficientes y rentables.",
      features5: "Automatización de Respuestas en Redes Sociales",
      features5Description:
        "Deja que la IA se encargue de responder a tus clientes en las redes sociales. Podemos crear un GPT personalizado para tu negocio que pueda responder a las preguntas de tus clientes y ayudarles a resolver sus problemas.",
      features6: "Generación y Reconocimiento de Imágenes",
      features6Description:
        "Diseño de productos, reconocimiento de imágenes, generación de imágenes, etc. Podemos ayuda a su empresa a utilizar la IA para crear imágenes personalizadas para sus productos y servicios.",

      examples: "Ejemplos de uso",
      description5:
        "Ejemplos de uso de la inteligencia artificial en el mundo real.",
      example1: "Mago de Datos y Análisis",
      example1Description:
        "Experto en todo tipo de análisis y visualización de datos y estadísticas de forma detallada.",
      try1: "Prueba Mago de Datos y Análisis",
      example2: "Digital Ad Creator",
      example2Description:
        "Experto en Marketing digital para imágenes y textos publicitarios para redes sociales.",
      try2: "Prueba Digital Ad Creator",
      example3: "Colorea Ideas",
      example3Description:
        "Colorea Ideas es un GPT personalizado que genera ilustraciones para colorear a partir de una descripción de texto.",
      try3: "Prueba Colorea Ideas",
      example4: "Tech Helper",
      example4Description:
        "Experto en soporte técnico que ofrece orientación paso a paso para la configuración y solución de problemas de dispositivos.",
      try4: "Prueba Tech Helper",
    },

    team: {
      HeroTeamsBar: "Conoce a nuestro equipo",
      HeroTitle1: "Todo lo que",
      HeroTitle2: "Puedas imaginar",
      HeroTitle3: "Podemos crearlo",
      HeroSubtitle:
        "Somos un equipo de profesionales altamente capacitados e innovadores que trabajan de cerca contigo para entender tus necesidades y desarrollar soluciones tecnológicas únicas y efectivas.",
      HeroButton: "Contactar",
      BarMissionandVision: "Nuestra Misión y Visión",
      TitleMissionandVision:
        "Innovamos para crear soluciones tecnológicas a medida de tu negocio",
      SubtitleMissionandVision:
        "Nuestra misión es proporcionar a nuestros clientes las mejores soluciones tecnológicas, con el objetivo de mejorar su productividad y competitividad. Nuestra visión es ser la empresa líder en el desarrollo de soluciones tecnológicas en Costa Rica.",
      CompanyValues: "Valores de la Empresa",
      CompanyValuesDescription:
        "Nuestros valores son los pilares que sostienen nuestra empresa y guían nuestras acciones. Son la esencia de nuestra identidad como empresa y representan las creencias y principios que orientan nuestro comportamiento.",
      OurMission: "Nuestra Misión",
      OurMissionDescription:
        "Nuestra misión es proporcionar a nuestros clientes las mejores soluciones tecnológicas, con el objetivo de mejorar su productividad y competitividad.",
      OurVision: "Nuestra Visión",
      OurVisionDescription:
        "Nuestra visión es transformar el mundo a través de la tecnología, siendo la empresa líder en el desarrollo de soluciones tecnológicas desde Costa Rica hacia el mundo.",
      ctatitle: "¿Estás listo para llevar tu negocio a las nubes?",
      ctatext:
        "Ofrecemos los mejores precios en el mercado, además de brindarte un sitio web o software de calidad, las mejores características y soporte técnico.",
      ctabtn: "Contáctanos",
    },

    contact: {
      ContactSub: "Contáctanos",
      ContactTitle: "¿Tienes alguna pregunta o consulta?",
      ContactText:
        "Si tienes alguna pregunta o consulta, no dudes en ponerte en contacto con nosotros.",
      ContactName: "Nombre",
      ContactEmail: "Correo Electrónico",
      ContactPhone: "Teléfono (Opcional)",
      ContactMessage: "Mensaje",
      ContactButton: "Enviar Mensaje",
      contactSuccess:
        "Mensaje enviado con éxito. Nos pondremos en contacto contigo lo antes posible.",
      ContactError:
        "Error al enviar el mensaje. Por favor, inténtalo nuevamente más tarde.",
      ErrorCatpcha: "Por favor, verifica que no eres un robot.",
    },

    promo: {
      title: "¿Quieres llevar tu negocio al siguiente nivel digital?",
      subtitle: "¡Te damos el Sitio Web sin costo y solo pagas",
      subtitle2: "$50 mensuales",
      subtitle3: "por mantenimiento!",

      stepTitle: "Los pasos a seguir para digitalizar tu negocio",
      subtitle4:
        "Facilitamos el proceso de digitalización de tu negocio con estos pasos.",
      step1: "Paso 1",
      step1Title: "Contacto inicial",
      step1Description:
        "Envíanos un mensaje para discutir tus necesidades y objetivos.",
      step2: "Paso 2",
      step2Title: "Consultoría personalizada",
      step2Description:
        "Analizaremos tus requerimientos y te brindaremos opciones personalizadas.",
      step3: "Paso 3",
      step3Title: "Desarrollo del sitio web",
      step3Description:
        "Creamos un sitio web profesional adaptado a tu marca y público objetivo.",
      step4: "Final",
      step4Title: "Mantenimiento continuo",
      step4Description:
        "Por solo $50 al mes, nos encargamos de mantener tu sitio actualizado y funcionando sin problemas.",

      ctaTitle: "¿Por qué necesitas un sitio web?",
      ctaSubtitle:
        "Un sitio web es una herramienta que ayuda a las personas y negocios a aumentar su visibilidad en línea y atraer a más clientes potenciales. Con un sitio web, puedes mostrar tus productos y servicios, compartir información sobre tu negocio y recopilar información de contacto de clientes potenciales.",
      ctaButton: "Enviar Mensaje",

      bannerTitle: "Promoción limitada",
      bannerSubtitle:
        "¿Quieres digitalizar tu negocio? ¡Te damos el Sitio Web sin costo y solo pagas $50 mensuales por mantenimiento!",
      bannerButton: "Ver Promoción",
      regulations: "Ver Reglamento",
    },

    jobs: {
      title1: "Trabajos disponibles en",
      title2: "WeCode",
      subtitle:
        "Sabemos que el talento es lo más importante para el éxito de una empresa, por eso estamos buscando personas apasionadas por la tecnología y ansiosas por aprender.",
      subtitle2:
        "Si no encuentras una vacante que coincida con tus habilidades o si tienes un proyecto que quieres compartir con nosotros, no dudes en contactarnos. Envíanos un correo electrónico a",

      searchPlaceholder: "Buscar título del trabajo o posición",
      location: "Ubicación",
      jobType: "Tipo de trabajo",
      searchButton: "Buscar",
      viewButton: "Ver trabajo",
      jobDescription: "Descripción",
      datePosted: "Fecha de publicación",
      salary: "Salario",
      jobRequirements: "Requisitos del trabajo",
      applyButton: "Aplicar",

      fullName: "Nombre completo",
      email: "Correo electrónico",
      phoneNumber: "Número de teléfono",
      uploadCV: "Cargar CV (Solo archivos PDF)",
      sendApplication: "Enviar solicitud",
      applicationSuccess:
        "Tu solicitud ha sido enviada correctamente. Nos pondremos en contacto contigo.",

      valuesTitle: "Beneficios de trabajar en WeCode",
      benefits1: "Salarios competitivos y beneficios atractivos",
      benefits1Description:
        "Ofrecemos salarios competitivos y beneficios atractivos a nuestros empleados. Creemos en recompensar el trabajo duro y la dedicación.",
      benefits2: "Oportunidades en proyectos de alto impacto",
      benefits2Description:
        "En WeCode, tendrás la oportunidad de trabajar en proyectos innovadores y de alto impacto que te desafiarán y te ayudarán a crecer profesionalmente.",
      benefits3: "Crecimiento profesional y desarrollo continuo",
      benefits3Description:
        "WeCode está comprometido con el crecimiento profesional de sus empleados, ofreciendo formación continua y oportunidades de desarrollo para ayudarte a alcanzar tu máximo potencial.",

      ctaTitle: "¿Listo para dar el siguiente paso?",
      ctaTitle2: "¡Únete a nosotros!",
      ctaSubtitle:
        "Siempre estamos buscando personas talentosas y motivadas para unirse a nuestro equipo. Si eres apasionado por la tecnología y quieres trabajar en un entorno dinámico e innovador, ¡WeCode es el lugar para ti!",
      ctaButton: "Ver vacantes de trabajo",

      noJobsFound: "No se encontraron trabajos que coincidan con tu búsqueda.",
    },
  },
};

export default es;
