import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import extrasData from "./extras.json";

export default function Checkout() {
  const location = useLocation();
  const { plan } = location.state || {};
  const [extras, setExtras] = useState({});
  const [selectedExtras, setSelectedExtras] = useState({});
  const [userFlow, setUserFlow] = useState("login"); // "login" or "register"
  const [billingCycle, setBillingCycle] = useState("annual");
  const [modals, setModals] = useState({
    operatingSystem: false,
  });

  useEffect(() => {
    if (extrasData[plan.category]) {
      setExtras(extrasData[plan.category].find((v) => v.id === plan.id) || {});
    } else {
      setExtras({});
    }
  }, [plan]);

  const handleUserFlowChange = (flow) => {
    setUserFlow(flow);
  };

  const handleBillingCycleChange = (e) => {
    setBillingCycle(e.target.value);
  };

  const handleExtraToggle = (category, extra) => {
    setSelectedExtras((prev) => {
      const updatedExtras = { ...prev };
      if (updatedExtras[category]?.name === extra.name) {
        delete updatedExtras[category];
      } else {
        updatedExtras[category] = extra;
      }
      return updatedExtras;
    });
  };

  const calculateTotal = () => {
    const planPrice =
      billingCycle === "annual"
        ? plan.price * 12
        : billingCycle === "semi-annual"
        ? plan.price * 6
        : plan.price;
    const extrasPrice = Object.keys(selectedExtras).reduce((acc, category) => {
      const price = selectedExtras[category]?.price || 0;
      return acc + price;
    }, 0);
    return planPrice + extrasPrice;
  };

  const toggleModal = (modal) => {
    setModals((prev) => ({ ...prev, [modal]: !prev[modal] }));
  };

  return (
    <>
      <div className="flex flex-col items-center border-b bg-white py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-32">
        <a href="#" className="text-2xl font-bold text-gray-800">
          WeCode Hosting
        </a>
      </div>
      <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
        <div className="px-4 pt-8">
          <p className="text-xl font-medium">Detalles de la orden</p>
          <p className="text-gray-400">
            Revisa los detalles de tu orden antes de proceder al pago y agrega
            tus extras.
          </p>
          <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6">
            <div className="flex flex-col rounded-lg bg-white sm:flex-row">
              <div className="flex w-full flex-col px-4 py-4">
                <span className="font-semibold">{plan.title}</span>
                <ul className="list-disc pl-5">
                  <li>Performance: {plan.performance}</li>
                  <li>Websites: {plan.websites}</li>
                  <li>
                    Storage:{" "}
                    {selectedExtras.storage
                      ? selectedExtras.storage.name
                      : plan.storage}
                  </li>
                  <li>Databases: {plan.databases}</li>
                  <li>Emails: {plan.emails}</li>
                  <li>Support: {plan.support}</li>
                  {selectedExtras.os && <li>OS: {selectedExtras.os.name}</li>}
                  {selectedExtras.apps && (
                    <li>Apps: {selectedExtras.apps.name}</li>
                  )}
                  {selectedExtras.plesk && (
                    <li>Plesk: {selectedExtras.plesk.name}</li>
                  )}
                  {selectedExtras.ssl && (
                    <li>SSL: {selectedExtras.ssl.name}</li>
                  )}
                  {selectedExtras.backup && (
                    <li>Backup: {selectedExtras.backup.name}</li>
                  )}
                  {selectedExtras.serverManagement && (
                    <li>
                      Server Management: {selectedExtras.serverManagement.name}
                    </li>
                  )}
                  {selectedExtras.monitoring && (
                    <li>Monitoring: {selectedExtras.monitoring.name}</li>
                  )}
                </ul>
                <p className="text-lg font-bold">${plan.price}</p>
              </div>
            </div>
          </div>

          {plan.category !== "web_hosting" && (
            <>
              <p className="mt-8 text-lg font-medium">Extras</p>
              <div className="mt-5 grid gap-6">
                <button
                  className="p-2 border rounded w-full flex justify-between items-center"
                  onClick={() => toggleModal("location")}
                >
                  Ubicación del servidor
                </button>
                <button
                  className="p-2 border rounded w-full flex justify-between items-center"
                  onClick={() => toggleModal("operatingSystem")}
                >
                  Sistema Operativo / Panel de Administración
                </button>
              </div>
            </>
          )}
        </div>
        <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
          <p className="text-xl font-medium">Detalles de Usuario</p>
          <p className="text-gray-400">
            Inicio de sesión o registro para continuar con tu orden.
          </p>
          <div className="mb-4">
            <label htmlFor="userFlow" className="block text-sm font-medium">
              Seleccione una opción:
            </label>
            <select
              id="userFlow"
              value={userFlow}
              onChange={(e) => handleUserFlowChange(e.target.value)}
              className="p-2 border rounded"
            >
              <option value="login">Iniciar Sesión</option>
              <option value="register">Registro</option>
            </select>
          </div>
          {userFlow === "login" ? (
            <>
              <label
                htmlFor="email"
                className="mt-4 mb-2 block text-sm font-medium"
              >
                Email
              </label>
              <div className="relative">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                  placeholder="your.email@gmail.com"
                />
                <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-99m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    />
                  </svg>
                </div>
              </div>
              <label
                htmlFor="password"
                className="mt-4 mb-2 block text-sm font-medium"
              >
                Password
              </label>
              <div className="relative">
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Password"
                />
                <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    />
                  </svg>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Formulario de registro /}
<label
             htmlFor="fullName"
             className="mt-4 mb-2 block text-sm font-medium"
           >
Nombre completo
</label>
<input
             type="text"
             id="fullName"
             name="fullName"
             className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
             placeholder="Nombre completo"
           />
{/ Agrega más campos de registro aquí */}
            </>
          )}
          <div className="mt-5">
            <h3 className="text-xl font-semibold mb-2">Billing Cycle</h3>
            <select
              value={billingCycle}
              onChange={handleBillingCycleChange}
              className="p-2 border rounded"
            >
              {plan.category === "web_hosting" ? (
                <>
                  <option value="semi-annual">Semi-Annual Billing</option>
                  <option value="annual">Annual Billing</option>
                </>
              ) : (
                <>
                  <option value="monthly">Monthly Billing</option>
                  <option value="quarterly">Quarterly Billing</option>
                  <option value="annual">Annual Billing</option>
                </>
              )}
            </select>
          </div>
          <div className="mt-6 border-t border-b py-2">
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Subtotal</p>
              <p className="font-semibold text-gray-900">
                ${calculateTotal().toFixed(2)}
              </p>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-between">
            <p className="text-sm font-medium text-gray-900">Total</p>
            <p className="text-2xl font-semibold text-gray-900">
              ${calculateTotal().toFixed(2)}
            </p>
          </div>
          <button className="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white">
            Place Order
          </button>
        </div>
      </div>
      {modals.location && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg shadow-lg w-1/3">
            <h2 className="text-xl font-bold mb-4">Seleccionar ubicación</h2>
            {Object.keys(extras.locations).map((location) => (
              <div key={location} className="mb-2">
                <button
                  className="p-2 border rounded w-full flex justify-between items-center"
                  onClick={() =>
                    handleExtraToggle("location", {
                      name: location,
                      price: extras.locations[location],
                    })
                  }
                >
                  <span>{location}</span>
                  <span>${extras.locations[location].toFixed(2)}</span>
                </button>
              </div>
            ))}
            <button
              className="mt-4 p-2 bg-red-500 text-white rounded"
              onClick={() => toggleModal("location")}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {modals.operatingSystem && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg shadow-lg w-2/3">
            <h2 className="text-xl font-bold mb-4">
              Sistema Operativo / Panel de Administración
            </h2>
            <div className="grid grid-cols-3 gap-4">
              {/* OS */}
              <div>
                <h3 className="text-lg font-semibold mb-2">
                  Sistema Operativo
                </h3>
                {/* Agrega aquí la imagen o logo correspondiente */}
                <div className="mb-4">
                  <select
                    className="p-2 border rounded w-full"
                    onChange={(e) =>
                      handleExtraToggle("os", {
                        name: e.target.value,
                        price: extras.os_prices[e.target.value] || 0,
                      })
                    }
                  >
                    <option value="">Seleccionar</option>
                    {extras.os.map((os) => (
                      <option key={os} value={os}>
                        {os}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Apps */}
              <div>
                <h3 className="text-lg font-semibold mb-2">Apps</h3>
                {/* Agrega aquí la imagen o logo correspondiente */}
                <div className="mb-4">
                  <select
                    className="p-2 border rounded w-full"
                    onChange={(e) =>
                      handleExtraToggle("apps", {
                        name: e.target.value,
                        price: extras.apps_prices[e.target.value] || 0,
                      })
                    }
                  >
                    <option value="">Seleccionar</option>
                    {extras.apps.map((app) => (
                      <option key={app} value={app}>
                        {app}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Plesk */}
              <div>
                <h3 className="text-lg font-semibold mb-2">Plesk</h3>
                {/* Agrega aquí la imagen o logo correspondiente */}
                <div className="mb-4">
                  <select
                    className="p-2 border rounded w-full"
                    onChange={(e) =>
                      handleExtraToggle("plesk", {
                        name: e.target.value,
                        price: extras.plesk[e.target.value] || 0,
                      })
                    }
                  >
                    <option value="">Seleccionar</option>
                    {Object.keys(extras.plesk).map((plesk) => (
                      <option key={plesk} value={plesk}>
                        {plesk}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <button
              className="mt-4 p-2 bg-red-500 text-white rounded"
              onClick={() => toggleModal("operatingSystem")}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}
