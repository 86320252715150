import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { Player } from "@lottiefiles/react-lottie-player";
import Animation from "../../assets/3d/animation-ai.json";
import Animation1 from "../../assets/3d/animation-chatbot.json";
import Animation2 from "../../assets/images/stock/chatgpt.gif";
import GPT1 from "../../assets/images/stock/data.webp";
import GPT2 from "../../assets/images/stock/colorea.webp";
import GPT3 from "../../assets/images/stock/ad.webp";
import GPT4 from "../../assets/images/stock/helper.webp";

import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function AiDevelopment() {
  const { t } = useTranslation();
  const textArray = (t("ia.title") + " " + t("ia.title1")).split("");
  const textContainerRef = useRef(null);

  const line1 = t("ia.title");
  const line2 = t("ia.title1");

  const animateText = () => {
    const textArray = (line1 + " " + line2).split("");
    const shuffledIndices = shuffleArray(textArray.map((_, index) => index));

    shuffledIndices.forEach((index, animationIndex) => {
      gsap.fromTo(
        `.letter-${index}`,
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.1,
          delay: animationIndex * 0.1,
        }
      );
    });
  };

  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }

  useEffect(() => {
    animateText();
  }, []);
  return (
    <div className="relative overflow-hidden">
      <div className="max-w-[95rem] mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <div className="max-w-2xl text-center mx-auto">
          <h1
            ref={textContainerRef}
            className="text-4xl text-[#1f2937] font-nasalization font-bold lg:text-5xl lg:leading-tight dark:text-gray-200"
          >
            {textArray.map((letter, index) => (
              <span
                key={index}
                className={`letter-${index} ${
                  index >= line1.length ? "text-[#00acee]" : ""
                }`}
              >
                {letter}
              </span>
            ))}
          </h1>
          <p className="mt-3 text-md text-gray-600 dark:text-gray-400">
            {t("ia.description")}
          </p>
        </div>

        <div className="mt-10 relative max-w-5xl mx-auto">
          <div className="w-full object-cover h-96 sm:h-[480px] rounded-xl"></div>

          <div className="absolute inset-0 w-full h-full">
            <div className="flex flex-col justify-center items-center w-full h-full">
              <Player
                autoplay
                loop
                src={Animation}
                style={{ maxWidth: "55%", height: "auto" }}
              />
            </div>
          </div>

          <div className="absolute bottom-12 start-20 -z-[1] w-48 h-48 bg-gradient-to-b from-orange-500 to-white dark:bg-slate-200 p-px rounded-lg">
            <div className="bg-white w-48 h-48 rounded-lg dark:bg-slate-900"></div>
          </div>

          <div className="absolute top-12 end-20 -z-[1] w-48 h-48 bg-gradient-to-t from-blue-600 to-cyan-400 dark:bg-slate-200 p-px rounded-full">
            <div className="bg-white w-48 h-48 rounded-full dark:bg-slate-900"></div>
          </div>
        </div>
      </div>

      <div className="container max-w-xl mt-0 lg:mt-20 p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
        <div>
          <h2 className="text-3xl font-bold tracki text-[#1f2937] text-center sm:text-5xl dark:text-white">
            {t("ia.title2")}
          </h2>
          <p className="max-w-3xl mx-auto mt-4 text-xl text-center text-gray-600 dark:text-gray-400">
            {t("ia.description2")}
          </p>
        </div>
        <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
          <div className="-mt-0 lg:-mt-20">
            <h3 className="text-2xl font-bold tracki text-[#1f2937] sm:text-3xl dark:text-white">
              {t("ia.subtitle1")}
            </h3>
            <p className="mt-3 text-lg text-gray-600 dark:text-gray-400">
              {t("ia.description3")}
            </p>
            <div className="mt-12 space-y-12">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 rounded-xl bg-[#00acee] text-white dark:bg-[#00acee] dark:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-7 h-7"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg font-medium leadi text-[#1f2937] dark:text-gray-50">
                    {t("ia.features1")}
                  </h4>
                  <p className="mt-2 text-gray-600 dark:text-gray-400">
                    {t("ia.features1Description")}
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 rounded-xl bg-[#00acee] text-white dark:bg-[#00acee] dark:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-7 h-7"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg font-medium leadi text-[#1f2937] dark:text-gray-50">
                    {t("ia.features2")}
                  </h4>
                  <p className="mt-2 text-gray-600 dark:text-gray-400">
                    {t("ia.features2Description")}
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 rounded-xl bg-[#00acee] text-white dark:bg-[#00acee] dark:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-7 h-7"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg font-medium leadi text-[#1f2937] dark:text-gray-50">
                    {t("ia.features3")}
                  </h4>
                  <p className="mt-2 text-gray-600 dark:text-gray-400">
                    {t("ia.features3Description")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div aria-hidden="true" className="mt-10 lg:mt-0">
            <Player
              autoplay
              loop
              speed={0.5}
              src={Animation1}
              style={{ maxWidth: "90%", height: "auto" }}
            />
          </div>
        </div>
        <div>
          <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-bold tracki text-[#1f2937] sm:text-3xl dark:text-gray-50">
                {t("ia.subtitle2")}
              </h3>
              <p className="mt-3 text-lg text-gray-600 dark:text-gray-400">
                {t("ia.description4")}
              </p>
              <div className="mt-12 space-y-12">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 rounded-xl bg-[#00acee] text-white dark:bg-[#00acee] dark:text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-7 h-7"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-medium leadi text-[#1f2937] dark:text-gray-50">
                      {t("ia.features4")}
                    </h4>
                    <p className="mt-2 text-gray-600 dark:text-gray-400">
                      {t("ia.features4Description")}
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 rounded-xl bg-[#00acee] text-white dark:bg-[#00acee] dark:text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-7 h-7"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-medium leadi text-[#1f2937] dark:text-gray-50">
                      {t("ia.features5")}
                    </h4>
                    <p className="mt-2 text-gray-600 dark:text-gray-400">
                      {t("ia.features5Description")}
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center w-12 h-12 rounded-xl bg-[#00acee] text-white dark:bg-[#00acee] dark:text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-7 h-7"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-medium leadi text-[#1f2937] dark:text-gray-50">
                      {t("ia.features6")}
                    </h4>
                    <p className="mt-2 text-gray-600 dark:text-gray-400">
                      {t("ia.features6Description")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
              <div className="mockup-browser border bg-base-300 dark:bg-gray-500 dark:border-gray-600 w-[350px] h-[230px] md:w-full md:h-full mx-auto">
                <div className="mockup-browser-toolbar">
                  <div className="input">https://wecodecr.com</div>
                </div>
                <div className="flex justify-center bg-base-200">
                  <LazyLoadImage
                    src={Animation2}
                    alt="ChatGPT Website"
                    effect="blur"
                    className="mx-auto max-w-full h-auto shadow-lg dark:bg-gray-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="max-w-2xl mb-10">
          <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">
            {t("ia.examples")}
          </h2>
          <p className="mt-1 text-gray-600 dark:text-gray-400">
            {t("ia.description5")}
          </p>
        </div>

        <div className="grid lg:grid-cols-2 lg:gap-y-16 gap-10">
          <a
            className="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            href="https://chat.openai.com/g/g-VDmded5yO-mago-de-datos-y-analisis"
          >
            <div className="sm:flex">
              <div className="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
                <LazyLoadImage
                  className="group-hover:scale-105 transition-transform duration-500 ease-in-out w-full h-full absolute top-0 start-0 object-cover rounded-xl"
                  src={GPT1}
                  alt="ChatGPT Model"
                />
              </div>

              <div className="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
                <h3 className="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
                  {t("ia.example1")}
                </h3>
                <p className="mt-3 text-gray-600 dark:text-gray-400">
                  {t("ia.example1Description")}
                </p>
                <p className="mt-4 inline-flex items-center gap-x-1 text-blue-600 decoration-2 hover:underline font-medium">
                  {t("ia.try1")}
                  <svg
                    className="flex-shrink-0 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </p>
              </div>
            </div>
          </a>

          <a
            className="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            href="https://chat.openai.com/g/g-50ZqxkZv1-digital-ad-creator"
          >
            <div className="sm:flex">
              <div className="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
                <LazyLoadImage
                  className="group-hover:scale-105 transition-transform duration-500 ease-in-out w-full h-full absolute top-0 start-0 object-cover rounded-xl"
                  src={GPT3}
                  alt="ChatGPT Model"
                />
              </div>

              <div className="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
                <h3 className="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
                  {t("ia.example2")}
                </h3>
                <p className="mt-3 text-gray-600 dark:text-gray-400">
                  {t("ia.example2Description")}
                </p>
                <p className="mt-4 inline-flex items-center gap-x-1 text-blue-600 decoration-2 hover:underline font-medium">
                  {t("ia.try2")}
                  <svg
                    className="flex-shrink-0 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </p>
              </div>
            </div>
          </a>

          <a
            className="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            href="https://chat.openai.com/g/g-TWZc3VT87-colorea-ideas"
          >
            <div className="sm:flex">
              <div className="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
                <LazyLoadImage
                  className="group-hover:scale-105 transition-transform duration-500 ease-in-out w-full h-full absolute top-0 start-0 object-cover rounded-xl"
                  src={GPT2}
                  alt="ChatGPT Model"
                />
              </div>

              <div className="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
                <h3 className="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
                  {t("ia.example3")}
                </h3>
                <p className="mt-3 text-gray-600 dark:text-gray-400">
                  {t("ia.example3Description")}
                </p>
                <p className="mt-4 inline-flex items-center gap-x-1 text-blue-600 decoration-2 hover:underline font-medium">
                  {t("ia.try3")}
                  <svg
                    className="flex-shrink-0 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </p>
              </div>
            </div>
          </a>

          <a
            className="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            href="https://chat.openai.com/g/g-XgEZyO86C-tech-helper"
          >
            <div className="sm:flex">
              <div className="flex-shrink-0 relative rounded-xl overflow-hidden w-full sm:w-56 h-44">
                <LazyLoadImage
                  className="group-hover:scale-105 transition-transform duration-500 ease-in-out w-full h-full absolute top-0 start-0 object-cover rounded-xl"
                  src={GPT4}
                  alt="ChatGPT Model"
                />
              </div>

              <div className="grow mt-4 sm:mt-0 sm:ms-6 px-4 sm:px-0">
                <h3 className="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-300 dark:group-hover:text-white">
                  {t("ia.example4")}
                </h3>
                <p className="mt-3 text-gray-600 dark:text-gray-400">
                  {t("ia.example4Description")}
                </p>
                <p className="mt-4 inline-flex items-center gap-x-1 text-blue-600 decoration-2 hover:underline font-medium">
                  {t("ia.try4")}
                  <svg
                    className="flex-shrink-0 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div className="container mb-20 mt-10 flex flex-col items-center px-4 py-12 mx-auto text-center">
        <h2 className="text-2xl font-bold tracking-tight text-[#1f2937] xl:text-3xl dark:text-white">
          {t("webDevelopment.ctatitle")}
        </h2>

        <p className="block max-w-4xl mt-4 text-gray-600 dark:text-gray-300">
          {t("webDevelopment.ctatext")}
        </p>

        <div className="mt-6">
          <a
            href="/contact"
            className="inline-flex items-center justify-center w-full px-4 py-2.5 mt-4 overflow-hidden text text-white transition-colors duration-300 bg-[#00acee] rounded-lg shadow sm:w-auto sm:mx-2 sm:mt-0 hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
          >
            <svg
              className="w-5 h-5 mx-2 fill-current"
              viewBox="-28 0 512 512.00075"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m432.320312 215.121094-361.515624-208.722656c-14.777344-8.53125-32.421876-8.53125-47.203126 0-.121093.070312-.230468.148437-.351562.21875-.210938.125-.421875.253906-.628906.390624-14.175782 8.636719-22.621094 23.59375-22.621094 40.269532v417.445312c0 17.066406 8.824219 32.347656 23.601562 40.878906 7.390626 4.265626 15.496094 6.398438 23.601563 6.398438s16.214844-2.132812 23.601563-6.398438l361.519531-208.722656c14.777343-8.53125 23.601562-23.8125 23.601562-40.878906s-8.824219-32.347656-23.605469-40.878906zm-401.941406 253.152344c-.21875-1.097657-.351562-2.273438-.351562-3.550782v-417.445312c0-2.246094.378906-4.203125.984375-5.90625l204.324219 213.121094zm43.824219-425.242188 234.21875 135.226562-52.285156 54.539063zm-6.480469 429.679688 188.410156-196.527344 54.152344 56.484375zm349.585938-201.835938-80.25 46.332031-60.125-62.714843 58.261718-60.773438 82.113282 47.40625c7.75 4.476562 8.589844 11.894531 8.589844 14.875s-.839844 10.398438-8.589844 14.875zm0 0"></path>
            </svg>

            <span className="mx-2">{t("webDevelopment.ctabtn")}</span>
          </a>
        </div>
      </div>
    </div>
  );
}
