import React, { lazy, Suspense, useEffect } from "react";

// import Nexus from "./nexus";
import { SparklesPreview } from "./brand.tsx";
import Hero from "./hero.js";
import CTAClient from "./cta-client.js";
import CtaImage from "./cta-image.js";
import Steps from "./steps.js";
import Tech from "./tech.js";
import Footer from "./footer.js";
export const MainPromo = () => {
  useEffect(() => {
    const handleScrollLock = () => {
      if (window.innerWidth < 768) {
        // Si el ancho de la ventana es menor que 768px (dispositivo móvil)
        document.body.style.overflowX = "hidden"; // Bloquear desplazamiento horizontal
      } else {
        document.body.style.overflowX = "auto"; // Permitir desplazamiento horizontal
      }
    };

    // Agregar evento de escucha para cambiar el desplazamiento en función del ancho de la ventana
    window.addEventListener("resize", handleScrollLock);

    // Limpieza: eliminar el evento de escucha al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleScrollLock);
    };
  }, []); // El efecto solo se ejecuta una vez al montar el componente

  return (
      <Suspense fallback={<div>Loading...</div>}>
        <Hero />
        <div className="bg-slate-900">
        <Steps />
        <CTAClient />
        <CtaImage />
        <Tech />
        </div>
        <SparklesPreview />
        <Footer />

      </Suspense>
  );
};

