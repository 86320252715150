import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import data from "../data.json";

export default function PricingTable() {
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setPlans(data.web_hosting);
  }, []);

  const handleCheckout = (plan) => {
    navigate("/hosting/checkout", { state: { plan } });
  };

  return (
    <section id="plans" className="text-gray-700 body-font mt-10 overflow-hidden dark:bg-gray-900">
      <div className="flex flex-col gap-2 lg:gap-4 container mx-auto max-w-7xl">
        <h1 className="text-5xl font-bold text-center dark:text-white">
          Escoge tu plan
        </h1>
        <p className="text-gray-600 text-center dark:text-gray-400">
          Elige el plan de hosting que mejor se adapte a tus necesidades.
        </p>
      </div>
      <div className="container px-5 py-24 mx-auto flex flex-wrap">
        <div className="lg:w-1/4 mt-48 hidden lg:block">
          <div className="mt-px border-t border-gray-300 border-b border-l rounded-tl-lg rounded-bl-lg overflow-hidden">
            <p className="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start -mt-px dark:bg-gray-800 dark:text-gray-100">
              Rendimiento
            </p>
            <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-start dark:text-gray-100">
              Websites
            </p>
            <p className="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start dark:bg-gray-800 dark:text-gray-100">
              Almacenamiento
            </p>
            <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-start dark:text-gray-100">
              Bases de Datos
            </p>
            <p className="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start dark:bg-gray-800 dark:text-gray-100">
              Ancho de Banda Ilimitado
            </p>
            <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-start dark:text-gray-100">
              Dominio gratis (valor de $11.99/año)
            </p>
            <p className="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start dark:bg-gray-800 dark:text-gray-100">
              Certificado SSL gratis (valor de $99.99/por año)
            </p>
            <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-start dark:text-gray-100">
              Cuentas de Correo
            </p>
            <p className="bg-gray-100 text-gray-900 h-12 text-center px-4 flex items-center justify-start dark:bg-gray-800 dark:text-gray-100">
              Soporte
            </p>
          </div>
        </div>
        <div className="flex lg:w-3/4 w-full flex-wrap lg:border border-gray-300 rounded-lg">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={`lg:w-1/3 lg:mt-px w-full mb-10 lg:mb-0 border-2 ${
                plan.popular ? "border-yellow-700" : "border-gray-300"
              } lg:border-none rounded-lg lg:rounded-none relative`}
            >
              {plan.popular && (
                <span className="bg-yellow-700 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                  POPULAR
                </span>
              )}
              <div className="px-2 text-center h-48 flex flex-col items-center justify-center">
                <h3 className="tracking-widest dark:text-gray-400">
                  {plan.title}
                </h3>
                <h2 className="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2 dark:text-white">
                  ${plan.price.toFixed(2)}
                  <span className="text-gray-600 text-base ml-1 dark:text-gray-400">
                    /m
                  </span>
                </h2>
                <span className="text-sm text-gray-600 dark:text-gray-400">
                  {plan.billing}
                </span>
              </div>
              <p className="bg-gray-100 text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300 dark:bg-gray-800 dark:text-gray-100">
                {plan.performance}
              </p>
              <p className="text-gray-600 text-center h-12 flex items-center justify-center dark:text-gray-100">
                {plan.websites}
              </p>
              <p className="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center dark:bg-gray-800 dark:text-gray-100">
                {plan.storage}
              </p>
              <p className="h-12 text-gray-600 text-center leading-relaxed flex items-center justify-center dark:text-gray-100">
                {plan.databases}
              </p>
              <p className="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center dark:bg-gray-800 dark:text-gray-100">
                <span className="w-5 h-5 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
              </p>
              <p className="text-gray-600 text-center h-12 flex items-center justify-center dark:text-gray-100">
                <span className="w-5 h-5 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
              </p>
              <p className="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center dark:bg-gray-800 dark:text-gray-100">
                <span className="w-5 h-5 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
              </p>
              <p className="text-gray-600 text-center h-12 flex items-center justify-center dark:text-gray-100">
                {plan.emails}
              </p>
              <p className="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center dark:bg-gray-800 dark:text-gray-100">
                {plan.support}
              </p>
              <div className="border-t border-gray-300 p-6 text-center rounded-bl-lg">
                <button
                  className="flex items-center mt-auto rounded-lg text-white bg-blue-700 hover:bg-yellow-700 border-0 py-2 px-4 w-full focus:outline-none rounded"
                  onClick={() => handleCheckout(plan)}
                >
                  Comprar ahora
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 ml-auto"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </button>
                <p className="text-xs text-gray-500 mt-3 dark:text-gray-400">
                  {plan.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
