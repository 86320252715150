import React from "react";
import { Handshake, Gem, TrendingUp } from "lucide-react";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
const Value = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-7xl mx-auto px-[1rem] ">
      <h1 className="text-textColor text-[35px] py-3 pb-[3rem] font-bold w-[400px] block px-4 dark:text-white">
        {" "}
        {t("jobs.valuesTitle")}
      </h1>

      <div className="grid gap-[2rem] lg:flex lg:items-center">
        <div className="singleGrid rounded-[10px] hover:bg-[#eeedf7] p-[1.5rem] mb-[1rem] lg:mb-0 lg:w-1/3 dark:hover:bg-[#3f3d56]">
          <div className="flex items-center gap-3">
            <div className="imgDiv p-[4px] rounded-full bg-[#dedef8] h-[40px] w-[60px] flex items-center justify-center">
              <Handshake size={24} className=" text-[#3f3d56] dark:text-black" />
            </div>
            <span className="font-semibold text-textColor text-[18px] dark:text-white">
              {/* // Benefit 1 */}
              {t("jobs.benefits1")}
            </span>
          </div>
          <p className="text-[16px] text-gray-700 opacity-[.7] py-[1rem] font-medium dark:text-gray-300">
            {t("jobs.benefits1Description")}
          </p>
        </div>

        <div className="singleGrid rounded-[10px] hover:bg-[#f3e3eee5] p-[1.5rem] mb-[1rem] lg:mb-0 lg:w-1/3 dark:hover:bg-[#3f3d56]">
          <div className="flex items-center gap-3">
            <div className="imgDiv p-[4px] rounded-full bg-[#f7d1e1] h-[40px] w-[60px] flex items-center justify-center">
              <Gem size={24} className=" text-[#3f3d56] dark:text-black" />
            </div>
            <span className="font-semibold text-textColor text-[18px] dark:text-white">
              {/* // Benefit 2 */}
              {t("jobs.benefits2")}
            </span>
          </div>
          <p className="text-[16px] text-gray-700 opacity-[.7] py-[1rem] font-medium dark:text-gray-300">
            {/* // Benefit 2 Description */}
            {t("jobs.benefits2Description")}
          </p>
        </div>

        <div className="singleGrid rounded-[10px] hover:bg-[#fcfae3] p-[1.5rem] mb-10 lg:w-1/3 dark:hover:bg-[#3f3d56]">
          <div className="flex items-center gap-3">
            <div className="imgDiv p-[4px] rounded-full bg-[#f3f2ad] h-[40px] w-[60px] flex items-center justify-center">
              <TrendingUp size={24} className=" text-[#3f3d56] dark:text-black" />
            </div>
            <span className="font-semibold text-textColor text-[18px] dark:text-white">
              {/* // Benefit 3 */}
              {t("jobs.benefits3")}
            </span>
          </div>
          <p className="text-[16px] text-gray-700 opacity-[.7] py-[1rem] font-medium dark:text-gray-300">
            {/* // Benefit 3 Description */}
            {t("jobs.benefits3Description")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Value;
