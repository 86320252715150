// careers.js
import React, { useState, useEffect } from "react";
import { Nav } from "../navbar";
import SearchBar from "./search.tsx";
import Cards from "./cards.js";
import jobOffersData from "./jobOffers.json";
import Value from './value.js'
import CTA from './cta.js'
import Footer from '../../home/footer.js'

import "../../../translations/i18n";
import { useTranslation } from "react-i18next";

export default function Careers() {
  const [jobOffers, setJobOffers] = useState([]);
  const [filteredJobOffers, setFilteredJobOffers] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    setJobOffers(jobOffersData);
    setFilteredJobOffers(jobOffersData);
  }, []);

  const handleFilter = (location, jobType, searchInput) => {
    let filteredOffers = jobOffers.filter((offer) => {
      const locationMatch = !location || offer.location === location;
      const jobTypeMatch = !jobType || offer.jobType === jobType;
      const titleMatch =
        !searchInput ||
        offer.title.toLowerCase().includes(searchInput.toLowerCase());
      const descriptionMatch =
        !searchInput ||
        offer.description.toLowerCase().includes(searchInput.toLowerCase());
      return locationMatch && jobTypeMatch && (titleMatch || descriptionMatch);
    });
    setFilteredJobOffers(filteredOffers);
  };

  return (
    <>
      <div className="relative bg-gray-900 dark:bg-gray-900">
        <Nav />
        <div className="absolute inset-x-0 bottom-0">
          <svg
            viewBox="0 0 224 12"
            fill="currentColor"
            className="w-full -mb-1 text-white dark:text-gray-900"
            preserveAspectRatio="none"
          >
            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
          </svg>
        </div>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
            <h2 className="mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
            {t("jobs.title1")}{" "}
              <span className="relative inline-block">
                {t("jobs.title2")}
                <div className="w-full h-2 -mt-2 bg-[#00acee]" />
              </span>
            </h2>
            <p className="mb-6 text-base font-medium tracking-wide text-gray-300 md:text-lg">
              {t("jobs.subtitle")}
            </p>

            <p className="max-w-md mb-1 text-xs font-regular tracking-wide text-gray-500 sm:text-sm sm:mx-auto ">
            {t("jobs.subtitle2")}{" "}
              <a
                href="mailto:contact@wecodecr.com"
                className="text-white underline"
              >
                {" "}
                contact@wecodecr.com{" "}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-900">
      <SearchBar handleFilter={handleFilter} />
      {filteredJobOffers.length === 0 ? (
        <p className="text-gray-500 text-lg font-medium text-center mt-8 mb-10 px-4 py-4">
          {t("jobs.noJobsFound")}
        </p>
      ) : (
        <Cards jobOffers={filteredJobOffers} />
      )}
      <Value />
      <CTA />
      <Footer />
      </div>
    </>
  );
}
