import React from "react";
import { ChevronRight } from 'lucide-react';

export default function CardsTypesHosting() {
    // Función para redirigir a la URL correspondiente
    const handleClick = (url) => {
        window.location.href = url;
    };

    return (
        <section className="dark:bg-gray-900 py-12 lg:py-4 mb-10 px-4">
            <div className="py-12 lg:py-8 container mx-auto max-w-7xl">
                <div className="flex flex-col gap-2 lg:gap-4">
                    <h1 className="text-5xl font-bold text-start dark:text-white">Tipos de Hosting</h1>
                    <p className="text-gray-600 text-start dark:text-gray-400">Elige el plan de hosting que mejor se adapte a tus necesidades.</p>
                </div>
            </div>
            <div className="container mx-auto max-w-7xl">
                <div className="grid grid-cols-1 mt-8 gap-3 lg:grid-cols-3">
                    <div className="bg-slate-200 dark:bg-gray-800 shadow-lg rounded-lg p-6 hover:-translate-y-1 transition-transform duration-300 hover:shadow-xl ">
                        <img src='https://cdn-icons-png.flaticon.com/512/9954/9954416.png' alt="Shared Hosting" className="w-20 h-20" />
                        <h3 className="text-2xl font-bold dark:text-white text-start mt-2 mb-2">Hosting Web</h3>
                        <p className="text-gray-600 text-start dark:text-gray-400">Alojamiento web para sitios web pequeños y medianos. Ideal para blogs, sitios informativos y más.</p>
                        <button onClick={() => handleClick("/hosting/web-hosting")} className="bg-blue-700 text-white dark:text-gray-200 p-2 rounded-full hover:bg-blue-700 dark:hover:bg-gray-800 transition-colors duration-300 mt-3 hover:bg-yellow-700"> <ChevronRight className="w-6 h-6" /></button>
                    </div>
                    <div className="bg-slate-200 dark:bg-gray-800 shadow-lg rounded-lg p-6 hover:-translate-y-1 transition-transform duration-300 hover:shadow-xl ">
                        <img src='https://cdn-icons-png.flaticon.com/512/8047/8047699.png' alt="VPS Hosting" className="w-20 h-20 " />
                        <h3 className="text-xl font-bold dark:text-white text-start mt-2 mb-2">VPS Hosting</h3>
                        <p className="text-gray-600 text-start dark:text-gray-400">Servidores virtuales privados con alto rendimiento. Ideal para empresas y sitios web de alto tráfico y alto consumo de recursos.</p>
                        <button onClick={() => handleClick("/hosting/vps")} className="bg-blue-700 text-white dark:text-gray-200 p-2 rounded-full hover:bg-blue-700 dark:hover:bg-gray-800 transition-colors duration-300 mt-3 hover:bg-yellow-700"> <ChevronRight className="w-6 h-6" /></button>
                    </div>
                    <div className="bg-slate-200 dark:bg-gray-800 shadow-lg rounded-lg p-6 hover:-translate-y-1 transition-transform duration-300 hover:shadow-xl ">
                        <img src='https://cdn-icons-png.flaticon.com/512/622/622339.png' alt="VDS Hosting" className="w-20 h-20 " />
                        <h3 className="text-xl font-bold dark:text-white text-start mt-2 mb-2">VDS Hosting</h3>
                        <p className="text-gray-600 text-start dark:text-gray-400">Servidores virtuales dedicados con recursos garantizados. Ideal para empresas y aplicaciones críticas.</p>
                        <button onClick={() => handleClick("/hosting/vds")} className="bg-blue-700 text-white dark:text-gray-200 p-2 rounded-full hover:bg-blue-700 dark:hover:bg-gray-800 transition-colors duration-300 mt-3 hover:bg-yellow-700"> <ChevronRight className="w-6 h-6" /></button>
                    </div>
                    <div className="bg-slate-200 dark:bg-gray-800 shadow-lg rounded-lg p-6 hover:-translate-y-1 transition-transform duration-300 hover:shadow-xl ">
                        <img src='https://cdn-icons-png.flaticon.com/512/1792/1792238.png' alt="Dedicated Servers" className="w-20 h-20 " />
                        <h3 className="text-xl font-bold dark:text-white text-start mt-2 mb-2">Servidores Dedicados</h3>
                        <p className="text-gray-600 text-start dark:text-gray-400">Servidores dedicados con recursos exclusivos. Ideal para negocios y empresas que requieren un alto rendimiento y seguridad de un servidor privado.</p>
                        <button onClick={() => handleClick("/hosting/servers-dedicated")} className="bg-blue-700 text-white dark:text-gray-200 p-2 rounded-full hover:bg-blue-700 dark:hover:bg-gray-800 transition-colors duration-300 mt-3 hover:bg-yellow-700"> <ChevronRight className="w-6 h-6" /></button>
                    </div>
                    <div className="bg-slate-200 dark:bg-gray-800 shadow-lg rounded-lg p-6 hover:-translate-y-1 transition-transform duration-300 hover:shadow-xl ">
                        <img src='https://cdn-icons-png.flaticon.com/512/3899/3899414.png' alt="WordPress Hosting" className="w-20 h-20 " />
                        <h3 className="text-xl font-bold dark:text-white text-start mt-2 mb-2">Gaming Hosting</h3>
                        <p className="text-gray-600 text-start dark:text-gray-400">Servidores para juegos con alto rendimiento y baja latencia. Ideal para servidores de Minecraft, CS:GO, ARK, Rust y más.</p>
                        <button onClick={() => handleClick("/hosting/vps#gaming")} className="bg-blue-700 text-white dark:text-gray-200 p-2 rounded-full hover:bg-blue-700 dark:hover:bg-gray-800 transition-colors duration-300 mt-3 hover:bg-yellow-700"> <ChevronRight className="w-6 h-6" /></button>
                    </div>
                    <div className="bg-slate-200 dark:bg-gray-800 shadow-lg rounded-lg p-6 hover:-translate-y-1 transition-transform duration-300 hover:shadow-xl ">
                        <img src='https://cdn-icons-png.flaticon.com/512/4617/4617745.png' alt="GPU Cloud Servers" className="w-20 h-20 " />
                        <h3 className="text-xl font-bold dark:text-white text-start mt-2 mb-2">GPU Cloud Servers</h3>
                        <p className="text-gray-600 text-start dark:text-gray-400">Servidores en la nube con tecnología NVIDIA. Ideal para aplicaciones de inteligencia artificial y aprendizaje profundo.</p>
                        <p className="text-start text-blue-700 font-semibold italic dark:text-blue-500 mt-3">Próximamente</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
