import React from "react";

import Cpanel from "../../../assets/hosting/cpanel-logo.webp";
import Wordpress from "../../../assets/hosting/Wordpress-logo.webp";
import Joomla from "../../../assets/hosting/Joomla.png";
import Drupal from "../../../assets/hosting/Drupal.png";
import Prestashop from "../../../assets/hosting/PrestaShop.png";

export default function Apps() {
  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto bg-white dark:bg-gray-900">
      <div className="w-2/3 sm:w-1/2 lg:w-1/3 text-xl mx-auto text-center mb-6">
        <h2 className="text-gray-600 font-semibold dark:text-white">
          Las mejores aplicaciones y herramientas para tu sitio web
        </h2>
      </div>

      <div className="flex flex-wrap justify-center gap-x-6 sm:gap-x-12 lg:gap-x-24">
        <img
          src={Cpanel}
          alt="cPanel"
          className="w-24 sm:w-32 lg:w-40 h-auto mb-4 object-contain"
        />

        <img
          src={Wordpress}
          alt="WordPress"
          className="w-24 sm:w-32 lg:w-40 h-auto mb-4 object-contain"
        />

        <img
          src={Joomla}
          alt="Joomla"
          className="w-24 sm:w-32 lg:w-40 h-auto mb-4 object-contain"
        />

        <img
          src={Drupal}
          alt="Drupal"
          className="w-24 sm:w-32 lg:w-40 h-auto mb-4 object-contain"
        />

        {/* Agrega más imágenes aquí si es necesario */}
      </div>
      <div className="w-2/3 sm:w-1/2 lg:w-1/3 mx-auto text-center mb-6">
        <h2 className="text-gray-600 font-medium dark:text-gray-200">
          Y muchas más...
        </h2>
      </div>
    </div>
  );
}
