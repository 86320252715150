import React, { useState, useRef } from "react";
import "../../../translations/i18n";
import { useTranslation } from "react-i18next";
export default function Cards({ jobOffers }) {
  const { t } = useTranslation();

  const [showJobDialog, setShowJobDialog] = useState(false);
  const [showCvDialog, setShowCvDialog] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [cvSent, setCvSent] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    phoneNumber: "",
    jobTitle: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Nuevo estado para controlar el loader

  const openJobDialog = (job) => {
    setSelectedJob(job);
    setShowJobDialog(true);
  };

  const closeJobDialog = () => {
    setSelectedJob(null);
    setShowJobDialog(false);
    setCvSent(false);
    setSelectedFile(null);
    setErrorMessage("");
    setFormData({
      email: "",
      fullName: "",
      phoneNumber: "",
      jobTitle: "",
    });
  };

  const openCvDialog = () => {
    setShowJobDialog(false);
    setShowCvDialog(true);
  };

  const closeCvDialog = () => {
    setShowCvDialog(false);
    setShowJobDialog(true);
    setCvSent(false);
    setSelectedFile(null);
    setErrorMessage("");
    setFormData({
      email: "",
      fullName: "",
      phoneNumber: "",
      jobTitle: selectedJob ? selectedJob.title : "",
    });
  };

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
    setErrorMessage("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrorMessage("");
  };

  const handleCvSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Mostrar el loader

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("email", formData.email);
      formDataToSend.append("fullName", formData.fullName);
      formDataToSend.append("phoneNumber", formData.phoneNumber);
      formDataToSend.append("cv", selectedFile);
      formDataToSend.append("jobTitle", selectedJob.title);

      const response = await fetch("http://localhost:3002/api/upload-cv", {
        method: "POST",
        body: formDataToSend,
      });

      if (response.ok) {
        setCvSent(true);
        setErrorMessage("");

        setTimeout(() => {
          setCvSent(false);
          setSelectedFile(null);
          setFormData({
            email: "",
            fullName: "",
            phoneNumber: "",
            jobTitle: selectedJob ? selectedJob.title : "",
          });
          setShowJobDialog(false);
          setShowCvDialog(false);
        }, 4000);
      } else {
        throw new Error(t("SubmitErrorMessage.text"));
      }
    } catch (error) {
      console.error("Error CV:", error);
      setErrorMessage(t("SubmitErrorMessage.text"));
    } finally {
      setIsLoading(false); // Ocultar el loader después de recibir la respuesta
    }
  };

  const jobDialogRef = useRef(null);
  const cvDialogRef = useRef(null);

  const handleClickOutside = (e) => {
    if (jobDialogRef.current && !jobDialogRef.current.contains(e.target)) {
      closeJobDialog();
    }
    if (cvDialogRef.current && !cvDialogRef.current.contains(e.target)) {
      closeCvDialog();
    }
  };

  return (
    <div id="jobs" className="grid grid-cols-1 gap-8 px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ">
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">

      {jobOffers.map((jobOffer, index) => (
  <div
    key={index}
    className="relative p-6 bg-white rounded-xl shadow-md transform transition duration-300 hover:shadow-lg hover:scale-105 dark:bg-gray-800 dark:border-gray-700 border border-transparent hover:border-blue-500"
  >
    <div className="flex flex-col h-full">
      {/* Header Section */}
      <div className="mb-3">
        <h6 className="text-2xl font-semibold text-gray-900 dark:text-white">
          {jobOffer.title}
        </h6>
        <p className="text-sm text-gray-500 mt-1 dark:text-gray-400">
          {jobOffer.datePosted}
        </p>
      </div>

      {/* Description */}
      <p className="text-gray-700 dark:text-gray-300 mb-4 line-clamp-3 leading-relaxed">
        {jobOffer.description}
      </p>

      {/* Footer Section */}
      <div className="flex justify-between items-center mt-auto pt-4 border-t border-gray-200 dark:border-gray-700">
        <div className="flex flex-col">
          <span className="text-sm text-blue-600 font-medium dark:text-blue-400">
            {t("jobs.location")}
          </span>
          <span className="text-gray-600 dark:text-gray-400 text-sm">
            {jobOffer.location}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <div className="bg-yellow-900 text-white rounded-full px-3 py-1 text-xs font-semibold dark:bg-blue-800">
            {jobOffer.jobType}
          </div>
          <button
            onClick={() => openJobDialog(jobOffer)}
            className="bg-blue-600 text-white px-3 py-1 rounded-md hover:bg-blue-700 focus:outline-none"
          >
            {t("jobs.viewButton")}
          </button>
        </div>
      </div>
    </div>
  </div>
))}




      </div>

      {showJobDialog && (
  <div
    className="fixed inset-0 z-50 overflow-y-auto bg-gray-800 bg-opacity-75 flex items-center justify-center"
    onClick={handleClickOutside}
  >
    <div
      ref={jobDialogRef}
      className="relative p-8 bg-white rounded-2xl shadow-xl max-w-2xl w-full mx-4 dark:bg-gray-800 transform transition-transform duration-300 ease-in-out"
    >
      {/* Close Button */}
      <button
        onClick={closeJobDialog}
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 dark:text-gray-300"
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>

      {/* Job Title */}
      <h5 className="mb-6 text-2xl font-semibold text-center text-gray-900 dark:text-white">
        {selectedJob.title}
      </h5>

      {/* Job Description */}
      <div className="mb-6">
        <h3 className="font-bold text-lg text-gray-800 dark:text-gray-100">
          {t("jobs.jobDescription")}
        </h3>
        <p className="mt-2 text-gray-700 dark:text-gray-300 leading-relaxed">
          {selectedJob.fullDescription}
        </p>
      </div>

      {/* Job Details */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm font-medium text-gray-700 dark:text-gray-300 mb-6">
        <div>
          <p className="text-blue-800 dark:text-blue-400">
            {t("jobs.location")}:{" "}
            <span className="text-gray-600 dark:text-gray-400">
              {selectedJob.location}
            </span>
          </p>
        </div>
        <div>
          <p className="text-blue-800 dark:text-blue-400">
            {t("jobs.jobType")}:{" "}
            <span className="text-gray-600 dark:text-gray-400">
              {selectedJob.jobType}
            </span>
          </p>
        </div>
        <div>
          <p className="text-blue-800 dark:text-blue-400">
            {t("jobs.datePosted")}:{" "}
            <span className="text-gray-600 dark:text-gray-400">
              {selectedJob.datePosted}
            </span>
          </p>
        </div>
        <div>
          <p className="text-blue-800 dark:text-blue-400">
            {t("jobs.salary")}:{" "}
            <span className="text-gray-600 dark:text-gray-400">
              {selectedJob.salary}
            </span>
          </p>
        </div>
      </div>

      {/* Job Requirements */}
      <div className="mb-6">
        <h3 className="font-bold text-lg text-gray-800 dark:text-gray-100">
          {t("jobs.jobRequirements")}
        </h3>
        <ul className="list-disc list-inside mt-2 space-y-2 text-gray-700 dark:text-gray-300">
          {selectedJob.requirements.map((requirement, index) => (
            <li key={index}>{requirement}</li>
          ))}
        </ul>
      </div>

      {/* Apply Button */}
      <div className="mt-8">
        <button
          onClick={openCvDialog}
          className="w-full py-3 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 focus:outline-none transition duration-200 dark:bg-blue-500 dark:hover:bg-blue-600"
        >
          {t("jobs.applyButton")}
        </button>
      </div>
    </div>
  </div>
)}


{showCvDialog && (
  <div
    className="fixed inset-0 z-50 overflow-y-auto bg-gray-800 bg-opacity-75 flex items-center justify-center"
    onClick={handleClickOutside}
  >
    <div
      ref={cvDialogRef}
      className="relative p-8 bg-white rounded-2xl shadow-xl max-w-xl w-full mx-4 dark:bg-gray-800 transform transition-transform duration-300 ease-in-out"
    >
      {/* Close Button */}
      <button
        onClick={closeCvDialog}
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 dark:text-gray-300"
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>

      {/* Title */}
      <h5 className="mb-6 text-2xl font-semibold text-center text-gray-900 dark:text-white">
        {selectedJob.title}
      </h5>

      {/* Form */}
      <form onSubmit={handleCvSubmit} className="space-y-4">
        {/* Full Name */}
        <div>
          <label
            htmlFor="fullName"
            className="block text-sm font-medium text-gray-700 dark:text-gray-100"
          >
            {t("jobs.fullName")}
          </label>
          <input
            id="fullName"
            type="text"
            name="fullName"
            value={formData.fullName}
            placeholder={t("jobs.fullName")}
            onChange={handleChange}
            required
            className="mt-1 w-full px-4 py-2 rounded-lg bg-gray-50 border border-gray-300 focus:border-blue-500 focus:outline-none dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300"
          />
        </div>

        {/* Email */}
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 dark:text-gray-100"
          >
            {t("jobs.email")}
          </label>
          <input
            id="email"
            type="email"
            name="email"
            value={formData.email}
            placeholder={t("jobs.email")}
            onChange={handleChange}
            required
            className="mt-1 w-full px-4 py-2 rounded-lg bg-gray-50 border border-gray-300 focus:border-blue-500 focus:outline-none dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300"
          />
        </div>

        {/* Phone Number */}
        <div>
          <label
            htmlFor="phoneNumber"
            className="block text-sm font-medium text-gray-700 dark:text-gray-100"
          >
            {t("jobs.phoneNumber")}
          </label>
          <input
            id="phoneNumber"
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            placeholder={t("jobs.phoneNumber")}
            onChange={handleChange}
            required
            className="mt-1 w-full px-4 py-2 rounded-lg bg-gray-50 border border-gray-300 focus:border-blue-500 focus:outline-none dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300"
          />
        </div>

        {/* CV Upload */}
        <div>
          <label
            htmlFor="cvUpload"
            className="block text-sm font-medium text-gray-700 dark:text-gray-100"
          >
            {t("jobs.uploadCV")}
          </label>
          <input
            id="cvUpload"
            type="file"
            accept=".pdf"
            onChange={handleFileSelect}
            required
            className="mt-1 w-full px-4 py-2 rounded-lg bg-gray-50 border border-gray-300 focus:border-blue-500 focus:outline-none dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300"
          />
          {selectedFile && (
            <p className="text-xs text-gray-500 mt-1 dark:text-gray-400">
              {selectedFile.name}
            </p>
          )}
        </div>

        {/* Error Message */}
        {errorMessage && (
          <p className="text-sm text-red-600 mt-2">{errorMessage}</p>
        )}

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full py-3 mt-6 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition duration-200 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none"
        >
          {t("jobs.sendApplication")}
        </button>

        {/* Success and Loading States */}
        {cvSent && (
          <p className="mt-2 text-green-600 text-center">
            {t("jobs.applicationSuccess")}
          </p>
        )}
        {isLoading && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 dark:border-blue-400"></div>
          </div>
        )}
      </form>
    </div>
  </div>
)}



    </div>
  );
}
