import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";
import LazyLoadImageMain from "../../assets/3d/hero-main.webp";
import Figure3 from "../../assets/3d/figure-3.webp";
import Figure4 from "../../assets/3d/figure-4.webp";
import Figure5 from "../../assets/3d/figure-5.webp";
import Figure6 from "../../assets/3d/lucas.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import "../../App.css";

import "../useTheme.js";
const Hero = () => {
  useEffect(() => {
    const handleScrollLock = () => {
      if (window.innerWidth < 768) {
        // Si el ancho de la ventana es menor que 768px (dispositivo móvil)
        document.body.style.overflowX = "hidden"; // Bloquear desplazamiento horizontal
      } else {
        document.body.style.overflowX = "auto"; // Permitir desplazamiento horizontal
      }
    };

    // Agregar evento de escucha para cambiar el desplazamiento en función del ancho de la ventana
    window.addEventListener("resize", handleScrollLock);

    // Limpieza: eliminar el evento de escucha al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleScrollLock);
    };
  }, []); // El efecto solo se ejecuta una vez al montar el componente
  const textContainerRef = useRef(null);
  const imageRef = useRef(null);

  const { t } = useTranslation();
  // texto usando la traducción
  const text = t("HeroTitle.text");

  const animateText = () => {
    const textArray = text.split("");
    const shuffledIndices = shuffleArray(textArray.map((_, index) => index));

    shuffledIndices.forEach((index, animationIndex) => {
      gsap.fromTo(
        `.letter-${index}`,
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          duration: 1.5,
          delay: animationIndex * 0.1,
          ease: "power3.out",
          onComplete: () => {
            if (animationIndex === shuffledIndices.length - 1) {
              gsap.to(imageRef.current, {
                duration: 1,
                opacity: 1,
              });
            }
          },
        }
      );
    });
  };

  // Función para mezclar aleatoriamente un array usando el algoritmo de Fisher-Yates
  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }

  useEffect(() => {
    animateText();
  }, []); // Añadir soundPlayed como dependencia para evitar advertencias de ESLint

  return (
    <section
      id="Hero"
      className="max-w-full flex flex-col md:flex-row items-center relative dark:bg-gray-900 md:h-[calc(100vh-4rem)] mx-auto"
    >
      <div className="inset-y-0 top-0 right-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0 ">
        <div className="relative flex flex-col items-center justify-center h-full max-w-3xl mx-auto lg:max-w-none">
          <div className="relative w-full h-full">
            <LazyLoadImage
              className="hidden md:block 2xl:block absolute left-1/3 animate-float-second h-64 w-64 md:w-56 md:h-56 lg:h-56 lg:w-56 2xl:h-64 2xl:w-64"
              src={Figure4}
              
              alt="Credit card with a statistics and a check mark"
            />
            
            <LazyLoadImage
              className="hidden md:hidden 2xl:block absolute top-1/3 animate-float-first left-[100px] md:h-56 lg:h-56 lg:w-56 2xl:h-64 2xl:w-64"
              src={Figure3}
              
              alt="Laptop with a WeCode logo and a statistics"
            />
          </div>
          <LazyLoadImage
            ref={imageRef}
            className="relative right-0 lg:absolute animate-float duration-3000 ease-in-out infinite w-full -mt-[25px] md:w-auto md:h-auto lg:w-auto lg:h-auto lg:-top-[-65px] 2xl:w-9/12 2xl:h-9/12"
            src={Figure6}
            
            alt="A person with a glasses looking at a multiple types of multimedia and web development icons"
          />
        </div>
      </div>

      <div className="w-full md:w-1/2 flex flex-col justify-center items-center ">
        <div ref={textContainerRef} className="text-center ">
          <h1 className="text-5xl lg:text-8xl 2xl:text-9xl md:text-5xl text-[#1f2937] font-normal font-nasalization dark:text-gray-200 font-bold -mt-[-20px] md:-mt-12 leading-none">
            {text.split("").map((letter, index) => (
              <span key={index} className={`letter-${index}`}>
                {letter}
              </span>
            ))}
          </h1>
        </div>
      </div>
    </section>
  );
};

export default Hero;
