import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Personalized() {
  const { t } = useTranslation();

  const textContainerRef = useRef(null);

  const text = t("CustomSoftware.title");

  const animateText = () => {
    const textArray = text.split("");
    const shuffledIndices = shuffleArray(textArray.map((_, index) => index));

    shuffledIndices.forEach((index, animationIndex) => {
      gsap.fromTo(
        `.letter-${index}`,
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          duration: 1.5,
          delay: animationIndex * 0.1,
        }
      );
    });
  };

  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }

  useEffect(() => {
    animateText();
  }, []);

  return (
    <div className="max-w-[95rem] -mt-6 mx-auto dark:bg-gray-900">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl  md:mx-auto sm:text-center lg:max-w-2xl ">
          <h2
            ref={textContainerRef}
            className="block mb-2 text-center text-4xl font-nasalization font-bold text-[#1f2937] sm:text-4xl md:text-5xl lg:text-6xl dark:text-white"
          >
            {text.split("").map((letter, index) => (
              <span key={index} className={`letter-${index}`}>
                {letter}
              </span>
            ))}
          </h2>
          <p className="text-base text-gray-700 md:text-lg dark:text-gray-400">
            {t("CustomSoftware.description")}
          </p>
        </div>
      </div>
      <div className="relative -mt-5 px-4 sm:px-0">
        <div className="absolute inset-0 h-1/2" />
        <div className="relative grid mx-auto overflow-hidden bg-white divide-y rounded-xl shadow sm:divide-y-0 sm:divide-x sm:max-w-screen-sm sm:grid-cols-3 lg:max-w-screen-md border border-gray-200 dark:bg-slate-900 dark:border-gray-700">
          <div className="inline-block p-8 text-center">
            <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-indigo-50">
              <svg
                className="text-[#00acee]"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
                <path d="m9 12 2 2 4-4" />
              </svg>
            </div>
            <p className="font-bold tracking-wide text-[#1f2937] dark:text-gray-200">
              {t("CustomSoftware.svg1")}
            </p>
          </div>
          <div className="inline-block p-8 text-center">
            <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-indigo-50">
              <svg
                className="text-[#00acee]"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M7 7h10v10" />
                <path d="M7 17 17 7" />
              </svg>
            </div>
            <p className="font-bold tracking-wide text-[#1f2937] dark:text-gray-200">
              {t("CustomSoftware.svg2")}
            </p>
          </div>
          <div className="inline-block p-8 text-center">
            <div className="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-indigo-50">
              <svg
                className="text-[#00acee]"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="m12 14 4-4" />
                <path d="M3.34 19a10 10 0 1 1 17.32 0" />
              </svg>
            </div>
            <p className="font-bold tracking-wide text-[#1f2937] dark:text-gray-200">
              {t("CustomSoftware.svg3")}
            </p>
          </div>
        </div>
      </div>

      <div className="px-4 mt-10 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="grid md:grid-cols-2 gap-12">
          <div className="lg:w-3/4">
            <h2 className="text-3xl text-[#1f2937] font-bold lg:text-4xl dark:text-white">
              {t("CustomSoftware.title2")}
            </h2>
            <p className="mt-3 text-[#1f2937] dark:text-gray-400">
              {t("CustomSoftware.description2")}
            </p>
          </div>

          <div className="space-y-6 lg:space-y-10">
            <div className="flex">
              <span className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200 bg-white text-[#1f2937] shadow-sm mx-auto dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
                <svg
                  className="flex-shrink-0 w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
                  <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
                </svg>
              </span>
              <div className="ms-5 sm:ms-8">
                <h3 className="text-base sm:text-lg font-semibold text-[#1f2937] dark:text-gray-200">
                  {t("CustomSoftware.features1")}
                </h3>
                <p className="mt-1 text-gray-600 dark:text-gray-400">
                  {t("CustomSoftware.features1Description")}
                </p>
              </div>
            </div>

            <div className="flex">
              <span className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200 bg-white text-[#1f2937] shadow-sm mx-auto dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
                <svg
                  className="flex-shrink-0 w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z" />
                  <path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1" />
                </svg>
              </span>
              <div className="ms-5 sm:ms-8">
                <h3 className="text-base sm:text-lg font-semibold text-[#1f2937] dark:text-gray-200">
                  {t("CustomSoftware.features2")}
                </h3>
                <p className="mt-1 text-gray-600 dark:text-gray-400">
                  {t("CustomSoftware.features2Description")}
                </p>
              </div>
            </div>

            <div className="flex">
              <span className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200 bg-white text-[#1f2937] shadow-sm mx-auto dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
                <svg
                  className="flex-shrink-0 w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M7 10v12" />
                  <path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" />
                </svg>
              </span>
              <div className="ms-5 sm:ms-8">
                <h3 className="text-base sm:text-lg font-semibold text-[#1f2937] dark:text-gray-200">
                  {t("CustomSoftware.features3")}
                </h3>
                <p className="mt-1 text-gray-600 dark:text-gray-400">
                  {t("CustomSoftware.features3Description")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-xl mt-5 mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-[#1f2937] sm:text-4xl md:mx-auto dark:text-white">
          <span className="relative inline-block">
            {t("CustomSoftware.title3")}
          </span>{" "}
        </h2>
        <p className="text-base text-gray-700 md:text-lg dark:text-gray-400">
          {t("CustomSoftware.description3")}
        </p>
      </div>
      <div className="grid max-w-screen-lg gap-8 lg:grid-cols-2 sm:mx-auto">
        <div className="grid grid-cols-2 gap-5">
          <LazyLoadImage
            effect="blur"
            className="object-cover w-full h-56 col-span-2 rounded shadow-lg"
            src="https://images.pexels.com/photos/3182746/pexels-photo-3182746.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
            alt=""
          />
          <LazyLoadImage
            effect="blur"
            className="object-cover w-full h-48 rounded shadow-lg"
            src="https://images.pexels.com/photos/3184296/pexels-photo-3184296.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
            alt=""
          />
          <LazyLoadImage
            effect="blur"
            className="object-cover w-full h-48 rounded shadow-lg"
            src="https://images.pexels.com/photos/3184338/pexels-photo-3184338.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
            alt=""
          />
        </div>
        <div className="flex flex-col justify-center">
          <div className="pb-4 mb-4 border-b">
            <h6 className="mb-2 font-semibold text-[#1f2937] leading-5 dark:text-gray-200">
              {t("CustomSoftware.features4")}
            </h6>
            <p className="text-sm text-[#1f2937] dark:text-gray-400">
              {t("CustomSoftware.features4Description")}
            </p>
          </div>
          <div className="pb-4 mb-4 border-b">
            <h6 className="mb-2 font-semibold text-[#1f2937] leading-5 dark:text-gray-200">
              {t("CustomSoftware.features5")}
            </h6>
            <p className="text-sm text-[#1f2937] dark:text-gray-400">
              {t("CustomSoftware.features5Description")}
            </p>
          </div>
          <div>
            <h6 className="mb-2 font-semibold text-[#1f2937] leading-5 dark:text-gray-200">
              {t("CustomSoftware.features6")}
            </h6>
            <p className="text-sm text-[#1f2937] dark:text-gray-400">
              {t("CustomSoftware.features6Description")}
            </p>
          </div>
        </div>
      </div>

      <div className="container px-6 py-10 mt-10 mx-auto">
        <h1 className="text-2xl font-semibold text-[#1f2937] capitalize lg:text-3xl dark:text-white">
          {" "}
          <div
            dangerouslySetInnerHTML={{ __html: t("CustomSoftware.title4") }}
          />{" "}
          <span className="underline decoration-blue-500">
            {t("CustomSoftware.title41")}
          </span>
        </h1>

        <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
          <div className="p-8 space-y-3 border-2 border-[#00acee] dark:border-blue-300 rounded-xl">
            <span className="inline-block text-[#00acee] dark:text-blue-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                />
              </svg>
            </span>

            <h1 className="text-xl font-semibold text-gray-700 capitalize dark:text-white">
              {t("CustomSoftware.title5")}
            </h1>

            <p className="text-gray-600 dark:text-gray-300">
              <div
                dangerouslySetInnerHTML={{
                  __html: t("CustomSoftware.description5"),
                }}
              />
            </p>
          </div>

          <div className="p-8 space-y-3 border-2 border-[#00acee] dark:border-blue-300 rounded-xl">
            <span className="inline-block text-[#00acee] dark:text-blue-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                />
              </svg>
            </span>

            <h1 className="text-xl font-semibold text-gray-700 capitalize dark:text-white">
              {" "}
              {t("CustomSoftware.title6")}
            </h1>

            <p className="text-gray-500 dark:text-gray-300">
              <div
                dangerouslySetInnerHTML={{
                  __html: t("CustomSoftware.description6"),
                }}
              />
            </p>
          </div>

          <div className="p-8 space-y-3 border-2 border-[#00acee] dark:border-blue-300 rounded-xl">
            <span className="inline-block text-[#00acee] dark:text-blue-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                />
              </svg>
            </span>

            <h1 className="text-xl font-semibold text-gray-700 capitalize dark:text-white">
              {" "}
              {t("CustomSoftware.title7")}
            </h1>

            <p className="text-gray-500 dark:text-gray-300">
              <div
                dangerouslySetInnerHTML={{
                  __html: t("CustomSoftware.description7"),
                }}
              />
            </p>
          </div>
        </div>
      </div>

      <div className=" px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
          <h2 className="text-2xl font-bold md:text-3xl md:leading-tight text-[#1f2937] dark:text-gray-200">
            {t("CustomSoftware.faqtitle")}
          </h2>
        </div>

        <div className="max-w-5xl mx-auto">
          <div className="grid sm:grid-cols-2 gap-6 md:gap-12">
            <div>
              <h3 className="text-lg font-semibold text-[#1f2937] dark:text-gray-200">
                {t("CustomSoftware.faq1")}
              </h3>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                {t("CustomSoftware.faq1Description")}
              </p>
            </div>

            <div>
              <h3 className="text-lg font-semibold text-[#1f2937] dark:text-gray-200">
                {t("CustomSoftware.faq2")}
              </h3>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                {t("CustomSoftware.faq2Description")}
              </p>
            </div>

            <div>
              <h3 className="text-lg font-semibold text-[#1f2937] dark:text-gray-200">
                {t("CustomSoftware.faq3")}
              </h3>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                {t("CustomSoftware.faq3Description")}
              </p>
            </div>

            <div>
              <h3 className="text-lg font-semibold text-[#1f2937] dark:text-gray-200">
                {t("CustomSoftware.faq4")}
              </h3>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                {t("CustomSoftware.faq4Description")}
              </p>
            </div>

            <div>
              <h3 className="text-lg font-semibold text-[#1f2937] dark:text-gray-200">
                {t("CustomSoftware.faq5")}
              </h3>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                {t("CustomSoftware.faq5Description")}
              </p>
            </div>

            <div>
              <h3 className="text-lg font-semibold text-[#1f2937] dark:text-gray-200">
                {t("CustomSoftware.faq6")}
              </h3>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                {t("CustomSoftware.faq6Description")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-0 mb-10 flex flex-col items-center px-4 py-12 mx-auto text-center">
        <h2 className="text-2xl font-bold tracking-tight text-[#1f2937] xl:text-3xl dark:text-white">
          {t("webDevelopment.ctatitle")}
        </h2>

        <p className="block max-w-4xl mt-4 text-gray-600 dark:text-gray-300">
          {t("webDevelopment.ctatext")}
        </p>

        <div className="mt-6">
          <a
            href="/contact"
            className="inline-flex items-center justify-center w-full px-4 py-2.5 mt-4 overflow-hidden text text-white transition-colors duration-300 bg-[#00acee] rounded-lg shadow sm:w-auto sm:mx-2 sm:mt-0 hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
          >
            <svg
              className="w-5 h-5 mx-2 fill-current"
              viewBox="-28 0 512 512.00075"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m432.320312 215.121094-361.515624-208.722656c-14.777344-8.53125-32.421876-8.53125-47.203126 0-.121093.070312-.230468.148437-.351562.21875-.210938.125-.421875.253906-.628906.390624-14.175782 8.636719-22.621094 23.59375-22.621094 40.269532v417.445312c0 17.066406 8.824219 32.347656 23.601562 40.878906 7.390626 4.265626 15.496094 6.398438 23.601563 6.398438s16.214844-2.132812 23.601563-6.398438l361.519531-208.722656c14.777343-8.53125 23.601562-23.8125 23.601562-40.878906s-8.824219-32.347656-23.605469-40.878906zm-401.941406 253.152344c-.21875-1.097657-.351562-2.273438-.351562-3.550782v-417.445312c0-2.246094.378906-4.203125.984375-5.90625l204.324219 213.121094zm43.824219-425.242188 234.21875 135.226562-52.285156 54.539063zm-6.480469 429.679688 188.410156-196.527344 54.152344 56.484375zm349.585938-201.835938-80.25 46.332031-60.125-62.714843 58.261718-60.773438 82.113282 47.40625c7.75 4.476562 8.589844 11.894531 8.589844 14.875s-.839844 10.398438-8.589844 14.875zm0 0"></path>
            </svg>

            <span className="mx-2">{t("webDevelopment.ctabtn")}</span>
          </a>
        </div>
      </div>
    </div>
  );
}
