import React from "react";

export default function CTA2() {
  // CTA para mencionar los servidores VDS
  return (
    <section className="bg-gray-200 dark:bg-gray-800 ">
      <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
        <div className="max-w-screen-sm mx-auto text-center">
          <h2 className="mb-4 text-3xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white">
            ¿Necesitas más recursos?<br/>Prueba nuestros servidores VDS
          </h2>
          <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
            Nuestros servidores VDS son la mejor opción para aquellos que
            necesitan más recursos y potencia para sus proyectos.
          </p>
          <a
            href="/hosting/vds"
            className="text-black bg-yellow-700 hover:bg-yellow-400  font-medium rounded-full text-sm px-6 py-3.5 mr-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
          >
            Ver Servidores VDS
          </a>
        </div>
      </div>
    </section>
  );
}
