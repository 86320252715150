import React from "react";
import { useTranslation } from "react-i18next";

export default function Steps() {
  const { t } = useTranslation();
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-200 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            
            <span className="relative">{t("promo.stepTitle")}</span>
          </span>{" "}
        </h2>
        <p className="text-base text-gray-200 md:text-lg">
          {t("promo.subtitle4")}
        </p>
      </div>
      <div className="grid gap-10 lg:grid-cols-4 sm:grid-cols-2">
        <div>
          <div className="flex items-center justify-between mb-6">
            <p className="text-2xl text-gray-200 font-bold">{t("promo.step1")}</p>
            <svg
              className="w-6 text-gray-200 transform rotate-90 sm:rotate-0"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <line
                fill="none"
                strokeMiterlimit="10"
                x1="2"
                y1="12"
                x2="22"
                y2="12"
              />
              <polyline
                fill="none"
                strokeMiterlimit="10"
                points="15,5 22,12 15,19 "
              />
            </svg>
          </div>
          <p className="text-gray-200">
            <strong>{t("promo.step1Title")}</strong> <br/> {t("promo.step1Description")}
          </p>
        </div>
        <div>
          <div className="flex items-center justify-between mb-6">
            <p className="text-2xl text-gray-200 font-bold">{t("promo.step2")}</p>
            <svg
              className="w-6 text-gray-200 transform rotate-90 sm:rotate-0"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <line
                fill="none"
                strokeMiterlimit="10"
                x1="2"
                y1="12"
                x2="22"
                y2="12"
              />
              <polyline
                fill="none"
                strokeMiterlimit="10"
                points="15,5 22,12 15,19 "
              />
            </svg>
          </div>
          <p className="text-gray-200">
            <strong>{t("promo.step2Title")}</strong> <br/> {t("promo.step2Description")}
          </p>
        </div>
        <div>
          <div className="flex items-center justify-between mb-6">
            <p className="text-2xl text-gray-200 font-bold">{t("promo.step3")}</p>
            <svg
              className="w-6 text-gray-200 transform rotate-90 sm:rotate-0"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <line
                fill="none"
                strokeMiterlimit="10"
                x1="2"
                y1="12"
                x2="22"
                y2="12"
              />
              <polyline
                fill="none"
                strokeMiterlimit="10"
                points="15,5 22,12 15,19 "
              />
            </svg>
          </div>
          <p className="text-gray-200">
            <strong>{t("promo.step3Title")}</strong> <br/> {t("promo.step3Description")}
          </p>
        </div>
        <div>
          <div className="flex items-center justify-between mb-6">
            <p className="text-2xl text-gray-200 font-bold">{t("promo.step4")}</p>
            <svg
              className="w-8 text-gray-200"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <polyline
                fill="none"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="6,12 10,16 18,8"
              />
            </svg>
          </div>
          <p className="text-gray-200">
            <strong>{t("promo.step4Title")}</strong> <br/> {t("promo.step4Description")}
          </p>
        </div>
      </div>
    </div>
  );
}
